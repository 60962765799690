<template>
    <div>
        <div class="panel panel--push-top">
            <article-push-notification-tabs :article="article" />

            <div class="body">
                <table v-if="this.notifications.length > 0">
                    <thead>
                        <tr>
                            <th class="sortable">
                                {{ $t('article_push_notification_tabs.push_notification') }}
                            </th>
                            <th class="sortable">
                                {{ $t('article_push_notification_tabs.date') }}
                            </th>
                            <th />
                        </tr>
                    </thead>

                    <tbody ref="tableBody">
                        <template v-for="(pushNotifications, index) in this.notifications">
                            <tr :key="index" @click="toggleRow(index)" class="topRow">
                                <td>{{ pushNotifications.length }} talen</td>
                                <td>{{ pushNotifications[0].date | toFormattedDate('dd. DD MMMM YYYY') | upperCaseFirst }}</td>
                                <td>
                                    <div class="collapse-button" :class="{'active': expandedRow === index}">
                                        <svg width="12"
                                             height="7"
                                             viewBox="0 0 12 7"
                                             fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11.5306 1.53064L6.5306 6.53064C6.46092 6.60056 6.37813 6.65604 6.28696 6.69389C6.1958 6.73175 6.09806 6.75124 5.99935 6.75124C5.90064 6.75124 5.8029 6.73175 5.71173 6.69389C5.62057 6.65604 5.53778 6.60056 5.4681 6.53064L0.468098 1.53064C0.327202 1.38975 0.248047 1.19865 0.248047 0.999393C0.248047 0.800136 0.327202 0.609039 0.468098 0.468143C0.608994 0.327247 0.800091 0.248092 0.999348 0.248092C1.19861 0.248092 1.3897 0.327247 1.5306 0.468143L5.99997 4.93752L10.4693 0.467518C10.6102 0.326622 10.8013 0.247467 11.0006 0.247467C11.1999 0.247467 11.391 0.326622 11.5318 0.467518C11.6727 0.608415 11.7519 0.799511 11.7519 0.998768C11.7519 1.19803 11.6727 1.38912 11.5318 1.53002L11.5306 1.53064Z"
                                                  fill="#0069B4" />
                                        </svg>
                                    </div>
                                </td>
                            </tr>
                            <template v-if="expandedRow === index">
                                <tr v-for="(pushNotification, subIndex) in notifications[index]"
                                    :key="'sub-' + subIndex"
                                    class="expanded-row">
                                    <td>
                                        {{ getLocaleTranslation(pushNotification.language) }}
                                    </td>
                                    <td>
                                        {{ pushNotification.message }}
                                    </td>
                                    <td>
                                        {{ pushNotification.date | toFormattedDate('dd. DD MMMM YYYY hh:mm') | upperCaseFirst }}
                                    </td>
                                </tr>
                            </template>
                        </template>
                    </tbody>
                </table>

                <h6 class="no-notifications" v-if="this.notifications.length === 0">
                    {{ $t('article_push_notification_tabs.no_push_notifications_yet') }}
                </h6>
            </div>
        </div>
    </div>
</template>

<script>
    import ArticlePushNotificationTabs from '../ArticlePushNotificationsTabs';

    export default {
        name: 'ArticleTabPushNotificationsOverview',

        components: {
            ArticlePushNotificationTabs,
        },

        props: {
            article: {
                type: Object,
                required: true,
            },
        },

        data() {
            return {
                notifications: [],
                expandedRow: null,
            };
        },

        methods: {
            getPushNotifications() {
                window.axios.get(`/articles/${this.$route.params.id}/push-notifications`)
                    .then((response) => {
                        this.notifications = response.data;
                    });
            },
            toggleRow(index) {
                // Zet expandedRow naar de huidige index als deze niet al is uitgeklapt, anders sluit het
                this.expandedRow = this.expandedRow === index ? null : index;
            },
            getLocaleTranslation(locale) {
                return this.$t(`languages.${locale}`);
            },
        },

        mounted() {
            this.getPushNotifications();
        },
    };
</script>

<style lang="scss" scoped>
    .panel {
        padding : 0;

        .body {
            margin  : 0;
            padding : 0px 16px 56px 16px;

            .no-notifications {
                padding : 40px 16px 56px 16px;
            }

            tr.topRow th:last-child, tr.topRow td:last-child {
                display         : flex;

                align-items     : center;
                justify-content : flex-end;
                height          : 64px;
                text-align      : right;

            }

            tr:hover:not(thead tr) {
                background-color : #F0F6FB;

                .collapse-button {
                    border           : rgba(0, 0, 0, 0.1) 1px solid;
                    background-color : white;
                }
            }

            .collapse-button {
                display          : flex;
                align-items      : center; /* Aligns vertically */
                justify-content  : center; /* Aligns horizontally */

                margin-right     : 16px;
                border-radius    : 20px;

                width            : 32px;
                height           : 32px;

                background-color : #DFECF6;

                svg {
                    transition : transform 0.3s ease;
                }

                &.active {
                    svg {
                        transform : rotate(180deg);
                    }
                }
            }

            .expanded-row {
                border : none;
                height : 16px;
            }
        }
    }
</style>
