<template>
    <div>
        <div class="content">
            <manual-header v-if="manuals && activeManual.id"
                           :active-manual="activeManual"
                           :languages="languages"
                           :manual-version="manualVersion"
                           :manuals="manuals"
                           :version-disabled="versionDisabled"
                           @publish="publish"
                           @change-language="setLanguage"
                           @change-version="changeVersion"
                           @manual-switch="onManualSwitch"
                           @translate-to-english="translateManual"/>
            <manual-tabs />

            <router-view
                :active-manual="activeManual"
                :adjustment="manualVersion.adjustment"
                :chaptersProp="chapters"
                :language="language"
                :manual-id="manualId"
                :manual-version="manualVersion"
                :version-disabled="versionDisabled"
            ></router-view>
        </div>

        <portal-target name="modals">
            <!--Teleport modals here-->
        </portal-target>
    </div>
</template>

<script>
import { mapMutations } from 'vuex';
import ManualHeader from './ManualHeader';
import ManualTabs from './ManualTabs';

export default {
    name: 'manual-app',

    components: {
        ManualHeader,
        ManualTabs,
    },

    data() {
        return {
            showNewChapterModal: 0,
            translatable: {},
            manualVersion: {},
            activeManual: {},
            manuals: null,
            manualId: null,
            languages: [],
        };
    },

    computed: {
        versionDisabled() {
            let manualVersion = Object.assign({}, this.manualVersion);

            if (this.manualVersion.versions !== undefined) {
                return this.manualVersion.version !== this.manualVersion.versions.length
                    || !!this.manualVersion.manual.is_publishing;
            }

            return manualVersion && !!manualVersion.manual && !!manualVersion.manual.is_publishing;
        },
        chapters: {
            set(chapters) {
                this.setChapters(chapters);
            },
            get() {
                return this.$store.getters['chapters/chapters'];
            },
        },
        language: {
            set(language) {
                this.setLanguage(language);
            },
            get() {
                return this.$store.getters['chapters/language'];
            },
        },
    },

    methods: {
        ...mapMutations({
            setChapters: 'chapters/set_chapters',
            setLanguage: 'chapters/set_language',
        }),
        changeVersion(version) {
            this.getChapters(this.manualId, version)
        },
        publish() {
            if (!!this.manualVersion.manual.is_publishing) {
                return;
            }

            if (this.manualVersion.accept_before !== null) {
                window.axios.post('/manuals/' + this.manualId + '/publish')
                    .then((response) => {
                        this.getChapters(this.manualId)
                    });
            } else {
                this.$resetMessages();
                this.$flashMessage(this.$t('manual_app.deadline'), 5000)
            }
        },

        onManualSwitch(manual) {
            this.manualId = manual.id;
            this.getChapters(this.manualId);
        },

        getManuals() {
            return new Promise((resolve) => {
                window.axios.get('/manuals').then(manualsResponse => {
                    this.manuals = manualsResponse.data;

                    if(this.manuals.baseManuals.length > 0) {
                        this.manualId = manualsResponse.data.baseManuals[0].id;
                    }
                    else if(this.manuals.businessUnitManuals.length > 0) {
                        this.manualId = manualsResponse.data.businessUnitManuals[0].id;
                    }
                    else if(this.manuals.employeeFunctionManuals.length > 0) {
                        this.manualId = manualsResponse.data.employeeFunctionManuals[0].id;
                    }

                    resolve();
                });
            });
        },

        getLanguages() {
            return new Promise((resolve) => {
                window.axios.get('/languages').then(response => {
                    this.languages = response.data;

                    if (window.authUser.language_relation) {
                        this.setLanguage(window.authUser.language_relation);
                    } else {
                        this.setLanguage(this.languages.find(language => {
                            return language.default === 1;
                        }));
                    }

                    resolve();
                });
            });
        },

        getChapters(manualId, version = null) {
            let url = `/manuals/${manualId}/chapters`;
            window.axios.get(url, { params: { version: version } }).then(response => {
                response.data.chapters.map(function (chapter) {
                    chapter.collapsed = true;
                });
                this.manualVersion = response.data;
                this.setChapters(response.data.chapters);
                this.activeManual = response.data.manual;
                this.$store.dispatch('chapters/load_chapter_stack', manualId);
            });
        },
        translateManual() {
            window.axios.get(`/manuals/${this.manualId}/translate`).then(() => {
                this.$flashMessage(this.$t('manual_google_translate.started'), 5000)
            });
        },
    },

    beforeMount() {
        this.$eventBus.$on('referencedLinkClicked', function (arg) {
            let element = document.querySelector('.js-ref-target-' + arg);

            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        });

        this.getLanguages().then(() => this.getManuals()).then(() => this.getChapters(this.manualId));
    },
};
</script>
