import ContactsIndex from "../components/Contacts/index";
import ContactsForm from "../components/Contacts/contact-form";

export default [
    {
        path: '/contacten',
        component: ContactsIndex,
        name: 'contacts.index',
        meta: {
            permissions: ['add-and-edit-contacts']
        },
    },
    {
        path: '/contacten/nieuw',
        component: ContactsForm,
        name: 'contacts.create',
        meta: {
            permissions: ['add-and-edit-contacts']
        },
    },
    {
        path: '/contacten/:id',
        component: ContactsForm,
        name: 'contacts.edit',
        meta: {
            permissions: ['add-and-edit-contacts']
        },
    },
];