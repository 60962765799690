import DocumentsAddDocApp from '../components/Documents/DocumentsAddDocApp';
import DocumentsEditDocApp from '../components/Documents/DocumentsEditDocApp';
import DocumentsEditDocContent from '../components/Documents/DocumentsEditDocContent';
import DocumentsEditDocAgree from '../components/Documents/DocumentsEditDocAgree';
import DocumentsAddMapApp from '../components/Documents/DocumentsAddMapApp';
import DocumentsEditMapApp from '../components/Documents/DocumentsEditMapApp';
import DocumentsApp from '../components/Documents/DocumentsApp';
import DocumentsAddLinkApp from '../components/Documents/DocumentsAddLinkApp';
import DocumentsEditLinkApp from '../components/Documents/DocumentsEditLinkApp';

export default [
    {
        path: '/documenten/document-toevoegen/:parentId?',
        name: 'add-document',
        component: DocumentsAddDocApp,
        meta: {
            permissions: ['add-and-edit-documents'],
        },
    },
    {
        path: '/documenten/link-toevoegen/:parentId?',
        name: 'add-link',
        component: DocumentsAddLinkApp,
        meta: {
            permissions: ['add-and-edit-documents'],
        },
    },
    {
        path: '/documenten/link-bewerken/:id',
        name: 'edit-link',
        component: DocumentsEditLinkApp,
        meta: {
            permissions: ['add-and-edit-documents'],
        },
    },
    {
        path: '/documenten/document-bewerken/:id',
        name: 'edit-document',
        component: DocumentsEditDocApp,
        children: [
            {
                path: '',
                redirect: 'content',
                meta: {
                    permissions: ['add-and-edit-documents'],
                },
            },
            {
                path: 'content',
                name: 'content',
                component: DocumentsEditDocContent,
                meta: {
                    permissions: ['add-and-edit-documents']
                },
            },
            {
                path: 'akkoord-geven',
                name: 'agree',
                component: DocumentsEditDocAgree,
                meta: {
                    permissions: ['add-and-edit-documents']
                },
            },
        ],
    },
    {
        path: '/documenten/map-toevoegen/:parentId?',
        name: 'add-map',
        component: DocumentsAddMapApp,
        meta: {
            permissions: ['add-and-edit-documents'],
        },
    },
    {
        path: '/documenten/map-bewerken/:id',
        name: 'edit-map',
        component: DocumentsEditMapApp,
        meta: {
            permissions: ['add-and-edit-documents'],
        },
    },
    {
        path: '/documenten/:parentId?',
        component: DocumentsApp,
        meta: {
            permissions: ['add-and-edit-documents'],
        },
    },
];
