<template>
    <div>
        <div class="panel panel--push-top">
            <h2 class="title title--push-bottom">Instellingen</h2>
            <div class="field field--checkbox language-activator">
                <input id="toggleLanguage" v-model="useLanguage" type="checkbox" @change="onToggleLanguage" />
                <label for="toggleLanguage"></label>
                <label class="label" for="toggleLanguage">Is beschikbaar in deze taal</label>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ManualTabSettings',
    props: {
        manualId: {
            required: true,
        },
        activeManual: {
            required: true
        },
        language: {
            required: true
        }
    },
    data() {
        return {
            useLanguage: false,
        }
    },
    beforeMount() {
        this.isLanguageActive();
    },
    methods: {
        onToggleLanguage() {
            window.axios.get(`/manuals/${this.manualId}/toggle-language/${this.language.id}`).then(response => {
                this.activeManual.language = response.data;
            });
        },
        isLanguageActive() {
            if (this.activeManual.language !== undefined) {
                const langCheck = this.activeManual.language.filter(lang => {
                    if (lang.id === this.language.id) {
                        return lang.id;
                    }
                });

                this.useLanguage = langCheck.length > 0;
            }
        }
    },
    watch: {
        manualId() {
            this.isLanguageActive();
        },
        language() {
            this.isLanguageActive();
        }
    }
}
</script>
