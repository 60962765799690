const state = {
    chapters: [],
    language: {},
    chaptersStack: [],
};

const getters = {
    chapters: state => state.chapters.sort((a, b) => a.position - b.position),
    chaptersStack: state => state.chaptersStack && state.chaptersStack.length ? state.chaptersStack.sort((a, b) => a.position - b.position) : [],
    language: state => state.language,
    chapters_length: state => state.chapters.length,
    paragraphs_by_id: function (state) {
        return (id) => {
            const chapter = state.chapters.find(c => c.id === id);

            return chapter.chapters && chapter.chapters.length ? chapter.chapters.sort((a, b) => a.position - b.position) : [];
        };
    },
    sub_paragraphs_by_id: function (state) {
        return (chapterId, paragraphId) => {
            const chapter = state.chapters.find((c) => c.id === chapterId);
            const paragraph = chapter.chapters && chapter.chapters.length ? chapter.chapters.find((p) => p.id === paragraphId) : [];

            return paragraph.chapters && paragraph.chapters.length ? paragraph.chapters.sort((a, b) => a.position - b.position) : [];
        }
    },
    components_by_chapter_id: state => id => state.chapters.find(c => c.id === id).components.sort((a, b) => a.position - b.position),
    components_by_paragraph_id: state => (chapterId, paragraphId) => state.chapters.find(c => c.id === chapterId).chapters.find(c => c.id === paragraphId).components.sort((a, b) => a.position - b.position),
    components_by_sub_paragraph_id: state => {
        return (chapterId, paragraphId, subParagraphId) => {
            return state.chapters.find((c) => c.id === chapterId).chapters.find((p) => p.id === paragraphId).chapters.find(s => s.id === subParagraphId).components.sort((a, b) => a.position - b.position);
        }
    }
};

const actions = {
    load_chapter_stack({ commit }, manualId) {
        const promise = window.axios.get(`/manuals/${manualId}/all-chapters`);

        promise.then(response => {
            commit('set_chapter_stack', response.data);
        });

        return promise;
    },

    load_sub_chapters({ commit }, chapterId) {
        const promise = window.axios.get(`/chapters/${chapterId}/list`);

        promise.then(response => {
            commit('eager_load_sub_chapters', response.data);
        });

        return promise;
    },

    store_chapter({ commit, dispatch, state }, chapter) {
        const afterChapter = state.chapters.find(c => c.id === chapter.afterId);

        const promise = window.axios.post(`/manuals/${chapter.manual_id}/chapters`, {
            chapter_id: chapter.chapter_id,
            content: chapter.content,
            language: chapter.language,
            ...(afterChapter ? { position: afterChapter.position } : {})
        });

        promise.then(response => {
            commit('add_chapter', response.data);
            if (afterChapter) {
                commit('reposition_chapters_after_chapter', response.data);
            }

            dispatch('load_chapter_stack', chapter.manual_id);
        });

        return promise;
    },

    update_chapter({ commit }, chapter) {
        window.axios.put(`/chapters/${chapter.id}`, { content: chapter.content, language: chapter.language }).then(response => {
            commit('update_chapter_title', {
                title: chapter.content,
                chapterKey: chapter.chapterKey,
                language: chapter.language,
            });
        });
    },

    delete_chapter({ commit }, { chapterKey, chapter }) {
        window.axios.delete(`/chapters/${chapter.id}`).then(() => {
            commit('delete_chapter', chapterKey);
        });
    },

    store_paragraph({ commit, dispatch }, paragraph) {
        window.axios.post(`/manuals/${paragraph.manual_id}/chapters`, paragraph).then(response => {
            response.data.chapters = [];
            commit('add_paragraph', {
                paragraph: response.data,
            });

            dispatch('load_chapter_stack', paragraph.manual_id);
        });
    },

    store_sub_paragraph({ commit, dispatch }, subParagraph) {
        window.axios.post(`/manuals/${subParagraph.manual_id}/chapters`, subParagraph).then(response => {
            commit('add_sub_paragraph', {
                subParagraph: response.data,
                paragraph: subParagraph.paragraph,
            });

            dispatch('load_chapter_stack', subParagraph.manual_id);
        });
    },

    store_empty_component({ commit }, component) {
        const components = getChapterByKeyPath(state.chapters, component.chapterKey).components;
        const afterComponent = components.find(c => c.id === component.afterId);

        const promise = window.axios.post(`/components`, {
            componentable_id: component.componentable_id,
            componentable_type: component.componentable_type,
            language: state.language.code,
            component_type_id: component.component_type_id,
            ...(afterComponent ? { position: afterComponent.position } : {})
        });

        promise.then(response => {
            commit('add_component', {
                chapterKey: component.chapterKey,
                component: response.data,
            });

            if (afterComponent) {
                commit('reposition_components_after_component', {
                    component: response.data,
                    chapterKey: component.chapterKey,
                });
            }
        });

        return promise;
    },

    update_component({ commit }, component) {
        window.axios.put(`/components/${component.id}`, {
            content: component.content,
            language: component.language
        }).then(response => {
            commit('update_component_content', {
                chapterKey: component.chapterKey,
                id: response.data.id,
                content: component.content,
                language: component.language,
            });
        });
    },

    delete_component({ commit }, { chapterKey, id }) {
        window.axios.delete(`/components/${id}`).then(() => {
            commit('delete_component', {
                chapterKey: chapterKey,
                id: id,
            });
        });
    },

    swap_chapter_positions({ commit, state }, { chapterId, position, parentChapterKey }) {
        window.axios.put(`/chapters/${chapterId}/update-position`, {
            'position': position,
        }).then(response => {
            commit('update_chapter_position', { chapterId, position, parentChapterKey });
        });
    },

    swap_component_positions({ commit, state }, { chapterKey, oldComponentId, newComponentId }) {
        commit('swap_components', { chapterKey, oldComponentId, newComponentId });

        window.axios.put(`/components/swap-positions`, {
            'old': oldComponentId,
            'new': newComponentId,
        });
    },

    set_chapter_components({ commit, state }, { chapterId, components }) {
        commit('update_chapter_component_position', {
            components: components,
            chapterId: chapterId
        });

        let componentPositions = components.map(function (component) {
            return {
                'id': component.id,
                'pos': component.position
            };
        });

        window.axios.put(`/components/swap-positions`, componentPositions);
    },
    set_paragraph_components({ commit, state }, { chapterId, paragraphId, components }) {
        commit('update_paragraph_component_position', {
            components: components,
            chapterId: chapterId,
            paragraphId: paragraphId
        });

        let componentPositions = components.map(function (component) {
            return {
                'id': component.id,
                'pos': component.position
            };
        });

        window.axios.put(`/components/swap-positions`, componentPositions);
    },
    set_language({ commit, state }, { language }) {
        commit('set_language', {
            language: language
        })
    },
    set_sub_paragraph_components({ commit, state }, {
        chapterId,
        paragraphId,
        subParagraphId,
        components
    }) {
        commit('update_sub_paragraph_component_position', {
            components: components,
            chapterId: chapterId,
            paragraphId: paragraphId,
            subParagraphId: subParagraphId,
        });

        let componentPositions = components.map(function (component) {
            return {
                'id': component.id,
                'pos': component.position
            };
        });

        window.axios.put(`/components/swap-positions`, componentPositions);
    },
};

const mutations = {
    set_chapters(state, chapters) {
        state.chapters = chapters;
    },

    set_language(state, language) {
        state.language = language;
    },

    update_chapter_position(state, { chapterId, position, parentChapterKey }) {
        const chapters = parentChapterKey ? getChapterByKeyPath(state.chapters, parentChapterKey).chapters : state.chapters;
        let movingChapter = chapters.find(c => parseInt(c.id) === parseInt(chapterId));
        const isMovedDown = position > movingChapter.position;

        for (let i = 0; i < chapters.length; i++) {
            if (parseInt(movingChapter.id) === parseInt(chapters[i].id)) {
                continue;
            }

            if (isMovedDown) {
                if (chapters[i].position > movingChapter.position && chapters[i].position <= position) {
                    chapters[i].position = chapters[i].position - 1;
                }
            } else {
                if (chapters[i].position >= position && chapters[i].position < movingChapter.position) {
                    chapters[i].position = chapters[i].position + 1;
                }
            }
        }

        movingChapter.position = position;
    },

    swap_components(state, { chapterKey, oldComponentId, newComponentId }) {
        const chapter = getChapterByKeyPath(state.chapters, chapterKey);
        const i = chapter.components.findIndex(c => c.id === oldComponentId);
        const k = chapter.components.findIndex(c => c.id === newComponentId);

        let tmp = chapter.components[i].position;
        chapter.components[i].position = chapter.components[k].position;
        chapter.components[k].position = tmp;
    },

    eager_load_sub_chapters(state, chapter) {
        const i = state.chapters.findIndex(c => c.id.toString() === chapter.id.toString());

        let newChapter = Object.assign({}, state.chapters[i]);
        newChapter.chapters = chapter.chapters;
        newChapter.chaptersLoaded = true;

        state.chapters.splice(i, 1, newChapter);
    },

    add_chapter(state, chapter) {
        chapter.collapsed = true;
        state.chapters.push(chapter);
    },

    update_chapter_paragraphs(state, { chapterId, paragraphs }) {
        const i = state.chapters.findIndex((c) => c.id === chapterId);

        state.chapters[i].chapters = paragraphs;
    },

    update_paragraph_sub_paragraphs(state, { chapterId, paragraphId, subParagraphs }) {
        const i = state.chapters.findIndex((c) => c.id === chapterId);
        const k = state.chapters[i].chapters.findIndex((c) => c.id === paragraphId);

        state.chapters[i].chapters[k].chapters = subParagraphs;
    },

    update_chapter_component_position(state, { chapterId, components }) {
        const i = state.chapters.findIndex((c) => c.id === chapterId);

        state.chapters[i].components = components;
    },

    update_paragraph_component_position(state, { chapterId, paragraphId, components }) {
        const i = state.chapters.findIndex((c) => c.id === chapterId);
        const k = state.chapters[i].chapters.findIndex((c) => c.id === paragraphId);

        state.chapters[i].chapters[k].components = components;
    },

    update_sub_paragraph_component_position(state, { chapterId, paragraphId, subParagraphId, components }) {
        const i = state.chapters.findIndex((c) => c.id === chapterId);
        const k = state.chapters[i].chapters.findIndex((c) => c.id === paragraphId);
        const l = state.chapters[i].chapters[k].chapters.findIndex((c) => c.id === subParagraphId);

        state.chapters[i].chapters[k].chapters[l].components = components;
    },

    add_paragraph(state, { paragraph }) {
        const i = state.chapters.findIndex(x => x.id === paragraph.chapter_id);

        state.chapters[i].chapters.push(paragraph);
    },

    add_sub_paragraph(state, { subParagraph, paragraph }) {
        const chapter = state.chapters.find(x => x.id === paragraph.chapter_id);
        const p = chapter.chapters.find(x => x.id === paragraph.id);

        p.chapters.push(subParagraph);
    },

    add_component(state, { chapterKey, component }) {
        let chapter = getChapterByKeyPath(state.chapters, chapterKey);
        let components = chapter.components;

        components.push(component);
    },

    update_component_content(state, { chapterKey, id, content, language }) {
        let chapter = getChapterByKeyPath(state.chapters, chapterKey);
        let component = chapter.components.find(c => c.id === id);

        if (language.code) {
            let translation = component.translations.filter(function (translations) {
                return translations.language === language.code
            })[0];

            translation.content = content;
        }
    },

    delete_chapter(state, chapterKey) {
        const chapter = getChapterByKeyPath(state.chapters, chapterKey);
        const chapterIds = chapterKey.toString().split('.');

        if (chapterIds.length === 1) {
            const i = state.chapters.findIndex(c => c.id.toString() === chapterIds[0]);

            state.chapters.splice(i, 1);
        } else {
            chapterIds.splice(-1, 1);
            const newChapterKey = chapterIds.join('.');
            const parentChapter = getChapterByKeyPath(state.chapters, newChapterKey);
            const i = parentChapter.chapters.findIndex(c => c.id.toString() === chapter.id.toString());
            parentChapter.chapters.splice(i, 1);
        }
    },

    update_chapter_title(state, { title, chapterKey, language }) {
        const chapter = getChapterByKeyPath(state.chapters, chapterKey);

        if (language.code) {
            let translation = chapter.translations.filter(function (translations) {
                return translations.language === language.code
            })[0];

            translation.content = title;
        }
    },

    delete_component(state, { chapterKey, id }) {
        let chapter = getChapterByKeyPath(state.chapters, chapterKey);
        let components = chapter.components;

        const i = components.findIndex(c => c.id === id);
        components.splice(i, 1);
    },

    reposition_chapters_after_chapter(state, chapter) {
        let chapters = state.chapters.filter((c) => {
            return c.position >= chapter.position && c.id !== chapter.id;
        });

        chapters.forEach((ch) => {
            state.chapters.find(c => c.id === ch.id).position++;
        });
    },

    reposition_components_after_component(state, { component, chapterKey }) {
        let chapter = getChapterByKeyPath(state.chapters, chapterKey);

        chapter.components.forEach((cmp, i) => {
            if (cmp.position >= component.position && cmp.id !== component.id) {
                chapter.components[i].position++;
            }
        });
    },

    set_chapter_stack(state, chapters) {
        state.chaptersStack = chapters;
    },
};

const getChapterByKeyPath = (chapters, keyPath) => {
    const chapterIds = keyPath.toString().split('.');

    if (chapterIds.length === 1) {
        const i = chapters.findIndex(c => c.id.toString() === chapterIds[0]);

        return chapters[i];
    }

    if (chapterIds.length === 2) {
        const i = chapters.findIndex(c => c.id.toString() === chapterIds[0]);
        const j = chapters[i].chapters.findIndex(c => c.id.toString() === chapterIds[1]);

        return chapters[i].chapters[j];
    }

    if (chapterIds.length === 3) {
        const i = chapters.findIndex(c => c.id.toString() === chapterIds[0]);
        const j = chapters[i].chapters.findIndex(c => c.id.toString() === chapterIds[1]);
        const k = chapters[i].chapters[j].chapters.findIndex(c => c.id.toString() === chapterIds[2]);

        return chapters[i].chapters[j].chapters[k];
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
