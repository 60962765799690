<template>
    <base-modal @close="$emit('close')">
        <h1>{{ $t('no_language_modal.notification') }}</h1>

        <p>{{ $t('no_language_modal.argument') }}</p>

        <div class="btn-wrap btn-wrap--right">
            <a href="#" class="btn" @click.prevent="$emit('close')">{{ $t('no_language_modal.ok') }}</a>
        </div>
    </base-modal>
</template>

<script>
    export default {
        name: 'document-map-no-language-modal',
    };
</script>
