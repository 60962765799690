<template>
    <div class="content">
        <div class="header">
            <h1>{{ !$route.params.id ? $t('departments.add_department') : $t('departments.save_changes') }}</h1>
            <div class="header-buttons">
                <div v-if="$route.params.id" @click="deleteDepartment">
                    <div class="button red">
                        <span>{{ $t('departments.delete') }}</span>
                    </div>
                </div>
                <div @click="save">
                    <div class="button green">
                        <span>{{ !$route.params.id ? $t('departments.add_department') : $t('departments.save_changes') }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="body">
            <div class="content-wrap">
                <div class="content-wrap__content content-wrap__content--push-right">
                    <div class="panel panel--push-top">
                        <h2 class="title title--push-bottom">{{ $t('departments.data') }}</h2>
                        <div class="field">
                            <label class="label" for="title">{{ $t('departments.name_nl') }}</label>
                            <input id="title" v-model="departmentNames.nl" type="text" />
                        </div>
                        <div class="field">
                            <label class="label" for="title">{{ $t('departments.name_en') }}</label>
                            <input id="title" v-model="departmentNames.en" type="text" />
                        </div>
                        <div class="field">
                            <label class="label" for="title">{{ $t('departments.name_pl') }}</label>
                            <input id="title" v-model="departmentNames.pl" type="text" />
                        </div>
                        <div class="field">
                            <label class="label" for="title">{{ $t('departments.name_de') }}</label>
                            <input id="title" v-model="departmentNames.de" type="text" />
                        </div>
                        <div class="field">
                            <label class="label" for="title">{{ $t('departments.name_fr') }}</label>
                            <input id="title" v-model="departmentNames.fr" type="text" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'departments-index',

        data() {
            return {
                departmentNames: {
                    nl: '',
                    en: '',
                    pl: '',
                    de: '',
                    fr: '',
                },
                translations: [],
                id: null,
            };
        },

        beforeMount() {
            const departmentId = this.$route.params.id;
            if (departmentId) {
                this.fetchDepartment(departmentId);
            }
        },

        methods: {
            save() {
                const departmentId = this.$route.params.id;
                const params = {
                    nl: this.departmentNames.nl,
                    en: this.departmentNames.en,
                    pl: this.departmentNames.pl,
                    de: this.departmentNames.de,
                    fr: this.departmentNames.fr,
                    id: this.id,
                };

                if (departmentId) {
                    this.update(departmentId, params);
                } else {
                    this.store(params);
                }
            },
            store(params) {
                axios.post('/departments', params)
                     .then(response => {
                         this.$router.push({ name: 'departments.index' });
                     })
                     .catch(error => {
                         const errors = Object.entries(error.response.data.errors).map((error) => error[1]).flat().join('<br>');
                         this.$flashMessage(errors, 5000, 'error');
                     });
            },
            update(departmentId, params) {
                axios.get(`/departments/${departmentId}`)
                     .then(() => {
                         axios.put(`/departments/${departmentId}`, params)
                              .then(response => {
                                  this.$router.push({ name: 'departments.index' });
                              })
                              .catch(error => {
                                  const errors = Object.entries(error.response.data.errors).map((error) => error[1]).flat().join('<br>');
                                  this.$flashMessage(errors, 5000, 'error');
                              });
                     })
                     .catch(error => {
                         const errors = Object.entries(error.response.data.errors).map((error) => error[1]).flat().join('<br>');
                         this.$flashMessage(errors, 5000, 'error');
                     });
            },
            deleteDepartment() {
                const departmentId = this.$route.params.id;
                const isConfirmed = confirm('Are you sure you want to delete this department?');

                if (isConfirmed) {
                    axios.delete(`/departments/${departmentId}`)
                         .then(response => {
                             this.$router.push({ name: 'departments.index' });
                         })
                         .catch(error => {
                             const errors = Object.entries(error.response.data.errors).map(error => error[1]).flat().join('<br>');
                             this.$flashMessage(errors, 5000, 'error');
                         });
                }
            },
            fetchDepartment(departmentId) {
                axios.get(`/departments/${departmentId}`)
                     .then(response => {
                         this.translations = response.data.data.translations;
                         this.setValuesFromTranslations();
                         this.id = response.data.data.id;
                     })
                     .catch(error => {
                         const errors = Object.entries(error.response.data.errors).map((error) => error[1]).flat().join('<br>');
                         this.$flashMessage(errors, 5000, 'error');
                     });
            },
            setValuesFromTranslations() {
                this.translations.forEach(translation => {
                    this.departmentNames[translation.lang] = translation.name;
                });
            },
        },
    };
</script>
<style>
    .red {
        background-color : #CC5833;
    }

    .button {
        color : #FFFFFF;
    }
</style>