<template>
    <div class="panel panel--push-top">
        <h3 class="panel-title">{{ $t('business_units.anniversaries_label') }}</h3>
        <div v-for="(item,index) in anniversaries" class="field field--anniversary">
            <label class="label" for="value">{{ $t('business_units.anniversary_item') }}</label>
            <div class="input-row">
                <input id="value" :ref="item.interval" v-model="item.interval"
                       min="0" step="0.5" type="number"
                       @blur="update(index,item)">
                <img alt="delete" src="/img/mdi_delete.svg" @click="removeItem(index)">
            </div>
        </div>
        <div class="new" @click="add">
            <img alt="add" src="/img/mdi_add_circle.svg">
            <span>{{ $t('business_units.add_anniversary') }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AnniversariesTab',

    data() {
        return {
            selected: this.selectedPermissions,
        }
    },

    methods: {
        add() {
            let object = {
                interval: null
            }
            this.anniversaries.push(object)
            this.$emit('update', { key: 'anniversaries', value: this.anniversaries })
        },
        removeItem(index) {
            this.anniversaries.splice(index, 1);
            this.$emit('update', { key: 'anniversaries', value: this.anniversaries })
        },
        update(index, item) {
            if (Number(item.interval) < 0) {
                item.interval = 0
            }
            this.$emit('update', { key: 'anniversaries[' + index + ']', value: item })
        }
    },

    props: {
        anniversaries: {
            required: true
        },
    },

    watch: {
        selectedPermissions() {
            this.selected = this.selectedPermissions;
        }
    },
}
</script>

<style lang="scss" scoped>
.input-row {
    display        : flex;
    flex-direction : row;
}

img {
    margin : 10px;
    width  : 20px;
    cursor : pointer;
}

input {
    display            : block;
    margin-top         : 6px;
    border             : 1px solid rgba(0, 0, 0, 0.1);
    border-radius      : 4px;
    width              : 100%;
    padding-left       : 16px;
    font-family        : Roboto, sans-serif;
    font-size          : 14px;
    font-weight        : 500;
    font-style         : normal;
    line-height        : 48px;
    color              : #1a1a1a;
    -moz-appearance    : textfield;
    -webkit-appearance : none;
}

.new {
    img {
        width  : 16px;
        height : 16px;
        cursor : pointer;
    }

    span {
        cursor      : pointer;
        font-size   : 14px;
        font-weight : 500;
    }
}
</style>
