<template>
    <base-modal @close="close">
        <h1>{{ $t('add_article_link_modal.info') }}</h1>

        <div class="field">
            <label for="label" class="label">{{ $t('add_article_link_modal.label') }}</label>
            <input type="text" v-model="label" id="label" />
        </div>

        <div class="field">
            <label for="manual" class="label">{{ $t('add_article_link_modal.article') }}</label>
            <vue-multiselect
                :close-on-select="true"
                :placeholder="$t('add_article_link_modal.select')"
                :multiple="false"
                :searchable="true"
                :options="articles"
                select-label=""
                deselect-label=""
                selected-label=""
                track-by="id"
                label="label"
                name="article"
                v-model="selectedArticle">
                <template slot="tag" slot-scope="props">
                    <span v-text="props.option.label"></span>
                </template>
                <template slot="option" slot-scope="props">
                    <div class="option__desc">
                        <div class="label_wrapper">
                            <span>{{ props.option.label }}</span>
                            <span class="business_units">{{ props.option.businessUnits }}</span>
                        </div>
                        <img alt="check" class="selected" src="/svg/check.svg">
                    </div>
                </template>
                <span slot="noResult">{{ $t('article_tab_division.no_results') }}</span>
            </vue-multiselect>
        </div>

        <div class="btn-wrap btn-wrap--right">
            <a href="#" class="btn" @click.prevent="close">{{ $t('add_chapter_link_modal.cancel') }}</a>
            <a href="#" class="btn btn--shadow"
               :class="{'btn--green': this.label && this.selectedArticle}"
               @click.prevent="confirm">{{ $t('add_chapter_link_modal.insert') }}</a>
        </div>
    </base-modal>
</template>

<script>
    import VueMultiselect from 'vue-multiselect/src/Multiselect';

    export default {
        name: 'AddArticleLinkModal',

        components: {
            VueMultiselect,
        },

        props: {
            languageCode: {
                required: true,
                type: String,
            },
        },

        data() {
            return {
                label: null,
                articles: [],
                selectedArticle: null,
            };
        },

        created() {
            window.axios.get('/articles/all-titles').then((response) => {
                this.articles = response.data.filter((article) => {
                    const translation = article.translations.find(t => t.language === this.languageCode);

                    return article.published_at
                           && article.translations.length
                           && translation
                           && translation.content;
                }).map(article => ({
                    id: article.id,
                    label: article.translations.find(t => t.language === this.languageCode).content,
                    businessUnits: article.business_units.map(item => (item.name)).join(', '),
                }));
            });
        },

        methods: {
            close() {
                this.$emit('close');
            },

            confirm() {
                if (!this.label || !this.selectedArticle) {
                    return;
                }

                this.$emit('confirm', {
                    label: this.label,
                    article: this.selectedArticle.id,
                });
            },
        },
    };
</script>

<style lang="scss">
    .modal {
        overflow : visible;
    }

    .multiselect {
        input {
            margin-top    : 0 !important;
            margin-bottom : 0 !important;
            border        : 0 !important;
            max-height    : 48px;
        }

        .label_wrapper {
            display : inline-flex;
            width   : 100%;

            .business_units {
                margin-left   : 16px;
                opacity       : 0.6;
                text-overflow : ellipsis;
                overflow      : hidden;
                white-space   : nowrap;
                display       : inline-block;
            }
        }

    }
</style>