import LocationOnRouteForm from '../components/LocationsOnRoute/LocationOnRouteForm';
import LocationsOnRouteIndex from '../components/LocationsOnRoute/index';

export default [
    {
        path: '/locaties-onderweg',
        component: LocationsOnRouteIndex,
        name: 'locations.index',
        meta: {
            // permissions: ['add-and-edit-location'],
        },
    },
    {
        path: '/locaties-onderweg/nieuw',
        component: LocationOnRouteForm,
        name: 'locations.create',
        meta: {
            // permissions: ['add-and-edit-location'],
        },
    },
    {
        path: '/locaties-onderweg/:id',
        component: LocationOnRouteForm,
        name: 'locations.edit',
        meta: {
            // permissions: ['add-and-edit-location'],
        },
    },
];
