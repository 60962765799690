<template>
    <div class="wrapper">
        <h2>{{ $t('statistics_app_fuel_reports.reports_export') }}</h2>
        <div class="date-container">
            <div class="from w-25">
                <span>Van</span>
                <Datepicker v-model="form.fromDate"></Datepicker>
            </div>
            <div class="till w-25">
                <span>Tot en met</span>
                <Datepicker v-model="form.tillDate"></Datepicker>
            </div>
            <div class="button-green" @click="exportFuelReports">
                <span>{{ $t('statistics_app_fuel_reports.export') }}</span>
            </div>
        </div>
    </div>
</template>

<script>
    import Datepicker from 'vuejs-datepicker';

    export default {
        name: 'ExportFuelReportsForm',

        data() {
            return {
                form: {
                    fromDate: null,
                    tillDate: null,
                },
            };
        },

        components: { Datepicker },

        methods: {
            exportFuelReports() {
                window.axios.get(`/statistics/fuel-reports/export`, {
                    params: {
                        fromDate: this.form.fromDate,
                        tillDate: this.form.tillDate,
                    },
                    responseType: 'blob',
                }).then(data => {
                        const downloadUrl = window.URL.createObjectURL(new Blob([data.data]));
                        const link = document.createElement('a');
                        link.href = downloadUrl;
                        link.setAttribute('download', 'statistics.xlsx');
                        document.body.appendChild(link);
                        link.click();
                        link.remove();
                    },
                );
            },
        },
    };
</script>

<style lang="scss">
    .wrapper {
        margin-top : 36px;

        .date-container {
            display     : flex;
            gap         : 20px;
            align-items : center;

            .button-green {
                box-shadow    : 0 2px 4px rgb(0 0 0 / 25%);
                background    : var(--color-secondary);
                color         : var(--color-white);
                display       : flex;
                border-radius : 6px;
                height        : 48px;
                font-size     : 16px;
                font-weight   : 600;
                cursor        : pointer;

                span {
                    border-radius : 6px;
                    padding       : 12px 42px;
                    transition    : all 0.2s ease;
                }
            }

            .vdp-datepicker {
                div {
                    input {
                        font-weight : bold;
                        color       : var(--color-white);
                    }
                }
            }
        }
    }
</style>
