const state = {
    hasChanges: false,
};

const getters = {
    hasChanges: state => state.hasChanges,
};

const actions = {};

const mutations = {
    set_has_changes(state, hasChanges) {
        state.hasChanges = hasChanges;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
