<template>
    <div class="content">
        <div class="header">
            <h1>{{ $t('users.users') }}</h1>

            <div class="header-buttons">
                <div @click="createUser">
                    <div class="button green">
                        <span>{{ $t('users.add_user') }}</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="body">
            <div class="search">
                <label for="search">
                    <img alt="search" src="img/search.svg">
                </label>

                <input id="search" :placeholder="$t('users.search')" type="text" v-model="search" @keyup="onSearch" />
            </div>

            <table ref="overviewTable">
                <thead>
                    <tr>
                        <th class="sortable" :class="{'sorted': sortByColumn === 'name'}" @click="sortBy('name')">{{ $t('users.name') }}</th>
                        <th class="sortable" :class="{'sorted': sortByColumn === 'division'}" @click="sortBy('division')">{{ $t('users.division') }}</th>
                        <th class="sortable" :class="{'sorted': sortByColumn === 'function'}" @click="sortBy('function')">{{ $t('users.function') }}</th>
                        <th class="sortable" :class="{'sorted': sortByColumn === 'phonenumber'}" @click="sortBy('phonenumber')">{{ $t('users.phone') }}</th>
                        <th>{{ $t('users.accepted_terms')}} / {{$t('users.last_login') | lowerCaseFirst}}</th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-for="user in users" :key="'users_' + user.id" @click="editUser(user)">
                        <td>{{ user.name }}</td>
                        <td>{{ user.businessUnitName ? user.businessUnitName : $t('users.not_available') }}</td>
                        <td>{{ user.employeeFunctionName ? user.employeeFunctionName : $t('users.not_available') }}</td>
                        <td>{{ user.phonenumber }}</td>
                        <td>{{ user.has_accepted_terms ? $t('general.yes') : $t('general.no') | upperCaseFirst }} {{user.last_login_at ? '/ ' + moment(user.last_login_at).format('DD-MM-YYYY HH:mm') :
                            ''}}
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="table-no-results" v-if="! users.length">
                {{ $t('users.no_results') }}
            </div>

            <div class="pagination pagination--push-top" v-if="totalPages > 1">
                <span @click="paginate(n)" v-for="n in paginationPages"
                      :class="{'active' : n === currentPage}">{{ n }}</span>
            </div>
        </div>
    </div>
</template>

<script>
    import debounce from 'lodash.debounce';
    import moment from 'moment';

    export default {
        name: 'users-index',

        data() {
            return {
                users: [],
                search: this.$store.getters['users/searchQuery'],
                currentPage: 1,
                totalPages: 1,
                sortByColumn: null,
                sortDirection: 'asc',
            };
        },

        computed: {
            paginationPages() {
                const totalPages = this.totalPages;
                const page = this.currentPage;
                const pages = 17;

                let lowerLimit = Math.min(page, totalPages);
                let upperLimit = Math.min(page, totalPages);

                for (let b = 1; b < pages && b < totalPages;) {
                    if (lowerLimit > 1) {
                        lowerLimit--;
                        b++;
                    }

                    if (b < pages && upperLimit < totalPages) {
                        upperLimit++;
                        b++;
                    }
                }

                if (lowerLimit === 0 || upperLimit === 0) {
                    return [];
                }

                return Array(upperLimit - lowerLimit + 1).fill().map((_, idx) => lowerLimit + idx);
            },
        },

        beforeMount() {
            this.fetchUsers();
        },

        methods: {
            createUser() {
                this.$router.push({ name: 'users.create' });
            },

            editUser(user) {
                this.$router.push({ name: 'users.edit', params: { id: user.id } });
            },

            moment: function (date) {
                return moment(date);
            },

            paginate(page) {
                this.currentPage = page;

                this.fetchUsers().then(response => {
                    const table = this.$refs.overviewTable;
                    table.scrollIntoView({ behavior: 'smooth' });
                });
            },

            onSearch: debounce(function () {
                this.$store.commit('users/setSearchQuery', this.search);
                this.fetchUsers();
            }, 500),

            fetchUsers() {
                const params = {
                    page: this.currentPage,
                    ...(this.search) && { search: this.search },
                    ...(this.sortByColumn && { orderBy: this.sortByColumn }),
                    ...(this.sortByColumn && { orderByDirection: this.sortDirection }),
                };

                const promise = window.axios.get('/users', { params });

                promise.then((response) => {
                    this.users = response.data.data;
                    this.currentPage = response.data.current_page;
                    this.totalPages = response.data.last_page;
                });

                return promise;
            },

            sortBy(s) {
                if (s === this.sortByColumn) {
                    this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
                } else {
                    this.sortDirection = 'asc';
                }

                this.sortByColumn = s;
                this.users = [];
                this.fetchUsers();
            },

        },
    };
</script>
