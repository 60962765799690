<template>
    <div class="paragraph">
        <div class="paragraph-header">
            <div class="title">
                <h1>{{ chapterPosition }}.</h1>
                <input :disabled="versionDisabled" type="text" v-model="paragraphTitle">
            </div>

            <div class="options-menu" v-if="!versionDisabled">
                <div class="btn-circle" href="#">
                    <svg fill="none" height="4" viewBox="0 0 16 4" width="16" xmlns="http://www.w3.org/2000/svg">
                        <path clip-rule="evenodd"
                              d="M2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0ZM14 0C12.9 0 12 0.9 12 2C12 3.1 12.9 4 14 4C15.1 4 16 3.1 16 2C16 0.9 15.1 0 14 0ZM6 2C6 0.9 6.9 0 8 0C9.1 0 10 0.9 10 2C10 3.1 9.1 4 8 4C6.9 4 6 3.1 6 2Z"
                              fill="black"
                              fill-rule="evenodd" />
                    </svg>
                </div>

                <div class="options-menu-items">
                    <a @click.prevent="deleteParagraph = paragraph" href="#">{{ $t('manual_paragraph.delete_paragraph') }}</a>
                </div>
            </div>
        </div>

        <div class="paragraph-content">
            <manual-component-list :chapter="chapter"
                            :chapterKey="chapterKey"
                            :components="paragraph.components"
                            :language="language"
                            :paragraph="paragraph"
                            :versionDisabled="versionDisabled" />

            <draggable :disabled="versionDisabled" @end="onEndDrag" handle=".sub-paragraph-header" v-model="subParagraphs">
                <manual-sub-paragraph :chapter="chapter"
                                      :chapterKey="`${chapterKey}.${subParagraph.id}`"
                                      :key="'manual-sub-paragraph-' + subParagraph.id"
                                      :language="language"
                                      :paragraph="paragraph"
                                      :paragraph-position="chapterPosition + '.' + (index + 1)"
                                      :class="'js-ref-target-' + subParagraph.id"
                                      :sub-paragraph="subParagraph"
                                      :versionDisabled="versionDisabled"
                                      v-for="(subParagraph, index) of paragraph.chapters" />
            </draggable>
        </div>

        <div @click="showNewSubParagraphModal = true" class="paragraph-footer" v-if="!versionDisabled">
            <div class="img-container">
                <img src="/svg/plus.svg">
            </div>
            <span>{{ $t('manual_paragraph.add_subsection') }}</span>
        </div>

        <portal to="modals">
            <new-sub-paragraph-modal :language="language"
                                     :paragraph="paragraph"
                                     @add-sub-paragraph="addSubParagraph"
                                     @close="showNewSubParagraphModal = false"
                                     v-if="showNewSubParagraphModal" />
            <delete-paragraph-modal :language="language"
                                    @close="deleteParagraph = null"
                                    @confirm="confirmDeleteParagraph"
                                    v-if="deleteParagraph" />
        </portal>
    </div>
</template>

<script>
    import draggable from 'vuedraggable';

    export default {
        name: 'manual-paragraph',

        components: {
            draggable,
        },

        props: {
            versionDisabled: {
                required: true,
            },
            chapterKey: {
                type: String,
                required: true,
            },
            chapterPosition: {
                type: String,
                required: true
            },
            paragraph: {
                type: Object,
                required: true
            },
            chapter: {
                type: Object,
                required: true
            },
            manualId: {
                type: Number,
                required: true
            },
            language: {
                type: Object,
                required: true,
            }
        },

        computed: {
            subParagraphs: {
                set(subParagraphs) {
                    this.$store.commit('chapters/update_paragraph_sub_paragraphs', {
                        chapterId: this.chapter.id,
                        paragraphId: this.paragraph.id,
                        subParagraphs: subParagraphs,
                    });
                },
                get() {
                    return this.$store.getters['chapters/sub_paragraphs_by_id'](this.chapter.id, this.paragraph.id);
                },
            },
        },

        data() {
            return {
                showNewSubParagraphModal: false,
                paragraphTitle: '',
                deleteParagraph: null,
                oldLanguage: null,
                start: true,
            }
        },

        watch: {
            paragraphTitle: function () {
                if (this.start) {
                    this.start = false;
                    return;
                }
                this.debouncedUpdateParagraphTitle();
            },

            language: function () {
                this.setTitle();
            }
        },

        methods: {
            setTitle() {
                const translation = this.paragraph.translations.find(t => t.language === this.language.code);

                this.paragraphTitle = translation.content;

                this.oldLanguage = this.language
            },
            addSubParagraph(subParagraph) {
                this.$store.dispatch('chapters/store_sub_paragraph', {
                    manual_id: this.manualId,
                    chapter_id: this.paragraph.id,
                    content: subParagraph.translations[0].content,
                    paragraph: this.paragraph,
                    language: this.language,
                });
            },

            onEndDrag(a) {
                if (a.newIndex === a.oldIndex) {
                    return;
                }

                this.$store.dispatch('chapters/swap_chapter_positions', {
                    chapterId: this.subParagraphs[a.oldIndex].id.toString(),
                    position: a.newIndex + 1,
                    parentChapterKey: this.chapterKey,
                });
            },

            updateParagraphTitle() {
                const translation = this.paragraph.translations.find(t => t.language === this.language.code);

                if(this.paragraphTitle === translation.content) {
                    return;
                }

                let params = {
                    chapterKey: this.chapterKey,
                    id: this.paragraph.id,
                    content: this.paragraphTitle,
                    language: this.oldLanguage,
                };
                this.$store.dispatch('chapters/update_chapter', params);
                if (this.oldLanguage.code !== this.language.code) {
                    this.oldLanguage = this.language;
                    this.setTitle()
                }
            },

            confirmDeleteParagraph() {
                const paragraph = this.deleteParagraph;
                this.deleteParagraph = null;

                this.$store.dispatch('chapters/delete_chapter', {
                    chapterKey: this.chapterKey,
                    chapter: paragraph,
                });
            },
        },

        created() {
            this.debouncedUpdateParagraphTitle = _.debounce(this.updateParagraphTitle, 500);
            this.setTitle();
        }
    };
</script>
