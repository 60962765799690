<template>
    <div class="content-wrap__column">
        <div class="filters">
            <div class="search">
                <label for="search">
                    <img alt="search" src="/img/search.svg">
                </label>
                <input id="search" :placeholder="$t('general.search') + '...'" type="text" v-model="search" />
            </div>

            <div class="selector">
                <div :class="{ active:agreed === false }" @click="agreed = false" class="item">
                    {{ $t('documents.disagreed') }}
                </div>
                <div :class="{ active:agreed === true }" @click="agreed = true" class="item">
                    {{ $t('documents.agreed') }}
                </div>
                <div :class="{ active:agreed === null }" @click="agreed = null" class="item">
                    {{ $t('documents.show_all') }}
                </div>
            </div>

            <vue-multiselect
                :close-on-select="true"
                :multiple="false"
                :options="businessUnits"
                :searchable="false"
                :placeholder="this.$t('manual_tab_divisions.all_divisions')"
                class="division-select"
                id="businessUnits"
                label="name"
                v-model="selectedBusinessUnit"
                selected-label=""
                select-label=""
                deselect-label=""
                track-by="id">
                <template slot="tag" slot-scope="props">
                    <span v-text="props.option.name + ', '"></span>
                </template>
                <template slot="option"
                            slot-scope="props">
                    <div class="option__desc">
                        <span class="option__title">{{ props.option.name }}</span>
                        <img alt="check"
                                class="selected" src="/svg/check.svg">
                    </div>
                </template>
            </vue-multiselect>
        </div>

        <table class="main-table">
            <thead>
                <tr>
                    <th>{{ $t('users.name')}}</th>
                    <th>{{ $t('general.status') }}</th>
                    <th>{{ $t('documents.date_agreed') }}</th>
                    <th>{{ $t('users.phone') }}</th>
                    <th>{{ $t('general.division') }}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="user in users" :key="user.id">
                    <td>{{ user.name }}</td>
                    <td>{{ user.acceptedAt ? $t('documents.agreed') : $t('documents.disagreed') }}</td>
                    <td>{{ user.acceptedAt | dateTime }}</td>
                    <td>{{ user.phoneNumber }}</td>
                    <td>{{ user.businessUnit }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    import moment from 'moment';
    import VueMultiselect from 'vue-multiselect/src/Multiselect';
    import { BusinessUnitService } from "../../services";
    import { debounce } from "lodash";

    export default {
        props: {
            value: {
                required: true,
            },
        },
        components: {
            VueMultiselect
        },
        data() {
            return {
                search: '',
                agreed: false,
                selectedBusinessUnit: null,
                businessUnits: [],
                users: [],
            };
        },
        watch: {
            value() {
                this.getUsers();
            },
            agreed() {
                this.getUsers();
            },
            selectedBusinessUnit() {
                this.getUsers();
            },
            search() {
                this.getUsers();
            },
        },
        methods: {
            getBusinessUnits() {
                BusinessUnitService.fetchByPermission(this.$permissions.ADD_AND_EDIT_DOCUMENTS).then((response) => {
                    this.businessUnits = response.data;
                });
            },
            getUsers: debounce(async function () {
                this.users = (await window.axios.get('/documents/files/' + this.value.id + '/users', {
                    params: {
                        search: this.search,
                        agreed: this.agreed,
                        business_unit_id: this.selectedBusinessUnit ? this.selectedBusinessUnit.id : null,
                    },
                })).data.data;
            }, 500),
        },
        async mounted() {
            this.getBusinessUnits()
            this.getUsers();
        },
        filters: {
            dateTime(value) {
                return !value ? '' : moment(value).format('DD-MM-YYYY HH:mm');
            },
        },
    };
</script>
