<template>
    <div>
        <div class="chapters-content-wrap">
            <div class="chapters">
                <div class="content-head">
                    <h1>{{ $t('article_tab_content.general_info') }}</h1>

                    <div class="title-input">
                        <label for="title">{{ $t('article_tab_content.title') }}</label>
                        <input :disabled="titleFieldDisabled()"
                               id="title"
                               type="text"
                               v-model="title">
                    </div>

                    <label>{{ $t('article_tab_content.primary_image') }}</label>

                    <div class="component">
                        <div class="component--content">
                            <div class="image-uploader" v-if="! article.featured_image">
                                <input @change="previewImage"
                                       accept="image/*"
                                       ref="image"
                                       class="image-uploader-input"
                                       type="file"
                                       @blur="updateTitle">

                                <p>{{ $t('upload_component.add_files') }}</p>
                                <span>{{ $t('upload_component.or') }}</span>

                                <a href="#" @click.prevent="$refs.image.click()" class="btn btn--blue">
                                    {{ $t('upload_component.add_image') }}
                                </a>
                            </div>

                            <div class="image-preview" v-if="article.featured_image">
                                <img :src="article.featured_image" v-if="article.featured_image.includes('base64')">
                                <img :src="'/files/articles/' + article.featured_image" v-else>
                                <a @click.prevent="removeImage" href="#" v-if="article.published_at === null">
                                    {{ $t('upload_component.delete_image') }}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="chapter article">
                    <div class="article-header">
                        {{ $t('article_tab_content.content') }}
                    </div>
                    <div class="chapter-content article-content">
                        <component-list :components="article.components"
                                        :data="article"
                                        :language="language"
                                        :type="'article'" />
                    </div>
                </div>

                <div class="chapter article">
                    <div class="article-header">
                        {{ $t('article_tab_tags.title') }}
                    </div>
                    <div class="chapter-content article-content">
                        <multiselect
                            v-model="selectedTags"
                            :options="tags"
                            :multiple="true"
                            :searchable="true"
                            :taggable="true"
                            @tag="addNewTag"
                            track-by="name"
                            label="name"
                            :placeholder="$t('article_tab_tags.select')">
                        </multiselect>
                    </div>
                </div>
            </div>

            <div class="chapters-navigation">
                <div class="chapters-navigation-header">
                    <h3>{{ $t('general.settings') }}</h3>
                </div>

                <div class="chapters-navigation-items">
                    <div class="item item--datepicker">
                        <span>{{ $t('article_tab_content.publish_at') }}</span>
                        <datetime v-model="publishDate"
                                  :placeholder="$t('article_tab_content.select_date')"
                                  type="datetime"
                                  :min-datetime="disabledDates.to.toISOString()" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import debounce from 'lodash.debounce';
    import moment from 'moment';
    import { Datetime } from 'vue-datetime';
    import { Settings } from 'luxon';
    import ComponentList from './ComponentList';
    import Multiselect from 'vue-multiselect';

    Settings.defaultLocale = 'nl';

    export default {
        name: 'ArticleTabContent',
        components: { ComponentList, Datetime, Multiselect },
        props: {
            article: {
                type: Object,
                required: true,
            },
            language: {
                type: Object,
                required: true,
            },
        },

        data() {
            return {
                title: null,
                oldLanguage: null,
                publishDate: null,
                featured_image: null,
                disabledDates: { to: new Date() },
                tags: [],
                selectedTags: [],
            };
        },

        mounted() {
            if (this.statePublishAtDate) {
                this.publishDate = this.statePublishAtDate;
            }
        },

        created() {
            this.debouncedUpdateComponent = debounce(this.updateTitle, 1500);
            this.setContent();
            setTimeout(() => {
                this.publishDate = this.article.publish_at ? new Date(this.article.publish_at).toISOString() : null;
            }, 400);
            this.fetchTags();
        },

        watch: {
            title(newVal, oldVal) {
                if (oldVal !== null) {
                    this.debouncedUpdateComponent();
                }
            },

            publishDate() {
                this.updatePublishAtDate();
            },

            article() {
                this.setContent();
            },

            language() {
                if (this.oldLanguage && this.oldLanguage.code !== this.language.code) {
                    this.updateTitle();
                }
            },
            selectedTags() {
                this.updateTags();
            },
        },
        methods: {
            setContent() {
                if (this.article.id) {
                    if (this.oldLanguage === null) {
                        this.oldLanguage = this.language;
                    }
                    const self = this;
                    const translation = this.article.translations.filter((translations) => translations.language === self.language.code)[0];

                    this.title = translation.content;

                    this.oldLanguage = this.language;

                    this.selectedTags = this.article.tags
                                            .map(articleTag => this.tags.find(tag => tag.id === articleTag.id))
                                            .filter(tag => tag !== undefined);
                }
            },

            updateTitle() {
                this.$store.dispatch('articles/set_article_title', {
                    title: this.title,
                    language: this.oldLanguage,
                });

                if (this.oldLanguage.code !== this.language.code) {
                    this.oldLanguage = this.language;
                    this.setContent();
                    return;
                }

                this.$resetMessages();
                this.$flashMessage(this.$t('article_tab_content.article_success_flash'), 5000, 'succes');
            },

            updatePublishAtDate() {
                const date = this.publishDate ? moment(this.publishDate).format('YYYY-MM-DD HH:mm:ss') : null;
                this.$store.dispatch('articles/set_article_publish_at_date', {
                    date,
                });

                this.$resetMessages();
                this.$flashMessage(this.$t('article_tab_content.article_success_flash'), 5000, 'succes');
            },

            removeImage() {
                this.article.featured_image = ''; // eslint-disable-line
                this.$store.dispatch('articles/remove_article_image');
            },

            titleFieldDisabled() {
                if (this.article.published_at !== undefined) {
                    if (this.article.published_at !== null) {
                        return true;
                    }
                } else if (this.article.published_at === null) {
                    return true;
                }
                return false;
            },

            previewImage(event) {
                const input = event.target;

                if (input.files && input.files[0]) {
                    const reader = new FileReader();

                    reader.onload = (e) => {
                        if (e.total > 22000000) {
                            this.$flashMessage(this.$t('article_tab_content.image_to_large'), 5000);
                            return;
                        }
                        this.article.featured_image = e.target.result; // eslint-disable-line
                        this.$store.dispatch('articles/set_article_image', { image: e.target });
                        this.$resetMessages();
                        this.$flashMessage(this.$t('article_tab_content.article_success_flash'), 5000, 'succes');
                    };

                    reader.readAsDataURL(input.files[0]);
                }
            },

            updateTags() {
                this.$store.dispatch('articles/set_selected_tags', { selectedTags: this.selectedTags });

                this.$resetMessages();
                this.$flashMessage(this.$t('article_tab_content.article_success_flash'), 5000, 'succes');
            },

            async fetchTags() {
                try {
                    const response = await axios.get('/tags');
                    this.tags = response.data;
                } catch (error) {
                    console.error('Error fetching tags:', error);
                }
            },

            addNewTag(newTagName) {
                axios.post('/tags', { name: newTagName })
                     .then(response => {
                         const createdTag = response.data;
                         this.tags.push(createdTag);
                         this.selectedTags.push(createdTag);
                     })
                     .catch(error => {
                         console.error('Error adding new tag:', error);
                     });
            },
        },

        computed: {
            statePublishAtDate() {
                return this.$store.getters['articles/article']?.publish_at;
            },
        },
    };
</script>

<style lang="scss" scoped>
    .image-uploader-input {
        display : none;
    }
</style>
