<template>
    <h1 contenteditable @focus="isLocked = true" @keyup="onKeyPress" @keydown="onKeyDown" @blur="onBlur" v-html="content"></h1>
</template>

<script>
    export default {
        name: 'h1-primitive',

        props: ['value'],

        data() {
            return {
                content: this.value,
                isLocked: false,
            };
        },

        watch: {
            value: function () {
                if (!this.isLocked) {
                    this.content = this.value;
                }
            },
        },

        methods: {
            onKeyDown(e) {
                if (e.key === 'Enter') {
                    e.preventDefault();
                }
            },

            onKeyPress(e) {
                this.$emit('input', e.target.innerText);
            },

            onBlur(e) {
                this.$emit('blur', e);
                this.isLocked = false;
            },
        },
    }
</script>
