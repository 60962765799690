<template>
    <div>
        <portal-target name="modals">
            <!--Teleport modals here-->
        </portal-target>
        <portal to="modals">
            <delete-doc-modal v-if="showDeleteDocModal"
                              @close="showDeleteDocModal = false" @confirm="deleteDoc" />
        </portal>
        <div class="content">
            <div id="n-header" class="header">
                <ul class="breadcrumbs">
                    <div v-for="breadcrumb in computedBreadcrumbs">
                        <a v-if="! Array.isArray(breadcrumb)" @click.prevent="gotoDirectory(breadcrumb.id)">{{ breadcrumb.name }}</a>
                        <a v-else @click="togglePopover">
                            ...
                            <div v-click-outside="closePopOver" :class="{ show : showBreadcrumbs }" class="popover-breads">
                                <li v-for="subBreadcrumb in breadcrumb">
                                    <a @click.prevent="gotoDirectory(subBreadcrumb.id)">{{ subBreadcrumb.name }}</a>
                                </li>
                            </div>
                        </a>
                    </div>
                </ul>
                <div id="n-header-buttons" class="header-buttons">
                    <div>
                        <div class="button light-blue" @click="showDeleteDocModal = true">
                            <span>{{ $t('documents_edit_link.delete_link') }}</span>
                        </div>
                    </div>
                    <div>
                        <div class="button green">
                            <span @click="updateDocument">{{ $t('documents_edit_link.save_changes') }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-wrap">
                <div :class="{'content-wrap__content--push-right': !! $route.params.id}" class="content-wrap__content">
                    <div class="panel panel--push-top">
                        <h2 class="title title--push-bottom">{{ $t('documents_edit_link.data') }}</h2>

                        <div class="field">
                            <label class="label" for="name">{{ $t('documents_add_link.title') }}</label>
                            <input id="name" v-model="name" type="text" value="AB Texel Group Wagenpark" />
                        </div>

                        <div class="field">
                            <label class="label" for="url">{{ $t('documents_add_link.link') }}</label>
                            <input id="url" v-model="url" type="text" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import DeleteDocModal from './DeleteDocModal';

    export default {
        name: 'documents-app',
        components: { DeleteDocModal },
        data() {
            return {
                name: null,
                url: null,
                showDeleteDocModal: false,
                breadcrumbs: [],
                showBreadcrumbs: false,
                parentId: null,
            };
        },

        beforeMount() {
            this.getData();
        },

        methods: {
            getData() {
                window.axios.get('/documents/files/' + this.$route.params.id).then((response) => {
                    this.name = response.data.name;
                    this.parentId = response.data.document_path_id;
                    this.url = response.data.url;
                    this.getFolderStructure();
                });
            },

            updateDocument() {
                const requestOptions = {
                    'Content-Type': 'application/json',
                };

                window.axios.post(`/documents/files/update/${this.$route.params.id}`, {
                    'name': this.name,
                    'url': this.url,
                }, requestOptions)
                      .then((response) => {
                          if (response.data.document_path_id !== null) {
                              this.$router.push('/documenten/' + response.data.document_path_id);
                          } else {
                              this.$router.push('/documenten/');
                          }
                      })
                      .catch((err) => {
                          const errors = Object.entries(err.response.data.errors).map((err) => err[1]).flat().join('<br>');
                          this.$flashMessage(errors, 5000, 'error');
                      });
            },

            removeDoc() {
                this.title = null;
                this.link = null;
            },

            deleteDoc() {
                window.axios.delete('/documents/files/delete/' + this.$route.params.id).then(response => {
                    if (response.data.parent_id !== null) {
                        this.$router.push('/documenten/' + response.data.parent_id);
                    } else {
                        this.$router.push('/documenten/');
                    }
                });
            },

            getFolderStructure() {
                window.axios.get('/documents/path/' + this.parentId).then(response => {
                    this.breadcrumbs = response.data.breadcrumbs;
                });
            },
            togglePopover: function () {
                this.showBreadcrumbs = !this.showBreadcrumbs;
                // some code to filter users
            },
            closePopOver() {
                this.showBreadcrumbs = false;
            },

            gotoDirectory(id) {
                if (id === null) {
                    return;
                }

                this.$router.push(`/documenten/${id}`);
            },

            generatePreviewUrl() {
                return '/documents/files/download/' + this.originalDocument.id + '?' + (new Date()).getTime();
            },

        },

        computed: {
            computedBreadcrumbs() {
                const breadcrumbs = this.breadcrumbs.slice().reverse();
                breadcrumbs.push({
                    id: null,
                    name: this.$t('documents_edit_link.edit_link'),
                });

                if (breadcrumbs.length <= 3) {
                    return breadcrumbs;
                }

                return [
                    ...breadcrumbs.slice(0, 2),
                    ...[breadcrumbs.slice(2, breadcrumbs.length - 1)],
                    ...breadcrumbs.slice(-1),
                ];
            },
        },
    };
</script>
