<template>
    <div class="content">
        <div class="header">
            <h1>{{ $route.params.id ? $t('role_form.change_role') : $t('role_form.add_role') }}</h1>

            <div class="header-buttons">
                <div v-if="$route.params.id" @click="deleteRole">
                    <div class="button danger">
                        <span>{{ $t('role_form.delete_role') }}</span>
                    </div>
                </div>

                <div @click="save">
                    <div class="button green">
                        <span>{{ ! $route.params.id ? $t('role_form.add_role') : $t('role_form.save_changes') }}</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="panel panel--push-top">
            <h2 class="title title--push-bottom">
                {{ $t('role_form.data') }}
            </h2>

            <div class="field">
                <label for="name" class="label">{{ $t('role_form.name') }}</label>
                <input type="text" id="name" v-model="name" />
            </div>

            <label for="divisions">{{ $t('role_form.divisions') }}</label>
            <vue-multiselect
                    label="name"
                    track-by="id"
                    :close-on-select="false"
                    select-label=""
                    selected-label=""
                    deselect-label=""
                    :multiple="true"
                    :allowEmpty="false"
                    :searchable="false"
                    :options="businessUnits"
                    id="divisions"
                    class="multiselect--push-bottom"
                    v-model="selectedBusinessUnits">
                <template slot="tag" slot-scope="props">
                    <span v-text="props.option.name + ', '" />
                </template>
                <template slot="option" slot-scope="props">
                    <div class="option__desc">
                        <span class="option__title">{{ props.option.name }}</span>
                        <img alt="check" class="selected" src="/svg/check.svg">
                    </div>
                </template>
            </vue-multiselect>

            <h3 class="panel-title">{{ $t('role_form.permissions_label') }}</h3>
            <div class="field field--checkbox" v-for="permission in permissions" :key="`permission_${permission.id}`">
                <input :id="`per_${permission.id}`" type="checkbox" v-model="selectedPermissions" :value="permission.id" multiple />
                <label :for="`per_${permission.id}`"></label>
                <label class="label" :for="`per_${permission.id}`">{{ $t(`role_form.permissions.${permission.name}`) }}</label>
            </div>
        </div>
    </div>
</template>

<script>
    import VueMultiselect from 'vue-multiselect/src/Multiselect';

    export default {
        name: 'roles-form',

        components: { VueMultiselect },

        beforeRouteEnter(to, from, next) {
            const roleId = to.params.id;

            if (roleId) {
                next((vm) => {
                    vm.fetchRole(roleId);
                });

                return;
            }

            next();
        },

        data() {
            return {
                businessUnits: [],
                permissions: [],

                name: null,
                selectedPermissions: [],
                selectedBusinessUnits: [],
            };
        },

        beforeMount() {
            this.getBusinessUnits();
            this.getPermissions();
        },

        methods: {
            getRoleId() {
                return this.$route.params.id;
            },

            getBusinessUnits() {
                window.axios.get('/business-units').then((response) => {
                    this.businessUnits = response.data;
                });
            },

            getPermissions() {
                window.axios.get('/permissions').then((response) => {
                    this.permissions = response.data;
                });
            },

            save() {
                const roleId = this.getRoleId();

                const params = {
                    name: this.name,
                    businessUnits: this.selectedBusinessUnits.map(b => b.id),
                    permissions: this.selectedPermissions,
                };

                if (roleId) {
                    this.update(roleId, params);
                    return;
                }

                this.store(params);
            },

            store(params) {
                window.axios.post('/roles', params).then((response) => {
                    this.$flashMessage(this.$t('role_form.role_saved'), 5000, 'succes');
                    this.$router.push({ name: 'roles.edit', params: { id: response.data.id } });
                }).catch((err) => {
                    const errors = Object.entries(err.response.data.errors).map(error => error[1]).flat().join('<br>');
                    this.$flashMessage(errors, 5000, 'error');
                });
            },

            update(roleId, params) {
                window.axios.put(`/roles/${roleId}`, params).then(() => {
                    this.$flashMessage(this.$t('role_form.role_saved'), 5000, 'succes');
                    this.fetchRole(roleId);
                }).catch((err) => {
                    let errors = '';
                    if(err.response.status === 403)
                    {
                        errors = this.$t('role_form.forbidden_message');
                    }else{
                        errors = Object.entries(err.response.data.errors).map(error => error[1]).flat().join('<br>');
                    }

                    this.$flashMessage(errors, 5000, 'error');
                });
            },

            fetchRole(id) {
                window.axios.get(`/roles/${id}`).then((response) => {
                    const role = response.data;

                    this.name = role.name;
                    this.selectedBusinessUnits = role.business_units;
                    this.selectedPermissions = role.permissions.map(p => p.id);
                });
            },

            deleteRole() {
                const roleId = this.getRoleId();

                window.axios.delete(`/roles/${roleId}`).then(() => {
                    this.$flashMessage(this.$t('role_form.role_deleted'), 5000, 'succes');
                    this.$router.push({ name: 'roles.index' });
                }).catch((err) => {
                    this.$flashMessage(this.$t('role_form.forbidden_message'), 5000, 'error');
                });
            },
        },
    };
</script>

<style lang="scss">
    .panel-title {
        margin-top    : 40px;
        margin-bottom : 24px;
    }

    .button.danger {
        color: #CC5833;
    }
</style>
