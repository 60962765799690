import EventsIndex from "../components/Events/index";
import EventsForm from "../components/Events/event-form";

export default [
    {
        path: '/evenementen',
        component: EventsIndex,
        name: 'events.index',
        meta: {
            permissions: ['add-and-edit-events']
        },
    },
    {
        path: '/evenementen/nieuw',
        component: EventsForm,
        name: 'events.create',
        meta: {
            permissions: ['add-and-edit-events']
        },
    },
    {
        path: '/evenementen/:id',
        component: EventsForm,
        name: 'events.edit',
        meta: {
            permissions: ['add-and-edit-events']
        },
    },
];