<template>
    <div class="content">
        <div class="header">
            <h1>{{ $t('business_units.business_units') }}</h1>
        </div>

        <div class="body">
            <table ref="overviewTable">
                <thead>
                    <tr>
                        <th>{{$t('roles.name') }}</th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-for="businessUnit in businessUnits" :key="'business_unit_' + businessUnit.id"
                        @click="edit(businessUnit)">
                        <td>{{ businessUnit.name }}</td>
                    </tr>
                </tbody>
            </table>

            <div class="table-no-results" v-if="! businessUnits.length">
                {{ $t('roles.no_results') }}
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'business-units-index',

        data() {
            return {
                businessUnits: [],
            };
        },

        beforeMount() {
            this.fetchBusinessUnits();
        },

        methods: {
            edit(businessUnit) {
                this.$router.push({ name: 'businessUnitsManagement.edit', params: { id: businessUnit.id } });
            },

            fetchBusinessUnits() {
                const promise = window.axios.get('/business-units');

                promise.then((response) => {
                    this.businessUnits = response.data;
                });

                return promise;
            },
        },
    }
</script>
