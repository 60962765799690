<template>
    <div>
        <div class="panel panel--push-top">
            <article-push-notification-tabs :article="article" />

            <div class="body">
                <h2 class="title title--push-bottom">
                    {{ $t('article_tab_push_notifications.push_notification') }}
                </h2>

                <div class="field">
                    <label for="messageNl" class="label">{{
                        $t('article_tab_push_notifications.push_notification_dutch')
                    }}</label>
                    <input type="text" id="messageNl" v-model="message.nl">
                </div>

                <div class="field">
                    <label for="messagePl" class="label">{{
                        $t('article_tab_push_notifications.push_notification_polish')
                    }}</label>
                    <input type="text" id="messagePl" v-model="message.pl">
                </div>

                <div class="field">
                    <label for="messageEn" class="label">{{
                        $t('article_tab_push_notifications.push_notification_english')
                    }}</label>
                    <input type="text" id="messageEn" v-model="message.en">
                </div>

                <div class="field">
                    <label for="messageDe" class="label">{{
                        $t('article_tab_push_notifications.push_notification_german')
                    }}</label>
                    <input type="text" id="messageDe" v-model="message.de">
                </div>

                <div class="field">
                    <label for="messageFr" class="label">{{
                        $t('article_tab_push_notifications.push_notification_french')
                    }}</label>
                    <input type="text" id="messageFr" v-model="message.fr">
                </div>

                <div class="field">
                    <div href="#" class="button green" @click.prevent="sendPushNotification">
                        <span>
                            {{ article.published_at ? $t('article_tab_push_notifications.send_notification') : $t('article_tab_push_notifications.save_notification') }}
                        </span>
                    </div>
                </div>
                <p>{{ $t('article_tab_push_notifications.message') }}</p>
            </div>
        </div>
    </div>
</template>

<script>
    import ArticlePushNotificationTabs from '../ArticlePushNotificationsTabs';

    export default {
        name: 'ArticleTabPushNotificationsSend',

        components: {
            ArticlePushNotificationTabs,
        },

        props: {
            article: {
                type: Object,
                required: true,
            },
        },

        data() {
            return {
                message: {
                    nl: null,
                    en: null,
                    pl: null,
                    de: null,
                    fr: null,
                },
            };
        },

        methods: {
            sendPushNotification() {
                const messages = Object.keys(this.message).filter((key) => this.message[key]).reduce((obj, key) => {
                    obj[key] = this.message[key];
                    return obj;
                }, {});

                const params = {
                    messages,
                };

                window.axios.post(`/articles/${this.article.id}/send-push-notification`, params)
                    .then(() => {
                        if (this.article.publish_at) {
                            this.$flashMessage(this.$t('article_tab_push_notifications.flash_message'), 5000, 'succes');
                        } else {
                            this.$flashMessage(this.$t('article_tab_push_notifications.flash_message_save'), 5000, 'succes');
                        }
                    });
            },

            getPushNotifications() {
                window.axios.get(`/push-notifications/${this.$route.params.id}`)
                      .then((response) => {
                        Object.keys(response.data.data).forEach((key) => {
                            this.message[key] = response.data.data[key];
                        });
                    });
            },
        },
        mounted() {
            this.getPushNotifications();
        },
    };
</script>

<style lang="scss" scoped>
    .panel {
        padding : 0;

        .body {
            margin  : 0;
            padding : 32px 16px 56px 16px;

            .button {
                display       : flex;
                border-radius : 6px;
                width         : fit-content;
                height        : 48px;
                font-size     : 16px;
                font-weight   : 600;

                span {
                    border-radius : 6px;
                    padding       : 12px 42px;
                    transition    : all 0.2s ease;
                }

                &.green {
                    box-shadow : 0 2px 4px rgba(0, 0, 0, 0.25);
                    background : var(--color-secondary);
                    color      : var(--color-white);

                    span {
                        &:hover {
                            background : var(--color-secondary-darken);
                            cursor     : pointer;
                        }
                    }
                }
            }
        }
    }
</style>
