<template>
    <base-modal @close="close">
        <h1>{{ $t('new_paragraph_modal.add_paragraph') }}</h1>

        <label>{{ $t('new_paragraph_modal.name_paragraph') }}</label>

        <input type="text"
               v-model="value"
               @keydown.enter="addChapter">

        <div class="btn-wrap btn-wrap--right">
            <button :disabled="value.length === 0"
                    @click="addChapter"
                    class="btn btn--green btn--shadow btn--bold">
                {{ $t('new_paragraph_modal.add_paragraph') }}
            </button>
        </div>
    </base-modal>
</template>

<script>
    export default {
        name: 'new-paragraph-modal',

        props: {
            chapter: {
                type: Object,
                required: true,
            },
        },

        data() {
            return {
                value: '',
            };
        },

        methods: {
            close() {
                this.$emit('close');
            },

            addChapter() {
                if (this.value.length > 0) {
                    this.$emit('add-paragraph', {
                        id: Math.random().toString(36).replace('0.', ''),
                        chapter_id: this.chapter.id,
                        chapters: [],
                        components: [],
                        position: null,
                        translations: [
                            {
                                content: this.value,
                                language: 'nl'
                            }
                        ]
                    });

                    this.close();
                }
            }
        }
    };
</script>
