<template>
    <div class="idea-component">
        <div class="idea-component-header">
            <h1>{{ subject }}</h1>
            <div v-click-outside="clickedOutside" class="status">
                <span class="value" @click="showStatusDropdown = !showStatusDropdown">
                    {{ ideaStatus }}
                    <img :class="{'rotate':showStatusDropdown}" class="icon" src="/svg/blue-caret-down.svg">
                </span>
                <transition name="fade">
                    <span v-show="showStatusDropdown" class="dropdown">
                        <span :class="{'selected':status === 1}" class="dropdown-item"
                              @click="setState(1)">{{ $t('ideabox.status.open').toUpperCase() }}
                        </span>
                        <span :class="{'selected':status === 2}" class="dropdown-item"
                              @click="setState(2)">{{ $t('ideabox.status.doing').toUpperCase() }}
                        </span>
                        <span :class="{'selected':status === 3}" class="dropdown-item"
                              @click="setState(3)">{{ $t('ideabox.status.done').toUpperCase() }}
                        </span>
                    </span>
                </transition>
            </div>
            <span>
                <img :src="'/users/' + user.id + '/image'">
                <span :class="{'clickable':hasManageUserPermission}" class="no-styling"
                      @click="routeToUser()">{{ user.name }}</span> &bullet; {{
                    user.email
                                                                    }} &bullet; {{ user.business_unit }}  &bullet; {{
                    formattedDate
                                                                    }}
            </span>
        </div>
        <div class="idea-component-body">
            <slot></slot>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import ClickOutside from 'vue-click-outside';

export default {
    name: "IdeaComponent",

    directives: {
        ClickOutside
    },

    props: {
        id: {
            type: Number,
            required: true
        },
        status: {
            type: Number,
            required: true
        },
        subject: {
            type: String,
            required: true
        },
        user: {
            type: Object,
            required: true
        },
        date: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            showStatusDropdown: false,
        }
    },

    methods: {
        clickedOutside() {
            if (this.showStatusDropdown) {
                this.showStatusDropdown = false
            }
        },
        setState(value) {
            if (value === this.status) {
                return;
            }
            this.showStatusDropdown = false;
            window.axios.post('/ideabox/' + this.id, { 'status': value }).then(() => {
                this.$emit('updateIdea', value)
            });
        },
        routeToUser() {
            if (this.hasManageUserPermission) {
                this.$router.push({ name: 'users.edit', params: { id: this.user.id } });
            }
        },
    },

    computed: {
        hasManageUserPermission() {
            return this.$can('add-and-edit-users');
        },
        ideaStatus() {
            switch (this.status) {
                case(1):
                    return this.$t('ideabox.status.open').toUpperCase()
                case(2):
                    return this.$t('ideabox.status.doing').toUpperCase()
                case(3):
                    return this.$t('ideabox.status.done').toUpperCase()
                default:
                    return ''
            }

        },
        formattedDate() {
            let date = moment(this.date);
            let today = moment();

            if (date.isSame(today.clone().startOf('day'), 'd')) {
                return 'vandaag';
            }

            if (date.isSame(today.clone().subtract(1, 'day').startOf('day'), 'd')) {
                return 'gisteren';
            }

            return date.format('DD-MM-Y');
        }
    }
}
</script>

<style scoped>
.clickable {
    cursor: pointer;
}
</style>
