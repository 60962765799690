<template>
    <div class="tabs">
        <router-link tag="div" to="content">
            {{ $t('manual_tabs.content') }}
        </router-link>
        <router-link tag="div" to="aanpassingen">
            {{ $t('manual_tabs.adjustments') }}
        </router-link>
        <router-link tag="div" to="akkoord-gegeven">
           {{ $t('manual_tabs.agree') }}
        </router-link>
        <router-link tag="div" to="divisies">
            {{ $t('manual_tabs.divisions') }}
        </router-link>
        <router-link tag="div" to="instellingen">
            Instellingen
        </router-link>
    </div>
</template>

<script>
    export default {
        name: 'manual-tabs'
    };
</script>
