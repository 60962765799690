<template>
    <base-modal @close="close">
        <h1>{{ $t('add_chapter_link_modal.select_modal') }}</h1>

        <div class="field">
            <label for="label" class="label">{{ $t('add_chapter_link_modal.label') }}</label>
            <input type="text" v-model="label" id="label" />
        </div>

        <div class="field">
            <label for="manual" class="label">{{ $t('add_chapter_link_modal.manual') }}</label>
            <select id="manual" v-model="selectedManual">
                <option :value="null"></option>
                <option v-for="manual in manuals" :value="manual">
                    {{ manual.name }} - v{{ manual.versions[0].version }}
                </option>
            </select>
        </div>

        <div class="field" v-if="selectedManual">
            <label for="chapter" class="label">{{ $t('add_chapter_link_modal.chapter') }}</label>
            <select id="chapter" v-model="selectedChapter">
                <option :value="null"></option>
                <option v-for="chapter in availableChapters" :value="chapter">
                    {{ chapter.translations[0].content }}
                </option>
            </select>
        </div>

        <div class="field" v-if="selectedChapter">
            <label for="paragraph" class="label">{{ $t('add_chapter_link_modal.paragraph') }}</label>
            <select id="paragraph" v-model="selectedParagraph">
                <option :value="null"></option>
                <option v-for="paragraph in availableParagraphs" :value="paragraph">
                    {{ paragraph.translations[0].content }}
                </option>
            </select>
        </div>

        <div class="btn-wrap btn-wrap--right">
            <a href="#" class="btn" @click.prevent="close">{{ $t('add_chapter_link_modal.cancel') }}</a>
            <a href="#" class="btn btn--shadow" :class="{'btn--green': this.selectedManual && this.selectedChapter}"
               @click.prevent="confirm">{{ $t('add_chapter_link_modal.insert') }}</a>
        </div>
    </base-modal>
</template>

<script>
    export default {
        name: 'add-chapter-link',

        props: {
            languageCode: {
                required: true,
                type: String,
            },
        },

        data() {
            return {
                label: null,
                selectedManual: null,
                selectedChapter: null,
                selectedParagraph: null,
                manuals: [],
            };
        },

        created() {
            // On the manual adjustment page it's possible to select chapters from the current selected unpublished manual.
            let config = {
                unpublished: this.$route.name === 'adjustments' ? 1 : 0,
            }

            window.axios.get('/manuals/all-manuals', {
                params: config
            }).then(response => {
                this.manuals = response.data.map((manual) => {
                    manual.versions[0].chapters = manual.versions[0].chapters.map((chapter) => {
                        chapter.translations = chapter.translations.filter(t => t.language === this.languageCode);

                        return chapter;
                    });

                    return manual;
                });
            })
        },

        computed: {
            availableChapters() {
                const manual = this.manuals.find(m => m.id === this.selectedManual.id);

                return manual.versions[0].chapters;
            },
            availableParagraphs() {
                this.selectedChapter.chapters.map(chapter => {
                    chapter.translations = chapter.translations.filter(t => t.language === this.languageCode);

                    return chapter;
                });
                return this.selectedChapter.chapters;
            },
        },

        methods: {
            close() {
                this.$emit('close');
            },

            confirm() {
                if (!this.selectedManual || !this.selectedChapter) {
                    return;
                }

                this.$emit('confirm', {
                    label: this.label,
                    manual: this.selectedManual,
                    chapter: this.selectedParagraph ? this.selectedParagraph : this.selectedChapter,
                });
            },
        },
    };
</script>
