const state = {
    contacts: [],
    searchQuery: '',
    selectedDepartments: [],
    selectedBusinessUnits: [],
};

const actions = {};

const mutations = {
    setSearchQuery(state, value) {
        state.searchQuery = value;
    },

    setSelectedDepartments(state, departments) {
        state.selectedDepartments = departments;
    },

    setSelectedBusinessUnits(state, businessUnits) {
        state.selectedBusinessUnits = businessUnits;
    },
};

const getters = {
    contacts: (state) => state.contacts,
    searchQuery: (state) => state.searchQuery,
    selectedDepartments: (state) => state.selectedDepartments,
    selectedBusinessUnits: (state) => state.selectedBusinessUnits,
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
