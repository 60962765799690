<template>
    <div>
        <div class="content">
            <div class="header" id="n-header">
                <h1 id="n-h1">{{ $t('news_app.news') }}</h1>
                <div class="header-buttons" id="n-header-buttons">
                    <div @click="createTip">
                        <div class="button light-blue">
                            <span>{{ $t('news_app.tip_article') }}</span>
                        </div>
                    </div>
                    <div @click="createArticle">
                        <div class="button green">
                            <span>{{ $t('news_app.add_article') }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="body">
                <div class="filters">
                    <div class="search">
                        <label for="search">
                            <img alt="search" src="img/search.svg">
                        </label>
                        <input @keyup="onSearch" id="search" :placeholder="$t('news_app.search')" type="text" v-model="searchQuery" />
                    </div>

                    <vue-multiselect
                        :close-on-select="true"
                        :multiple="false"
                        :options="businessUnits"
                        :searchable="false"
                        :placeholder="this.$t('manual_tab_divisions.all_divisions')"
                        class="division-select"
                        id="businessUnits"
                        label="name"
                        v-model="selectedBusinessUnit"
                        selected-label=""
                        select-label=""
                        deselect-label=""
                        @input="filterBusinessUnit"
                        track-by="id">
                        <template slot="tag" slot-scope="props">
                            <span v-text="props.option.name + ', '"></span>
                        </template>
                        <template slot="option"
                                  slot-scope="props">
                            <div class="option__desc">
                                <span class="option__title">{{ props.option.name }}</span>
                                <img alt="check"
                                     class="selected" src="/svg/check.svg">
                            </div>
                        </template>
                    </vue-multiselect>
                </div>

                <table>
                    <thead>
                        <tr>
                            <th class="more sortable" :class="{'sorted': sortByColumn === 'title'}" @click="sortBy('title')">{{ $t('news_app.title') }}</th>
                            <th class="sortable" :class="{'sorted': sortByColumn === 'article_type_id'}" @click="sortBy('article_type_id')">{{ $t('news_app.type') }}</th>
                            <th>Divisies</th>
                            <th class="sortable" :class="{'sorted': sortByColumn === 'updated_at'}" @click="sortBy('updated_at')">{{ $t('news_app.last_edited') }}</th>
                            <th class="sortable" :class="{'sorted': sortByColumn === 'planned_publication'}" @click="sortBy('publish_at')">{{ $t('news_app.planned_publication') }}</th>
                            <th class="sortable" :class="{'sorted': sortByColumn === 'published_at'}" @click="sortBy('published_at')">{{ $t('news_app.posted') }}</th>
                        </tr>
                    </thead>

                    <tbody ref="tableBody">
                        <router-link
                            :key="article.id"
                            :to="getLink(article)"
                            tag="tr"
                            v-for="article in articles">
                            <td>{{ getContent(article.translations) }}</td>
                            <td>{{ article.article_type_id === 1 ? 'Nieuwsartikel' : 'Tip'}}</td>
                            <td>{{ article.business_units.map(b => b.name).join(', ') }}</td>
                            <td>{{ article.updated_at | toDate }}</td>
                            <td>{{ article.publish_at | toDate }}</td>
                            <td>{{ article.published_at | toDate }}</td>
                        </router-link>
                    </tbody>
                </table>
                <div class="table-no-results" v-if="! articles.length">
                    {{ $t('news_app.no_results') }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import debounce from 'lodash.debounce';
    import VueMultiselect from 'vue-multiselect/src/Multiselect';
    import businessUnitService from '../services/business-units';

    export default {
        name: 'news-app',

        components: { VueMultiselect },

        data() {
            return {
                loading: false,
                loaded: false,
                articles: [],
                businessUnits: [],
                selectedBusinessUnit: null,
                page: 0,
                searchQuery: null,
                sortByColumn: null,
                sortDirection: 'asc',
            };
        },

        mounted() {
            this.$store.commit('articles/set_article', []);
            this.loadMore();

            window.addEventListener('scroll', (event) => {
                if (this.$refs.tableBody !== undefined) {
                    const lastEl = this.$refs.tableBody.rows.length - 1;
                    const el = this.$refs.tableBody.rows[lastEl];

                    if (this.isInViewport(el)) {
                        this.loadMore();
                    }
                }
                this.setTopbarStyle()
            }, false);
        },

        methods: {
            getContent(translations) {
                const nlTranslation = translations.find(t => t.language === 'nl');

                if (!nlTranslation.content || !nlTranslation.content.length) {
                    const translation = translations.find(t => !!t.content);

                    return translation ? translation.content : '';
                }

                return nlTranslation.content;
            },

            getBusinessUnits() {
                businessUnitService.fetch().then((response) => {
                    this.businessUnits = response.data;
                });
            },

            getArticles() {
                const url = '/articles' + (this.selectedBusinessUnit ? '/' + this.selectedBusinessUnit.id : '');

                window.axios.get(url).then(response => {
                    this.articles = response.data;
                });
            },

            getLink(article) {
                if (article.article_type_id === 1) {
                    return '/nieuws/artikel/' + article.id;
                }
                return '/nieuws/tip-artikel/' + article.id;
            },

            createTip() {
                window.axios.get('/articles/get', {
                    params: {
                        id: 0,
                        article_type_id: 2,
                    }
                }).then((response) => {
                    this.$router.push('/nieuws/tip-artikel/' + response.data.id + '/content');
                });
            },

            createArticle() {
                window.axios.get('/articles/get', {
                    params: {
                        article_type_id: 1,
                        id: 0,
                    }
                }).then((response) => {
                    this.$router.push('/nieuws/artikel/' + response.data.id + '/content');
                });
            },

            setTopbarStyle() {
                let element = document.getElementById('n-header');
                let h1 = document.getElementById('n-h1');
                let buttons = document.getElementById('n-header-buttons');
                if (element) {
                    if (element.getBoundingClientRect().y === 0) {
                        element.style.boxShadow = '0px 4px 12px rgba(0, 0, 0, 0.1)';
                        element.style.background = '#f9fafb';
                        element.style.width = 'calc(100vw - 280px)';
                        element.style.transform = 'translateX(-80px)';
                        element.style.padding = '19px 0';
                        h1.style.marginLeft = '80px';
                        h1.style.marginTop = '10px';
                        h1.style.marginBottom = '10px';
                        buttons.style.marginRight = '95px';
                        buttons.style.padding = '6px 0';
                    } else {
                        element.style.boxShadow = null;
                        element.style.background = null;
                        element.style.width = null;
                        element.style.transform = null;
                        element.style.padding = null;
                        h1.style.marginLeft = null;
                        h1.style.marginTop = null;
                        h1.style.marginBottom = null;
                        buttons.style.marginRight = null;
                        buttons.style.padding = null;
                    }
                }
            },

            loadMore() {
                if (this.loading || this.loaded) {
                    return;
                }

                this.loading = true;

                const params = {
                    page: this.page,
                    searchQuery: this.searchQuery,
                    businessUnit: this.selectedBusinessUnit ? this.selectedBusinessUnit.id : null,
                    ...(this.sortByColumn && { orderBy: this.sortByColumn }),
                    ...(this.sortByColumn && { orderByDirection: this.sortDirection }),
                };

                window.axios.get('/articles', { params }).then(response => {
                    this.page++;

                    if (!response.data.length) {
                        this.loaded = true;
                    } else {
                        this.articles.push(...response.data);
                    }

                    this.loading = false;
                });
            },

            isInViewport(elem) {
                if (!elem) {
                    return false
                }
                let bounding = elem.getBoundingClientRect();

                return (
                    bounding.top >= 0 &&
                    bounding.left >= 0 &&
                    bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                    bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
                );
            },

            sortBy(s) {
                if (s === this.sortByColumn) {
                    this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
                } else {
                    this.sortDirection = 'asc';
                }

                this.sortByColumn = s;
                this.resetArticlesState();
                this.loadMore();
            },

            async filterBusinessUnit() {
                await this.resetArticlesState();
                await this.loadMore();
            },

            async resetArticlesState() {
                this.loaded = false;
                this.page = 0;
                this.articles = [];
            },

            onSearch: debounce(function () {
                this.resetArticlesState();
                this.loadMore();
            }, 500),
        },
        beforeMount() {
            this.getBusinessUnits();
        },
    };
</script>
