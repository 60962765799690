export default {
    install(Vue, options) {
        Vue.prototype.$can = function (permission) {
            if (!window.permissions) {
                return false;
            }

            permission = Array.isArray(permission) ? permission : [permission];

            return permission.every(v => window.permissions.includes(v));
        };

        Vue.prototype.$canAny = function (permission) {
            if (!window.permissions) {
                return false;
            }

            return permission.some(p => window.permissions.includes(p));
        };

        Vue.prototype.$permissions = {
            ADD_AND_EDIT_INSTRUCTIONS: 'add-and-edit-instructions',
            ADD_AND_EDIT_NEWS_ITEMS: 'add-and-edit-news-items',
            ADD_AND_EDIT_DOCUMENTS: 'add-and-edit-documents',
            ADD_AND_EDIT_USERS: 'add-and-edit-users',
            ADD_AND_EDIT_CONTACTS: 'add-and-edit-contacts',
            ADD_AND_EDIT_EVENTS: 'add-and-edit-events',
            ADD_AND_EDIT_LOCATIONS: 'add-and-edit-locations',
            MANAGE_PUSH_NOTIFICATIONS: 'manage-push-notifications',
            ASSESS_EXPENSES: 'assess-expenses',
            SEE_STATISTICS: 'see-statistics',
            ADD_AND_EDIT_ROLES: 'add-and-edit-roles',
            EDIT_DIVISIONS: 'edit-divisions',
            SEE_IDEABOX: 'see-ideabox',
        };
    }
};
