<template>
    <div>
        <div class="content-head">
            <h1>{{ $t('article_tab_story.story_of') }}</h1>
            <div class="input-group related-articles">
                <label for="profile">{{ $t('article_tab_story.profile') }}</label>
                <vue-multiselect
                        id="profile"
                        v-model="article.story_profile"
                        v-bind="translations"
                        :options="contacts"
                        label="email"
                        :custom-label="multiSelectLabel"
                        :allow-empty="true"
                        :disabled="isDisabled"
                        @close="setProfile">
                </vue-multiselect>
            </div>
        </div>
    </div>
</template>

<script>
    import VueMultiselect from "vue-multiselect/src/Multiselect";

    export default {
        name: 'article-tab-story',

        components: {
            VueMultiselect
        },

        props: {
            article: {
                required: true,
            }
        },

        data() {
            return {
                translations: {
                    placeholder: this.$t('article_tab_story.select_profile'),
                    label: "name",
                    'track-by': "id",
                    selectLabel: "",
                    selectedLabel: "",
                    deselectLabel: "",
                },
                businessUnits: [],
                departments: [],
                contacts: [],
            }
        },

        computed: {
            stateArticle() {
                return this.$store.state.articles.article;
            },

            isDisabled() {
                const storyProfile = this.article && this.article.story_profile ? this.article.story_profile : null;

                if (!storyProfile) {
                    return false;
                }

                return !this.contacts.find(c => c.id === storyProfile.id);
            },
        },

        watch: {
            'stateArticle.story_user_id': function (newVal, oldVal) {
                if (newVal === oldVal || oldVal === undefined) {
                    return;
                }

                this.save();
            },
        },

        methods: {
            getContacts() {
                window.axios.get(`/contacts/list?permission=${this.$permissions.ADD_AND_EDIT_NEWS_ITEMS}`).then((response) => {
                    this.contacts = response.data;
                });
            },

            setProfile() {
                this.$store.commit('articles/set_story_user_id', this.article.story_profile ? this.article.story_profile.id : null);
            },

            multiSelectLabel(item) {
                let businessUnits = item.business_units && item.business_units.length ? item.business_units.map(bu => bu.name).join(' | ') : 'Alle divisies';
                let departments = item.departments && item.departments.length ? item.departments.map(d => d.name).join(' | ') : 'Alle afdeling';

                if (item.business_units && item.business_units.length === this.businessUnits.length) {
                    businessUnits = this.$t('article_tab_story.all_divisions')
                }

                if (item.departments && item.departments.length === this.departments.length) {
                    departments = this.$t('article_tab_story.all_departments')
                }

                return `${item.title} - ${businessUnits} - ${departments}`;
            },

            save() {
                window.axios.put(`/articles/${this.article.id}/story-profile`, {
                    contact: this.article.story_profile ? this.article.story_profile.id : null,
                });
            },

            getBusinessUnits() {
                window.axios.get('/business-units').then((response) => {
                    this.businessUnits = response.data
                });
            },

            getDepartments() {
                window.axios.get('/departments').then((response) => {
                    this.departments = response.data
                });
            },
        },

        beforeMount() {
            this.getContacts();
            this.getBusinessUnits();
            this.getDepartments();
        },
    };
</script>
