<template>
    <div class="content">
        <div class="header">
            <h1>{{ businessUnit.name ? businessUnit.name : $t('business_units.business_unit') }}</h1>

            <div class="header-buttons">
                <div @click="save">
                    <div class="button green">
                        <span>{{ $t('role_form.save_changes') }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="body">
            <div class="tabs">
                <router-link :to="'/divisies/'+$route.params.id+'/general'"
                             tag="div">
                    {{ $t('business_units.general_label') }}
                </router-link>
                <router-link :to="'/divisies/'+$route.params.id+'/permissions'"
                             tag="div">
                    {{ $t('business_units.permissions_label') }}
                </router-link>
                <router-link :to="'/divisies/'+$route.params.id+'/emails'"
                             tag="div">
                    {{ $t('business_units.emails_label') }}
                </router-link>
                <router-link :to="'/divisies/'+$route.params.id+'/anniversaries'"
                             tag="div">
                    {{ $t('business_units.anniversaries_label') }}
                </router-link>
                <router-link :to="'/divisies/'+$route.params.id+'/exports'"
                             tag="div">
                    {{ $t('business_units.export_label') }}
                </router-link>
            </div>
            <router-view :anniversaries="anniversaries"
                         :business-unit="businessUnit"
                         :emails="emails"
                         :businessUnitName="name"
                         :permissions="permissions"
                         :selected-permissions="selectedPermissions"
                         @update="updateData" />
        </div>
    </div>
</template>

<script>
    export default {
        name: 'BusinessUnitEdit',

        beforeRouteEnter(to, from, next) {
            const businessUnitId = to.params.id;

            if (businessUnitId) {
                next((vm) => {
                    vm.fetchBusinessUnit(businessUnitId);
                });

                return;
            }

            next();
        },

        data() {
            return {
                businessUnit: {},
                anniversaries: [],
                permissions: [],
                selectedPermissions: [],
                name: '',
                emails: {
                    expensesEmail: null,
                    furloughPlanningEmail: null,
                    furloughHoursControlEmail: null,
                },
            };
        },

        beforeMount() {
            this.getPermissions();
        },

        methods: {
            updateData(data) {
                this[data.key] = data.value;
            },

            getBusinessUnitId() {
                return this.$route.params.id;
            },

            getPermissions() {
                window.axios.get('/business-units/permissions').then((response) => {
                    this.permissions = response.data;
                });
            },

            save() {
                const businessUnitId = this.getBusinessUnitId();

                const params = {
                    permissions: this.selectedPermissions,
                    emails: this.emails,
                    anniversaries: this.anniversaries,
                    name: this.name,
                };

                if (businessUnitId) {
                    this.update(businessUnitId, params);
                    return;
                }

                this.store(params);
            },

            update(businessUnitId, params) {
                window.axios.put(`/business-units/${businessUnitId}`, params).then(() => {
                    this.$flashMessage(this.$t('business_units.saved'), 5000, 'succes');
                    this.fetchBusinessUnit(businessUnitId);
                }).catch((err) => {
                    const errors = Object.entries(err.response.data.errors).map((error) => error[1]).flat().join('<br>');

                    this.$flashMessage(errors, 5000, 'error');
                });
            },

            fetchBusinessUnit(id) {
                window.axios.get(`/business-units/${id}`).then((response) => {
                    this.businessUnit = response.data;
                    this.anniversaries = this.businessUnit.anniversaries;
                    this.selectedPermissions = this.businessUnit.permissions.map((p) => p.id);

                    this.emails.expensesEmail = this.businessUnit.expenses_contact_email;
                    this.emails.furloughPlanningEmail = this.businessUnit.furlough_planning_email;
                    this.emails.furloughHoursControlEmail = this.businessUnit.furlough_hours_control_email;
                    this.name = this.businessUnit.name;
                });
            },
        },
    };
</script>

<style lang="scss">
    .panel {
        max-width : 1000px;
    }

    .panel-title {
        margin-top    : 0;
        margin-bottom : 24px;
    }

    .button.danger {
        color : #CC5833;
    }
</style>
