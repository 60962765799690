<template>
    <div class="panel panel--push-top">
        <h3 class="panel-title">{{ $t('business_units.emails_label') }}</h3>
        <div class="field">
            <label class="label" for="expensesEmail">
                {{ $t('business_units.emails.expenses_email') }}
                {{ $t('business_units.emails.comma_seperated') }}
            </label>
            <input id="expensesEmail" v-model="emails.expensesEmail"
                   type="text" @change="update" />
        </div>

        <div class="field">
            <label class="label" for="furloughPlanningEmail">
                {{ $t('business_units.emails.furlough_planning_email') }}
                {{ $t('business_units.emails.comma_seperated') }}
            </label>
            <input id="furloughPlanningEmail" v-model="emails.furloughPlanningEmail"
                   type="text" @change="update" />
        </div>

        <div class="field">
            <label class="label" for="furloughHoursControlEmail">
                {{ $t('business_units.emails.furlough_hours_control_email') }}
                {{ $t('business_units.emails.comma_seperated') }}
            </label>
            <input id="furloughHoursControlEmail" v-model="emails.furloughHoursControlEmail"
                   type="text" @change="update" />
        </div>
    </div>
</template>

<script>
    export default {
        name: 'EmailsTab',
        props: {
            businessUnit: {
                required: true,
            },
            emails: {
                required: true,
            },
            permissions: {
                required: true,
            },
        },
        methods: {
            update() {
                this.$emit('update', { key: 'emails', value: this.emails });
            },
        },
    };
</script>
