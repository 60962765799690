<template>
    <div class="panel--push-top">
        <table>
            <thead>
                <tr>
                    <th>{{ $t('business_units.exports.type') }}</th>
                    <th class="export" />
                </tr>
            </thead>

            <tbody>
                <tr>
                    <td>{{ $t('business_units.exports.types.accepted_terms_last_login') }}</td>
                    <td class="export" @click-="exportData">
                        <span @click="exportData('accepted_terms_last_login')">{{ $t('business_units.exports.export') }}
                            <img src="/img/file_download.svg" alt="File download button"></span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    export default {
        name: 'ExportTab',

        methods: {
            exportData(type) {
                window.axios.get(`/business-units/${this.$route.params.id}/export`, {
                    params: { type },
                    responseType: 'blob',
                }).then((data) => {
                    const downloadUrl = window.URL.createObjectURL(new Blob([data.data]));
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.setAttribute('download', 'export.xlsx');
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                });
            },
        },
    };
</script>

<style scoped lang="scss">
    table {
        tbody {
            tr {
                td.export {
                    text-align : right;

                    span {
                        padding     : 12px;
                        font-weight : 700;
                        color       : rgba(0, 105, 180, 1);
                    }
                }
            }
        }
    }
</style>
