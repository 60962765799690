<template>
    <base-modal @close="close">
        <h1>{{ $t('manual_google_translate.automatic_translation') }}</h1>

        <p>{{ $t('manual_google_translate.automatic_translation_warning') }}</p>

        <div class="btn-wrap btn-wrap--right">
            <a href="#" class="btn" @click.prevent="close">{{ $t('delete_chapter_modal.cancel') }}</a>
            <a href="#" class="btn btn--red btn--shadow" @click.prevent="confirm">{{ $t('manual_google_translate.translate') }}</a>
        </div>
    </base-modal>
</template>

<script>
    import BaseModal from '../../BaseModal';

    export default {
        name: 'GoogleTranslateModal',

        components: {
            BaseModal,
        },

        methods: {
            close() {
                this.$emit('close');
            },

            confirm() {
                this.$emit('confirm');
            }
        },
    };
</script>
