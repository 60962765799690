<template>
    <content-page :title="$t('navigation.my_service_schedule')"
                  :content-page-id="1" />
</template>

<script>
    import ContentPage from './ContentPage';

    export default {
        name: 'MyServiceSchedule',
        compontens: {
            ContentPage,
        },
    };
</script>
