<template>
    <BaseModal @close="$emit('close')">
        <h1>{{ $t('publish_manual_version_confirmation_modal.publish') }}</h1>

        <div class="btn-wrap btn-wrap--right">
            <button class="btn" @click.prevent="$emit('close')">{{ $t('publish_manual_version_confirmation_modal.no') }}</button>
            <button class="btn btn--green btn--shadow" @click.prevent="$emit('confirm', true)">{{ $t('publish_manual_version_confirmation_modal.yes') }}</button>
        </div>
    </BaseModal>
</template>

<script>
    import BaseModal from '../../BaseModal';

    export default {
        name: 'PublishManualVersionConfirmationModal',

        components: {
            BaseModal
        }
    }
</script>