const state = {
    searchQuery: '',
};

const mutations = {
    setSearchQuery(state, value) {
        state.searchQuery = value;
    },
};

const getters = {
    searchQuery: (state) => state.searchQuery,
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
};
