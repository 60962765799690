<template>
    <div class="tabs">
        <router-link tag="div" :to="{ name: 'push-notification-send' }">{{ $t('article_push_notification_tabs.send') }}</router-link>
        <router-link tag="div" :to="{ name: 'push-notification-overview' }">{{ $t('article_push_notification_tabs.overview') }}</router-link>
    </div>
</template>

<script>
    export default {
        name: 'article-push-notification-tabs',
        props: {
            article: {
                required: true,
            },
        },
    };
</script>

<style lang="scss" scoped>
    .tabs {
        margin : 0;
    }
</style>
