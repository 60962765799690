import UsersIndex from "../components/Users/index";
import UsersForm from "../components/Users/user-form";
import UsersDocs from "../components/Users/user-docs";

export default [
    {
        path: '/gebruikers',
        component: UsersIndex,
        name: 'users.index',
        meta: {
            permissions: ['add-and-edit-users']
        },
    },
    {
        path: '/gebruikers/nieuw',
        component: UsersForm,
        name: 'users.create',
        meta: {
            permissions: ['add-and-edit-users']
        },
    },
    {
        path: '/gebruikers/:id/algemeen',
        component: UsersForm,
        name: 'users.edit',
        meta: {
            permissions: ['add-and-edit-users']
        },
    },
    {
        path: '/gebruikers/:id/documenten',
        component: UsersDocs,
        name: 'users.docs',
        meta: {
            permissions: ['add-and-edit-users']
        },
    },
];