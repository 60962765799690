<template>
    <div class="field field--checkbox">
        <input class="blue" :id="name" type="checkbox" :checked="checked" @input="updateValue" />
        <label :for="name"></label>
        <label class="label" :for="name">{{ label }}</label>
    </div>
</template>

<script>
    export default {
        name: 'Checkbox',

        props: {
            checked: {
                required: false,
                default: false,
                type: Boolean,
            },
            name: {
                require: true,
                type: String,
            },
            label: {
                required: true,
                type: String,
            },
        },

        methods: {
            updateValue(e) {
                this.$emit('input', e.target.checked);
            },
        },
    };
</script>

<style lang="scss" scoped>
    .field {
        margin-bottom : 0;
    }

    .label {
        color   : white !important;
        opacity : 1 !important;
    }

    input {
        display : none;

        & + label {
            position      : relative;
            display       : block;
            border-radius : 100px;
            width         : 50px;
            height        : 32px;
            background    : grey;
            cursor        : pointer;
            text-indent   : -9999px;

            &:active:after {
                width : 30px;
            }

            &:after {
                content       : '';
                position      : absolute;
                top           : 3px;
                left          : 3px;
                border-radius : 26px;
                width         : 26px;
                height        : 26px;
                background    : var(--color-white);
                transition    : 0.3s;
            }
        }

        &:checked {
            & + label {
                background : var(--color-secondary);

                &:after {
                    left      : calc(100% - 3px);
                    transform : translateX(-100%);
                }
            }
        }

        &.blue {
            &:checked {
                & + label {
                    background : rgba(#FFFFFF, 0.5);
                }
            }

            & + label {
                background : rgba(#FFFFFF, 0.2);
            }
        }
    }
</style>