<template>
    <div>
        <div class="statistics-content-wrap-header">
            <h2>{{ $t('statistics_app_fuel_reports.reports_opened') }}</h2>
        </div>
        <table class="table">
            <thead>
                <tr>
                    <th>{{ $t('statistics_app_fuel_reports.month') }}</th>
                    <th>{{ $t('statistics_app_fuel_reports.opened_number_second') }}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="report in reports"
                    :key="report.month">
                    <td>{{ report.month }}</td>
                    <td>{{ report.total }}</td>
                </tr>
            </tbody>
        </table>
        <ExportFuelReportsForm />
    </div>
</template>

<script>
    import ExportFuelReportsForm from './ExportFuelReportsForm';
    export default {
        name: 'StatisticsTabNews',
        components: { ExportFuelReportsForm },
        data() {
            return {
                reports: null,
            };
        },

        beforeMount() {
            this.getStatistics();
        },

        methods: {
            getStatistics() {
                window.axios.get('/statistics/fuel-reports').then((response) => {
                    this.reports = response.data;
                });
            },
        },

        watch: {
            language() {
                this.getStatistics();
            },
        },
    };
</script>
