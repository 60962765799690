<template>
    <div @mouseenter="onMouseEnter">
        <textarea :data-chapters="chapters"
                  :data-rd-data="JSON.stringify(rdData)"
                  :data-custom-buttons="JSON.stringify(config.customButtons)"
                  :name="name"
                  :placeholder="placeholder"
                  :value="value"
                  id="content"
                  ref="redactor" />
    </div>
</template>
​
<script>
    export default {
        name: 'redactor-text-area',

        props: {
            value: {
                default: '',
                type: String
            },
            rdData: {
                default: null,
            },
            placeholder: {
                type: String,
                default: null
            },
            name: {
                type: String,
                default: null
            },
            config: {
                default: {},
                type: Object
            },
            disabled: {
                default: false,
                type: Boolean
            }
        },
        data() {
            return { redactor: undefined, };
        },
        computed: {
            chapters() {
                const chapters = this.$store.getters['chapters/chaptersStack'].map(c => {
                    return {
                        id: c.id,
                        title: c.translations[0].content,
                    }
                });

                return JSON.stringify(chapters);
            },
        },
        watch: {
            value(newValue, oldValue) {
                if (!this.redactor.editor.isFocus()) this.redactor.source.setCode(newValue)
            },
        },
        mounted() {
            this.init()
        },
        beforeDestroy() {
            this.destroy()
        },
        methods: {
            init() {
                let self = this;
                let callbacks = {
                    ...
                    this.config.callbacks || {},
                    changed: function (html) {
                        self.handleInput(html);
                        return html;
                    },
                    blur: (e) => {
                        self.$emit('blur');
                    }
                };

                if (this.disabled) {
                    this.config.buttons = [];
                    this.config.plugins = [];
                } else {
                    this.config.plugins.push('custombuttons');
                    callbacks.custombuttons = {
                        click: (arg) => {
                            this.$emit('click-custom-button', { key: arg, redactor: this.redactor });
                        }
                    }
                }

                Vue.set(this.config, 'callbacks', callbacks);

                let app = $R(this.$refs.redactor, this.config);

                this.redactor = app;
                this.$parent.redactor = app;

                $R(this.$refs.redactor, this.disabled ? 'module.editor.disable' : 'module.editor.enable');
            },

            destroy() {
                $R(this.$refs.redactor, 'destroy');
                this.redactor = null;
                this.$parent.redactor = null;
            },

            handleInput(val) {
                this.$emit('input', val);
            },

            onMouseEnter() {
                this.redactor.editor.focus();
            },
        }
    }
</script>
