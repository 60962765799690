<template>
    <div class="content">
        <div class="header">
            <h1>{{ $t('push_notifications.notifications') }}</h1>
        </div>
        <div class="tabs">
            <div :class="{'active': activeTab === 1}"
                 @click.prevent="setPredefinedMessages('payslip')">{{ $t('push_notifications.pay_slip_notification') }}
            </div>
            <div :class="{'active': activeTab === 2}"
                 @click.prevent="setPredefinedMessages('fleethours')">{{
                    $t('push_notifications.wage_supplement_notification')
                                                                      }}
            </div>
        </div>

        <div class="panel panel--push-top">
            <h2 class="title title--push-bottom">
                {{ $t('push_notifications.data') }}
            </h2>
            <div class="field">
                <label class="label"
                       for="messageNl">{{ $t('push_notifications.push_notification_dutch') }}</label>
                <input id="messageNl"
                       type="text" v-model="message.nl" />
            </div>

            <div class="field">
                <label class="label"
                       for="messagePl">{{ $t('push_notifications.push_notification_polish') }}</label>
                <input id="messagePl"
                       type="text" v-model="message.pl" />
            </div>

            <div class="field">
                <label class="label"
                       for="messageEn">{{ $t('push_notifications.push_notification_english') }}</label>
                <input id="messageEn"
                       type="text" v-model="message.en" />
            </div>

            <div class="field mb-large">
                <label class="label"
                       for="messageDe">{{ $t('push_notifications.push_notification_german') }}</label>
                <input id="messageDe"
                       type="text" v-model="message.d" />
            </div>

            <div class="field field--checkbox mb-large">
                <input id="onlyAfas"
                       type="checkbox"
                       v-model="onlyAfas" />
                <label for="onlyAfas">asd</label>
                <label class="label"
                       for="onlyAfas">{{ $t('push_notifications.only_afas_users') }}</label>
            </div>

            <div class="field mb-large">
                <label class="label"
                       for="divisions">{{ $t('push_notifications.divisions') }}</label>
                <vue-multiselect
                    :close-on-select="false"
                    :multiple="true"
                    :options="businessUnits"
                    :searchable="false"
                    id="divisions"
                    v-bind="config"
                    v-model="selectedBusinessUnits">
                    <template slot="tag" slot-scope="props">
                        <span v-text="props.option.name + ', '"></span>
                    </template>
                    <template slot="option"
                              slot-scope="props">
                        <div class="option__desc">
                            <span class="option__title">{{ props.option.name }}</span>
                            <img alt="check"
                                 class="selected" src="/svg/check.svg">
                        </div>
                    </template>
                    <span slot="noResult">{{ $t('push_notifications.no_results') }}</span>
                </vue-multiselect>
            </div>

            <div class="field">
                <a @click.prevent="sendPushNotification"
                   class="btn btn--green" href="#">{{ $t('push_notifications.send_notification') }}
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    import VueMultiselect from 'vue-multiselect/src/Multiselect';
    import businessUnitService from "../../services/business-units";

    export default {
        name: 'push-notifications-index',

        components: {
            VueMultiselect,
        },

        data() {
            return {
                config: {
                    placeholder: this.$t('push_notifications.choose'),
                    label: 'name',
                    'track-by': 'id',
                    selectLabel: '',
                    selectedLabel: '',
                    deselectLabel: '',
                },
                message: {
                    nl: null,
                    en: null,
                    pl: null,
                    d: null,
                },
                onlyAfas: true,
                selectedBusinessUnits: [],
                businessUnits: [],
                activeTab: null,
            };
        },

        beforeMount() {
            this.getBusinessUnits();
        },

        methods: {
            setPredefinedMessages(type) {
                switch (type) {
                    case 'payslip':
                        this.message.nl = 'Je nieuwe salarisstrook staat voor je klaar';
                        this.message.en = 'Your new payslip is available';
                        this.message.pl = 'Twój nowy odcinek wynagrodzenia jest dla Ciebie gotowy';
                        this.message.d = 'Ihre neue Gehaltsabrechnung ist für Sie bereit';
                        this.activeTab = 1;
                        break;

                    case 'fleethours':
                        this.message.nl = 'Je nieuwe loonbijlage staat voor je klaar';
                        this.message.en = 'Your new salary attachment is available';
                        this.message.pl = 'Twój nowy rozliczenie godzin jest dla Ciebie gotowy';
                        this.message.d = 'Ihr neuer Gehaltszuschlag steht für Sie bereit';
                        this.activeTab = 2;
                        break;
                }
            },

            sendPushNotification() {
                const messages = Object.keys(this.message).filter(key => this.message[key]).reduce((obj, key) => {
                    obj[key] = this.message[key];
                    return obj;
                }, {});

                if (Object.keys(messages).length === 0) {
                    return;
                }

                const params = {
                    messages,
                    businessUnits: this.selectedBusinessUnits.map(b => b.id),
                    onlyAfas: this.onlyAfas
                };

                window.axios.post(`/push-notifications`, params)
                      .then((response) => {
                          this.$flashMessage(this.$t('push_notifications.push_notification_send'), 5000, 'succes');
                      });
            },

            getBusinessUnits() {
                businessUnitService.fetchByPermission(this.$permissions.MANAGE_PUSH_NOTIFICATIONS).then((response) => {
                    this.businessUnits = response.data
                });
            },
        },
    }
</script>
