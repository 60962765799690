export default {
    nl: {
        componentTypes: {
            title_lg: 'Titel groot',
            title_sm: 'Titel klein',
            textarea: 'Tekstvak',
            image: 'Afbeelding',
            pdf: 'PDF',
            poll: 'Poll',
        },
    },
    en: {
        componentTypes: {
            title_lg: 'Title big',
            title_sm: 'Title small',
            textarea: 'Textbox',
            image: 'Image',
            pdf: 'PDF',
            poll: 'Poll',
        },
    },
};
