<template>
    <div class="panel panel--push-top">
        <h2 class="title">{{ documentType.name }}</h2>

        <a class="delete-document-type" href="#" v-if="documentType.user_id" @click="deleteDocumentType">{{ $t('personal_document.delete_document_type') }}</a>

        <div class="field" v-if="documentType.has_expiration_date">
            <label class="label">
                {{ $t('personal_document.expiration_date') }}
                <strong v-if="expirationDate" @click="clearExpirationDate()">{{ $t('personal_document.delete') }}</strong>
            </label>
            <datepicker format="dd-MM-yyyy" :placeholder="this.$t('personal_document.pick_a_date')" v-model="expirationDate" v-on:input="updateExpirationDate" :disabled="! (frontImage || backImage)" />
        </div>

        <div class="field">
            <label for="frontImage" class="label">{{ documentType.has_back_page ? this.$t('personal_document.front') : this.$t('personal_document.document_upload') }}</label>
            <div class="image-uploader" v-if="! frontImage">
                <input type="file" id="frontImage" accept="application/pdf" ref="frontImageRef" @change="previewImage">

                <p>{{ $t('upload_component.add_files') }}</p>
                <span>{{ $t('upload_component.or') }}</span>

                <a href="#" @click.prevent="$refs.frontImageRef.click()" class="btn btn--blue">
                    {{ $t('upload_component.add_pdf') }}
                </a>
            </div>

            <div class="image-preview" v-if="frontImage">
                <img v-if="isBase64(frontImage) && ! isBase64Pdf(frontImage)" :src="frontImage" />

                <svg v-if="isBase64Pdf(frontImage)" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 309.267 309.267" style="enable-background:new 0 0 309.267 309.267;" xml:space="preserve">
                        <g>
                            <path style="fill:#E2574C;" d="M38.658,0h164.23l87.049,86.711v203.227c0,10.679-8.659,19.329-19.329,19.329H38.658
                                c-10.67,0-19.329-8.65-19.329-19.329V19.329C19.329,8.65,27.989,0,38.658,0z"/>
                            <path style="fill:#B53629;" d="M289.658,86.981h-67.372c-10.67,0-19.329-8.659-19.329-19.329V0.193L289.658,86.981z"/>
                            <path style="fill:#FFFFFF;" d="M217.434,146.544c3.238,0,4.823-2.822,4.823-5.557c0-2.832-1.653-5.567-4.823-5.567h-18.44
                                c-3.605,0-5.615,2.986-5.615,6.282v45.317c0,4.04,2.3,6.282,5.412,6.282c3.093,0,5.403-2.242,5.403-6.282v-12.438h11.153
                                c3.46,0,5.19-2.832,5.19-5.644c0-2.754-1.73-5.49-5.19-5.49h-11.153v-16.903C204.194,146.544,217.434,146.544,217.434,146.544z
                                 M155.107,135.42h-13.492c-3.663,0-6.263,2.513-6.263,6.243v45.395c0,4.629,3.74,6.079,6.417,6.079h14.159
                                c16.758,0,27.824-11.027,27.824-28.047C183.743,147.095,173.325,135.42,155.107,135.42z M155.755,181.946h-8.225v-35.334h7.413
                                c11.221,0,16.101,7.529,16.101,17.918C171.044,174.253,166.25,181.946,155.755,181.946z M106.33,135.42H92.964
                                c-3.779,0-5.886,2.493-5.886,6.282v45.317c0,4.04,2.416,6.282,5.663,6.282s5.663-2.242,5.663-6.282v-13.231h8.379
                                c10.341,0,18.875-7.326,18.875-19.107C125.659,143.152,117.425,135.42,106.33,135.42z M106.108,163.158h-7.703v-17.097h7.703
                                c4.755,0,7.78,3.711,7.78,8.553C113.878,159.447,110.863,163.158,106.108,163.158z"/>
                        </g>
                    </svg>

                <img v-if="! isBase64(frontImage)" :src="`/files/personal-documents/${$route.params.id}/${frontImage}`" />

                <a href="#" @click.prevent="deleteFrontImage">{{ $t('upload_component.delete_image') }}</a>
            </div>
        </div>

<!--        <div class="field" v-if="documentType.has_back_page">-->
<!--            <label for="backImage" class="label">{{ $t('personal_document.back') }}</label>-->
<!--            <div class="image-uploader" v-if="! backImage">-->
<!--                <input type="file" id="backImage" accept="application/pdf" ref="backImageRef" @change="previewImage">-->

<!--                <p>{{ $t('upload_component.add_files') }}</p>-->
<!--                <span>{{ $t('upload_component.or') }}</span>-->

<!--                <a href="#" @click.prevent="$refs.backImageRef.click()" class="btn btn&#45;&#45;blue">-->
<!--                    {{ $t('upload_component.add_image') }}-->
<!--                </a>-->
<!--            </div>-->

<!--            <div class="image-preview" v-if="backImage">-->
<!--                <img v-if="isBase64(backImage) && ! isBase64Pdf(backImage)" :src="backImage" />-->

<!--                <svg v-if="isBase64Pdf(backImage)" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 309.267 309.267" style="enable-background:new 0 0 309.267 309.267;" xml:space="preserve">-->
<!--                        <g>-->
<!--                            <path style="fill:#E2574C;" d="M38.658,0h164.23l87.049,86.711v203.227c0,10.679-8.659,19.329-19.329,19.329H38.658-->
<!--                                c-10.67,0-19.329-8.65-19.329-19.329V19.329C19.329,8.65,27.989,0,38.658,0z"/>-->
<!--                            <path style="fill:#B53629;" d="M289.658,86.981h-67.372c-10.67,0-19.329-8.659-19.329-19.329V0.193L289.658,86.981z"/>-->
<!--                            <path style="fill:#FFFFFF;" d="M217.434,146.544c3.238,0,4.823-2.822,4.823-5.557c0-2.832-1.653-5.567-4.823-5.567h-18.44-->
<!--                                c-3.605,0-5.615,2.986-5.615,6.282v45.317c0,4.04,2.3,6.282,5.412,6.282c3.093,0,5.403-2.242,5.403-6.282v-12.438h11.153-->
<!--                                c3.46,0,5.19-2.832,5.19-5.644c0-2.754-1.73-5.49-5.19-5.49h-11.153v-16.903C204.194,146.544,217.434,146.544,217.434,146.544z-->
<!--                                 M155.107,135.42h-13.492c-3.663,0-6.263,2.513-6.263,6.243v45.395c0,4.629,3.74,6.079,6.417,6.079h14.159-->
<!--                                c16.758,0,27.824-11.027,27.824-28.047C183.743,147.095,173.325,135.42,155.107,135.42z M155.755,181.946h-8.225v-35.334h7.413-->
<!--                                c11.221,0,16.101,7.529,16.101,17.918C171.044,174.253,166.25,181.946,155.755,181.946z M106.33,135.42H92.964-->
<!--                                c-3.779,0-5.886,2.493-5.886,6.282v45.317c0,4.04,2.416,6.282,5.663,6.282s5.663-2.242,5.663-6.282v-13.231h8.379-->
<!--                                c10.341,0,18.875-7.326,18.875-19.107C125.659,143.152,117.425,135.42,106.33,135.42z M106.108,163.158h-7.703v-17.097h7.703-->
<!--                                c4.755,0,7.78,3.711,7.78,8.553C113.878,159.447,110.863,163.158,106.108,163.158z"/>-->
<!--                        </g>-->
<!--                    </svg>-->

<!--                <img v-if="! isBase64(backImage)" :src="`/files/personal-documents/${$route.params.id}/${backImage}`" />-->

<!--                <a href="#" @click.prevent="deleteBackImage">{{ $t('upload_component.delete_image') }}</a>-->
<!--            </div>-->
<!--        </div>-->

<!--        <div class="certificates-wrap" v-if="documentType.has_certificates">-->
<!--            <table class="table-minimal table-minimal&#45;&#45;push-bottom">-->
<!--                <thead>-->
<!--                    <tr>-->
<!--                        <td>-->
<!--                            <h2>{{ $t('personal_document.certificates') }}</h2>-->
<!--                        </td>-->
<!--                        <td></td>-->
<!--                    </tr>-->
<!--                </thead>-->

<!--                <tbody>-->
<!--                    <tr v-for="certificate in certificates" :key="`table_cert_${certificate.id}`">-->
<!--                        <td>{{ certificate.name }}</td>-->
<!--                        <td>-->
<!--                            <svg @click="deleteCertificate(certificate.id)" height="512pt" viewBox="-57 0 512 512" width="512pt" xmlns="http://www.w3.org/2000/svg">-->
<!--                                <path d="m156.371094 30.90625h85.570312v14.398438h30.902344v-16.414063c.003906-15.929687-12.949219-28.890625-28.871094-28.890625h-89.632812c-15.921875 0-28.875 12.960938-28.875 28.890625v16.414063h30.90625zm0 0" />-->
<!--                                <path d="m344.210938 167.75h-290.109376c-7.949218 0-14.207031 6.78125-13.566406 14.707031l24.253906 299.90625c1.351563 16.742188 15.316407 29.636719 32.09375 29.636719h204.542969c16.777344 0 30.742188-12.894531 32.09375-29.640625l24.253907-299.902344c.644531-7.925781-5.613282-14.707031-13.5625-14.707031zm-219.863282 312.261719c-.324218.019531-.648437.03125-.96875.03125-8.101562 0-14.902344-6.308594-15.40625-14.503907l-15.199218-246.207031c-.523438-8.519531 5.957031-15.851562 14.472656-16.375 8.488281-.515625 15.851562 5.949219 16.375 14.472657l15.195312 246.207031c.527344 8.519531-5.953125 15.847656-14.46875 16.375zm90.433594-15.421875c0 8.53125-6.917969 15.449218-15.453125 15.449218s-15.453125-6.917968-15.453125-15.449218v-246.210938c0-8.535156 6.917969-15.453125 15.453125-15.453125 8.53125 0 15.453125 6.917969 15.453125 15.453125zm90.757812-245.300782-14.511718 246.207032c-.480469 8.210937-7.292969 14.542968-15.410156 14.542968-.304688 0-.613282-.007812-.921876-.023437-8.519531-.503906-15.019531-7.816406-14.515624-16.335937l14.507812-246.210938c.5-8.519531 7.789062-15.019531 16.332031-14.515625 8.519531.5 15.019531 7.816406 14.519531 16.335937zm0 0" />-->
<!--                                <path d="m397.648438 120.0625-10.148438-30.421875c-2.675781-8.019531-10.183594-13.429687-18.640625-13.429687h-339.410156c-8.453125 0-15.964844 5.410156-18.636719 13.429687l-10.148438 30.421875c-1.957031 5.867188.589844 11.851562 5.34375 14.835938 1.9375 1.214843 4.230469 1.945312 6.75 1.945312h372.796876c2.519531 0 4.816406-.730469 6.75-1.949219 4.753906-2.984375 7.300781-8.96875 5.34375-14.832031zm0 0" />-->
<!--                            </svg>-->
<!--                        </td>-->
<!--                    </tr>-->
<!--                </tbody>-->
<!--            </table>-->

<!--            <div class="field" :class="{'error': certificateImage && ! certificateName}">-->
<!--                <label class="label" for="certificateName">{{ $t('personal_document.name_certificate') }}</label>-->
<!--                <input id="certificateName" type="text" v-model="certificateName" @blur="submitCertificate" />-->
<!--            </div>-->

<!--            <div class="field field&#45;&#45;uploader" :class="{'error': ! certificateImage && certificateName}">-->
<!--                <label for="certificateImage" class="label">{{ $t('personal_document.add_certificate') }}</label>-->
<!--                <input type="file" id="certificateImage" accept="application/pdf" ref="certificateImageRef" @change="previewCertificateImage">-->

<!--                <div class="image-uploader image-uploader&#45;&#45;shrink image-uploader&#45;&#45;white-bg" v-if="! certificateImage">-->
<!--                    <p>{{ $t('upload_component.add_files') }}</p>-->
<!--                    <span>{{ $t('upload_component.or') }}</span>-->

<!--                    <a href="#" @click.prevent="$refs.certificateImageRef.click()" class="btn btn&#45;&#45;blue">-->
<!--                        {{ $t('upload_component.add_image') }}-->
<!--                    </a>-->
<!--                </div>-->

<!--                <div class="image-preview" v-if="certificateImage">-->
<!--                    <img v-if="isBase64(certificateImage) && ! isBase64Pdf(certificateImage)" :src="certificateImage" />-->

<!--                    <svg v-if="isBase64Pdf(certificateImage)" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 309.267 309.267" style="enable-background:new 0 0 309.267 309.267;" xml:space="preserve">-->
<!--                        <g>-->
<!--                            <path style="fill:#E2574C;" d="M38.658,0h164.23l87.049,86.711v203.227c0,10.679-8.659,19.329-19.329,19.329H38.658-->
<!--                                c-10.67,0-19.329-8.65-19.329-19.329V19.329C19.329,8.65,27.989,0,38.658,0z"/>-->
<!--                            <path style="fill:#B53629;" d="M289.658,86.981h-67.372c-10.67,0-19.329-8.659-19.329-19.329V0.193L289.658,86.981z"/>-->
<!--                            <path style="fill:#FFFFFF;" d="M217.434,146.544c3.238,0,4.823-2.822,4.823-5.557c0-2.832-1.653-5.567-4.823-5.567h-18.44-->
<!--                                c-3.605,0-5.615,2.986-5.615,6.282v45.317c0,4.04,2.3,6.282,5.412,6.282c3.093,0,5.403-2.242,5.403-6.282v-12.438h11.153-->
<!--                                c3.46,0,5.19-2.832,5.19-5.644c0-2.754-1.73-5.49-5.19-5.49h-11.153v-16.903C204.194,146.544,217.434,146.544,217.434,146.544z-->
<!--                                 M155.107,135.42h-13.492c-3.663,0-6.263,2.513-6.263,6.243v45.395c0,4.629,3.74,6.079,6.417,6.079h14.159-->
<!--                                c16.758,0,27.824-11.027,27.824-28.047C183.743,147.095,173.325,135.42,155.107,135.42z M155.755,181.946h-8.225v-35.334h7.413-->
<!--                                c11.221,0,16.101,7.529,16.101,17.918C171.044,174.253,166.25,181.946,155.755,181.946z M106.33,135.42H92.964-->
<!--                                c-3.779,0-5.886,2.493-5.886,6.282v45.317c0,4.04,2.416,6.282,5.663,6.282s5.663-2.242,5.663-6.282v-13.231h8.379-->
<!--                                c10.341,0,18.875-7.326,18.875-19.107C125.659,143.152,117.425,135.42,106.33,135.42z M106.108,163.158h-7.703v-17.097h7.703-->
<!--                                c4.755,0,7.78,3.711,7.78,8.553C113.878,159.447,110.863,163.158,106.108,163.158z"/>-->
<!--                        </g>-->
<!--                    </svg>-->

<!--                    <img v-if="! isBase64(certificateImage)" :src="`/files/personal-documents/${$route.params.id}/${certificateImage}`" />-->

<!--                    <a href="#" @click.prevent="certificateImage = null">{{ $t('upload_component.delete_image') }}</a>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
    </div>
</template>

<script>
    import Datepicker from "vuejs-datepicker";
    import moment from "moment";

    export default {
        name: 'personal-document',

        components: { Datepicker },

        props: ['documentType', 'documents', 'certificates'],

        data() {
            return {
                expirationDate: null,
                frontImage: null,
                backImage: null,
                certificateName: null,
                certificateImage: null,
            };
        },

        watch: {
            documents: function () {
                this.setImages();
                this.setExpirationDate();
                this.certificateImage = this.certificateName = null;
                if (this.documentType.has_certificates) {
                    this.$refs.certificateImageRef.value = null;
                }
            },
        },

        beforeMount() {
            this.setImages();
            this.setExpirationDate();
        },

        methods: {
            deleteDocumentType() {
                window.axios.delete(`/personal-document-types/${this.documentType.id}/delete`).then(() => {
                    this.$emit('personalDocumentTypeDeleted');
                });
            },

            setImages() {
                const frontDocument = this.documents.find(f => !f.is_back_page);
                const backDocument = this.documents.find(f => f.is_back_page);

                this.frontImage = frontDocument ? (frontDocument.preview_path || frontDocument.path) : null;
                this.backImage = backDocument ? (backDocument.preview_path || backDocument.path) : null;
            },

            getUploadedFile(event, callback) {
                const input = event.target;

                if (!input.files || !input.files[0]) {
                    return false;
                }

                const file = input.files[0];
                const extension = file.name.substring(file.name.lastIndexOf('.') + 1);

                let reader = new FileReader();
                reader.onload = (e) => {
                    callback({
                        event: e,
                        file,
                        extension,
                    });
                };

                reader.readAsDataURL(file);

                return reader;
            },

            // submitCertificate() {
            //     if (!this.certificateImage || !this.certificateName || !this.certificateName.length) {
            //         return;
            //     }
            //
            //     const file = this.$refs.certificateImageRef.files[0];
            //
            //     this.$emit('updateCertificate', {
            //         documentType: this.documentType,
            //         name: this.certificateName,
            //         file,
            //     });
            // },
            //
            // previewCertificateImage(event) {
            //     this.getUploadedFile(event, ({ event, file, extension }) => {
            //         if (event.total > 22000000) {
            //             this.$flashMessage('Upload afbeeldingen kleiner dan 22mb', 5000);
            //         }
            //
            //         this.certificateImage = event.target.result;
            //         this.submitCertificate();
            //     });
            // },

            previewImage(event) {
                const input = event.target;
                const imageType = input.id;
                const isBackPage = imageType === 'backImage';

                this.getUploadedFile(event, ({ event, file }) => {
                    if (event.total > 22000000) {
                        this.$flashMessage('Upload afbeeldingen kleiner dan 22mb', 5000);
                    }

                    this.$set(this, imageType, event.target.result);
                    this.$emit('updatePersonalDocument', {
                        expirationDate: this.getExpirationDate(),
                        documentType: this.documentType,
                        isBackPage,
                        document: file,
                    });
                });
            },

            deleteFrontImage() {
                this.frontImage = null;

                this.$emit('deletePersonalDocument', {
                    documentType: this.documentType,
                    isBackPage: false,
                });
            },

            // deleteBackImage() {
            //     this.backImage = null;
            //
            //     this.$emit('deletePersonalDocument', {
            //         documentType: this.documentType,
            //         isBackPage: true,
            //     });
            // },

            updateExpirationDate() {
                this.$emit('updateExpirationDate', {
                    documentType: this.documentType,
                    expirationDate: this.getExpirationDate(),
                });
            },

            clearExpirationDate() {
                this.expirationDate = null;
                this.updateExpirationDate();
            },

            getExpirationDate() {
                return this.expirationDate ? moment(this.expirationDate).format('YYYY-MM-DD') : null;
            },

            setExpirationDate() {
                const frontDocument = this.documents.find(f => !f.is_back_page);
                const backDocument = this.documents.find(f => f.is_back_page);
                const document = frontDocument || backDocument;

                if (document) {
                    this.expirationDate = document.expiration_date || null;
                }
            },

            isBase64(str) {
                return str.includes('base64');
            },

            isBase64Pdf(str) {
                return this.isBase64(str) && str.includes('data:application/pdf');
            },
        },
    }
</script>

<style scoped lang="scss">
    a.delete-document-type {
        opacity       : 0.3;
        font-size     : 12px;
        line-height   : 100%;
        color         : #000000;
        margin-bottom : 24px;
        display       : block;
    }

    .table-minimal {
        td:last-child {
            width : 20px;

            svg {
                cursor  : pointer;
                width   : 20px;
                height  : 20px;
                opacity : 0.7;
            }
        }
    }

    .image-preview {
        svg {
            width: 50px;
            height: 50px;
        }
    }
</style>
