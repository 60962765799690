<template>
    <base-modal @close="$emit('close')">
        <h1>{{ $t('delete_article_modal.delete_article') }}</h1>

        <p>{{ $t('delete_article_modal.warning') }}</p>

        <div class="btn-wrap btn-wrap--right">
            <a href="#" class="btn" @click.prevent="$emit('close')">{{ $t('delete_article_modal.cancel') }}</a>
            <a href="#" class="btn btn--red btn--shadow" @click.prevent="$emit('confirm')">{{ $t('delete_article_modal.delete') }}</a>
        </div>
    </base-modal>
</template>

<script>
    export default {
        name: 'delete-article-modal',
    };
</script>
