<template>
    <div class="component">
        <div class="component--header handle">
            <div class="title">
                {{ $t('componentTypes.' + componentType) }}
            </div>

            <div class="options-menu" v-if="!versionDisabled">
                <div class="btn-circle btn-circle--transparent">
                    <svg fill="none" height="4" viewBox="0 0 16 4" width="16" xmlns="http://www.w3.org/2000/svg">
                        <path clip-rule="evenodd"
                              d="M2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0ZM14 0C12.9 0 12 0.9 12 2C12 3.1 12.9 4 14 4C15.1 4 16 3.1 16 2C16 0.9 15.1 0 14 0ZM6 2C6 0.9 6.9 0 8 0C9.1 0 10 0.9 10 2C10 3.1 9.1 4 8 4C6.9 4 6 3.1 6 2Z"
                              fill="black"
                              fill-rule="evenodd" />
                    </svg>
                </div>

                <div class="options-menu-items">
                    <a @click.prevent="showDeleteComponentModal = true" href="#">{{ $t('ab_component.component_delete')}}</a>
                </div>
            </div>
        </div>

        <div class="component--content">
            <div v-if="componentType === 'title_lg'">
                <label>{{ $t('ab_component.title_big')}}</label>
                <input :disabled="versionDisabled" type="text" v-model="iContent" />
            </div>

            <div v-if="componentType === 'title_sm'">
                <label>{{ $t('ab_component.title_small')}}</label>
                <input :disabled="versionDisabled" type="text" v-model="iContent" />
            </div>

            <div v-if="componentType === 'textarea'">
                <label>{{ $t('ab_component.textbox')}}</label>
                <redactor-text-area :config="redactorConfig" :disabled="versionDisabled"
                                    :key="'redactor-for-' + id"
                                    v-model="iContent" />
            </div>

            <div v-if="componentType === 'image'">
                <div class="image-uploader" v-if="! iContent">
                    <input :disabled="versionDisabled" @change="previewImage" accept="image/*" ref="image"
                           style="display: none" type="file">
                    <p>{{ $t('upload_component.add_files')}}</p>
                    <span>{{ $t('upload_component.or')}}</span>
                    <a @click.prevent="$refs.image.click()" class="btn btn--blue" href="#" v-if="!versionDisabled">
                        {{ $t('upload_component.add_image')}}
                    </a>
                </div>

                <div class="image-preview" v-if="iContent">
                    <img :src="iContent" v-if="iContent.includes('base64')">
                    <img :src="`/files/translations/${iContent}?${randomImageVersion}`" v-else />
                    <a @click.prevent="removeImage" href="#" v-if="!versionDisabled">{{ $t('upload_component.delete_image')}}</a>
                </div>
            </div>
        </div>

        <portal to="modals">
            <delete-component-modal @close="showDeleteComponentModal = false" @confirm="deleteComponent"
                                    v-if="showDeleteComponentModal" />
        </portal>
    </div>
</template>

<script>
    import debounce from 'lodash.debounce';
    import '../lang/nl'

    export default {
        name: 'manual-component',

        props: {
            versionDisabled: {
                required: true
            },
            componentType: {
                type: String,
                required: true
            },
            translations: {
                required: true
            },
            id: {
                required: false,
            },
            chapterKey: {
                type: String,
                required: true,
            },
            language: {
                type: Object,
                required: true,
            }
        },

        data() {
            return {
                redactorConfig: {
                    lang: 'nl',
                    buttons: ['format', 'bold', 'italic', 'ul', 'ol', 'link'],
                    formatting: ['p'],
                    pasteImages: false,
                    plugins: ['table', 'linkreference'],
                    callbacks: {
                        linkreference: {
                            started: (arg) => {
                                this.$eventBus.$emit('referencedLinkClicked', arg);
                            }
                        }
                    },
                    customButtons:[],
                },
                iContent: null,
                iComponentType: this.componentType,
                showDeleteComponentModal: null,
                oldLanguage: {},
                randomImageVersion: Math.random().toString(36).substring(5),
            };
        },

        watch: {
            iContent(newVal, oldVal) {
                if (oldVal !== null) {
                    this.debouncedUpdateComponent()
                }
            },
            language() {
                if (this.oldLanguage.code !== this.language.code) {
                    this.setContent();
                }
            },
        },

        created() {
            this.setContent();
            this.debouncedUpdateComponent = debounce(this.updateComponent, 1200);
        },

        methods: {
            previewImage(event) {
                let input = event.target;

                if (input.files && input.files[0]) {
                    let reader = new FileReader();
                    reader.onload = (e) => {
                        if (e.total > 22000000) {
                            this.$flashMessage(this.$t('upload_component.image_to_large'), 5000);
                            return;
                        }
                        this.iContent = e.target.result;
                        this.updateComponent();
                    };

                    reader.readAsDataURL(input.files[0]);
                }
            },

            setContent() {
                const translation = this.translations.find(t => t.language === this.language.code);

                this.iContent = translation.content;

                this.oldLanguage = this.language
            },

            removeImage() {
                this.iContent = '';
                this.updateComponent();
            },

            updateComponent() {
                const translation = this.translations.find(t => t.language === this.language.code);
                const translationContent = translation.content || '';
                const iContent = this.iContent || '';

                if(iContent === translationContent) {
                    return;
                }

                let params = {
                    id: this.id,
                    content: this.iContent,
                    chapterKey: this.chapterKey,
                    language: this.oldLanguage,
                };
                this.$store.dispatch('chapters/update_component', params);

                if (this.oldLanguage.code !== this.language.code) {
                    this.oldLanguage = this.language;
                    this.setContent();
                    return;
                }
                this.$resetMessages();
                this.$flashMessage(this.$t('manual_component.saved_success'), 5000, 'succes')
            },

            deleteComponent() {
                this.showDeleteComponentModal = false;
                const params = {
                    chapterKey: this.chapterKey,
                    id: this.id,
                };

                this.$store.dispatch('chapters/delete_component', params);
            },
        },
    };
</script>
