<template>
    <div class="modal-container">
        <div class="modal-background"></div>

        <div class="modal-wrapper" @click="close">
            <div class="modal" @click.stop>
                <slot />
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'base-model',

        methods: {
            close() {
                this.$emit('close');
            }
        },
    }
</script>
