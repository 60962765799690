require('./bootstrap');
import '../lib/redactor/redactor.min';
import '../lib/redactor/_plugins/table/table.min'
import '../lib/redactor/_plugins/linkreference/linkreference';
import '../lib/redactor/_plugins/custombuttons/custombuttons';
import messages from './plugins/messages'
import router from './routes'
import Role from './modules/Role';

window.Vue = require('vue');
import store from './store';
import filters from './vue-filters';

// Global event bus
Vue.prototype.$eventBus = new Vue();

// Import VueJs portal
import PortalVue from 'portal-vue';
import Multiselect from 'vue-multiselect'
import VueCookies from 'vue-cookies';

Vue.use(PortalVue);
Vue.use(messages);
Vue.use(Role);

Vue.use(VueCookies);

Vue.component(Multiselect);

Vue.config.productionTip = false;

const files = require.context('./', true, /\.vue$/i);
files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));

import { i18n } from './lang';

let languageCookie = window.$cookies.get('application_language');
if (languageCookie !== null) {
    i18n.locale = languageCookie;
}

// Inject filters
for (let name in filters) {
    Vue.filter(name, filters[name]);
}

const app = new Vue({
    el: '#app',
    i18n,
    router,
    store
});
