import axios from 'axios';

const BaseService = {
    get(path, params, options) {
        return axios.get(`${path}`, { params, ...options });
    },

    post(path, params, headers) {
        headers = headers || {};

        return axios.post(`${path}`, params, { headers });
    },

    put(path, params) {
        return axios.put(`${path}`, params);
    },

    patch(path, params) {
        return axios.patch(`${path}`, params);
    },

    delete(resource) {
        return axios.delete(resource);
    }
};

export default BaseService;

export { default as BusinessUnitService } from './business-units';