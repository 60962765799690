<template>
    <div>
        <div class="chapter">
            <div class="chapter-loading" v-if="chapterData.loading"></div>
            <div :class="{collapsed: chapterData.collapsed}" class="chapter-header">
                <div class="title">
                    <h1>{{ chapterPosition }}.</h1>
                    <input :disabled="versionDisabled" type="text" v-model="chapterTitle">
                </div>

                <div @click="chapterData.collapsed = !chapterData.collapsed" class="btn-circle btn-circle--collapse">
                    <img alt="collapse arrow" src="/svg/collapse-arrow.svg">
                </div>
                <div class="options-menu">
                    <div class="btn-circle">
                        <svg fill="none" height="4" viewBox="0 0 16 4" width="16"
                             xmlns="http://www.w3.org/2000/svg">
                            <path clip-rule="evenodd"
                                  d="M2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0ZM14 0C12.9 0 12 0.9 12 2C12 3.1 12.9 4 14 4C15.1 4 16 3.1 16 2C16 0.9 15.1 0 14 0ZM6 2C6 0.9 6.9 0 8 0C9.1 0 10 0.9 10 2C10 3.1 9.1 4 8 4C6.9 4 6 3.1 6 2Z"
                                  fill="black"
                                  fill-rule="evenodd" />
                        </svg>
                    </div>

                    <div class="options-menu-items">
                        <a @click.prevent="deleteChapter = chapter" href="#" v-if="!versionDisabled">{{ $t('manual_chapter.delete_chapter') }}</a>
                        <a :href="'/manuals/'+chapter.manual_version_id+'/preview/'+ language.code +'#'+chapterPosition" target="_blank">{{ $t('manual_chapter.preview') }}</a>
                    </div>
                </div>
            </div>

            <transition name="collapse">
                <div class="chapter-content" v-if="!chapterData.collapsed">
                    <manual-component-list :chapter="chapter"
                                    :chapterKey="chapterKey"
                                    :versionDisabled="versionDisabled"
                                    :components="chapterData.components"
                                    :language="language" />

                    <draggable :disabled="versionDisabled" @end="onEndDrag" handle=".paragraph-header" v-model="paragraphs">
                        <manual-paragraph
                            :chapter="chapter"
                            :chapter-position="chapterPosition + '.' + (index + 1)"
                            :class="'js-ref-target-' + paragraph.id"
                            :chapterKey="`${chapterKey}.${paragraph.id}`"
                            :key="'manual-paragraph-' + paragraph.id"
                            :language="language"
                            :manualId="manualId"
                            :paragraph="paragraph"
                            :versionDisabled="versionDisabled"
                            v-for="(paragraph, index) of paragraphs" />
                    </draggable>
                </div>
            </transition>

            <transition name="collapse">
                <div @click="showNewParagraphModal = true"
                     class="paragraph-footer"
                     v-if="!chapterData.collapsed && !versionDisabled">
                    <div class="img-container">
                        <img src="/svg/plus.svg">
                    </div>
                    <span>{{ $t('manual_chapter.add_paragraph') }}</span>
                </div>
            </transition>
        </div>

        <portal to="modals">
            <new-paragraph-modal :chapter="chapter"
                                 :language="language"
                                 @add-paragraph="addParagraph"
                                 @close="showNewParagraphModal = false"
                                 v-if="showNewParagraphModal" />

            <delete-chapter-modal :language="language"
                                  @close="deleteChapter = null"
                                  @confirm="confirmDeleteChapter"
                                  v-if="deleteChapter" />
        </portal>
    </div>
</template>

<script>
    import draggable from 'vuedraggable';
    import debounce from 'lodash.debounce';

    export default {
        name: 'manual-chapter',

        components: {
            draggable,
        },

        props: {
            versionDisabled: {
                required: true
            },
            chapter: {
                type: Object,
                required: true,
            },

            chapterKey: {
                type: String,
                required: true,
            },

            chapterPosition: {
                type: String,
                required: true
            },

            manualId: {
                type: Number,
                required: true
            },

            language: {
                type: Object,
                required: true,
            }
        },

        data() {
            return {
                showNewParagraphModal: false,
                chapterData: this.chapter,
                deleteChapter: null,
                chapterTitle: '',
                oldLanguage: null,
                start: true,
            }
        },

        computed: {
            paragraphs: {
                set(paragraphs) {
                    this.$store.commit('chapters/update_chapter_paragraphs', {
                        chapterId: this.chapter.id,
                        paragraphs: paragraphs,
                    });
                },
                get() {
                    return this.$store.getters['chapters/paragraphs_by_id'](this.chapter.id);
                },
            },
        },

        watch: {
            'chapterData.collapsed': function (collapsed) {
                if (!!this.chapterData.chaptersLoaded) {
                    return;
                }

                if (!collapsed) {
                    this.chapterData.loading = true;
                    this.$store.dispatch('chapters/load_sub_chapters', this.chapterData.id).then(() => this.chapterData.loading = false);
                }
            },

            chapter: function (chapter) {
                this.chapterData = chapter;
            },

            chapterTitle: debounce(function () {
                if (this.start) {
                    this.start = false;
                    return;
                }
                this.updateChapterTitle();
            }, 500),

            language() {
                this.setTitle();
            }
        },

        created() {
            this.setTitle();
        },

        methods: {
            setTitle() {
                const translation = this.chapter.translations.find(t => t.language === this.language.code);

                this.chapterTitle = translation.content;

                this.oldLanguage = this.language
            },
            addParagraph(paragraph) {
                this.$store.dispatch('chapters/store_paragraph', {
                    manual_id: this.manualId,
                    chapter_id: this.chapter.id,
                    content: paragraph.translations[0].content,
                    language: this.language,
                });
            },

            onEndDrag(a) {
                if (a.newIndex === a.oldIndex) {
                    return;
                }

                this.$store.dispatch('chapters/swap_chapter_positions', {
                    chapterId: this.paragraphs[a.oldIndex].id.toString(),
                    position: a.newIndex + 1,
                    parentChapterKey: this.chapterKey,
                });
            },

            confirmDeleteChapter() {
                const chapter = this.deleteChapter;
                this.deleteChapter = null;

                this.$store.dispatch('chapters/delete_chapter', {
                    chapterKey: this.chapterKey,
                    chapter: this.chapter,
                });
            },

            updateChapterTitle() {
                const translation = this.chapter.translations.find(t => t.language === this.language.code);

                if(this.chapterTitle === translation.content) {
                    return;
                }

                let params = {
                    chapterKey: this.chapterKey,
                    id: this.chapter.id,
                    content: this.chapterTitle,
                    language: this.oldLanguage,
                };
                this.$store.dispatch('chapters/update_chapter', params);
                if (this.oldLanguage.code !== this.language.code) {
                    this.oldLanguage = this.language;
                    this.setTitle()
                }
            },
        },
    };
</script>
