<template>
    <div>
        <portal-target name="modals">
            <!--Teleport modals here-->
        </portal-target>
        <portal to="modals">
            <delete-map-modal v-if="showDeleteMapModal"
                              @close="showDeleteMapModal = false" @confirm="deleteMap" />
            <document-map-no-language-modal v-if="showNoLanguageModal"
                                            @close="showNoLanguageModal = false" />
        </portal>
        <div class="content">
            <div id="n-header" class="header">
                <ul class="breadcrumbs">
                    <div v-for="breadcrumb in computedBreadcrumbs">
                        <a v-if="! Array.isArray(breadcrumb)" @click.prevent="gotoDirectory(breadcrumb.id)">
                            {{ breadcrumb.name }}</a>
                        <a v-else @click="togglePopover">
                            ...
                            <div v-click-outside="closePopOver" :class="{ show : showBreadcrumbs }"
                                 class="popover-breads">
                                <li v-for="subBreadcrumb in breadcrumb">
                                    <a @click.prevent="gotoDirectory(subBreadcrumb.id)">{{ subBreadcrumb.name }}</a>
                                </li>
                            </div>
                        </a>
                    </div>
                </ul>
                <div id="n-header-buttons" class="header-buttons">
                    <div>
                        <div class="button light-blue" @click="showDeleteMapModal = true">
                            <span>{{ $t('documents_edit_map.delete_folder') }}</span>
                        </div>
                    </div>
                    <div>
                        <div class="button green">
                            <span @click="updateMap">{{ $t('documents_edit_map.save_changes') }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-wrap">
                <div :class="{'content-wrap__content--push-right': !! $route.params.id}" class="content-wrap__content">
                    <div class="panel panel--push-top">
                        <h2 class="title title--push-bottom">{{ $t('documents_edit_map.data') }}</h2>

                        <div class="field">
                            <label class="label" for="title">{{ $t('documents_edit_map.title') }}</label>
                            <input id="title" v-model="title" type="text">
                        </div>

                        <div class="field">
                            <label class="label" for="divisions">{{ $t('documents_edit_map.divisions') }}</label>
                            <vue-multiselect
                                id="divisions"
                                v-model="selectedBusinessUnits"
                                v-bind="employeeDivisionsConfig"
                                :close-on-select="false"
                                :multiple="true"
                                :options="businessUnits"
                                :searchable="false">
                                <template slot="tag" slot-scope="props">
                                    <span v-text="props.option.name + ', '"></span>
                                </template>
                                <template slot="option" slot-scope="props">
                                    <div class="option__desc">
                                        <span class="option__title">{{ props.option.name }}</span>
                                        <img alt="check" class="selected" src="/svg/check.svg">
                                    </div>
                                </template>
                                <span slot="noResult">{{ $t('documents_edit_map.no_results') }}</span>
                            </vue-multiselect>
                        </div>
                        <div class="field">
                            <label class="label" for="function">{{ $t('documents_edit_map.function') }}</label>
                            <vue-multiselect
                                id="functions"
                                v-model="selectedEmployeeFunctions"
                                v-bind="employeeFunctionsConfig"
                                :close-on-select="false"
                                :multiple="true"
                                :options="employeeFunctions"
                                :searchable="false">
                                <template slot="tag" slot-scope="props">
                                    <span v-text="props.option.name + ', '"></span>
                                </template>
                                <template slot="option" slot-scope="props">
                                    <div class="option__desc">
                                        <span class="option__title">{{ props.option.name }}</span>
                                        <img alt="check" class="selected" src="/svg/check.svg">
                                    </div>
                                </template>
                                <span slot="noResult">{{ $t('documents_edit_map.no_results') }}</span>
                            </vue-multiselect>
                        </div>
                        <div v-if="parentId === null" class="field language-select">
                            <label class="label" for="country">{{ $t('documents_edit_map.language') }}</label>
                            <vue-multiselect
                                id="country"
                                v-model="selectedLanguage"
                                :multiple="false"
                                :options="languages"
                                :placeholder="$t('documents_edit_map.select_language')"
                                :searchable="false"
                                class="multiselect--push-bottom"
                                deselectLabel=""
                                label="name"
                                selectLabel=""
                                selectedLabel=""
                                track-by="id">
                                <template slot="singleLabel" slot-scope="props">
                                    <img :src="'/svg/flags/' + props.option.code + '.svg'" alt="country flag">
                                    <span v-text="props.option.name"></span>
                                </template>
                                <template slot="option" slot-scope="props">
                                    <div class="option__desc">
                                        <img :src="'/svg/flags/' + props.option.code + '.svg'" alt="country flag">
                                        <span class="option__title">{{ props.option.name }}</span>
                                    </div>
                                </template>
                                <span slot="noResult">{{ $t('documents_edit_map.no_results') }}</span>
                            </vue-multiselect>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueMultiselect from "vue-multiselect/src/Multiselect";
import { BusinessUnitService } from "../../services";

export default {

    name: 'documents-edit-map-app',

    beforeRouteEnter(to, from, next) {
        window.axios.get('/languages').then(response => {
            let languages = response.data
            let language = window.authUser.language_relation ||
                this.languages.find(language => {
                    return language.default === 1;
                });

            next((vm) => {
                vm.languages = languages
                vm.language = language
            })
        }).catch(() => {
            next();
        });
    },

    data() {
        return {
            loaded: false,
            parentId: null,
            showDeleteMapModal: false,
            showNoLanguageModal: false,
            title: null,
            employeeFunctionsConfig: {
                placeholder: this.$t('documents_edit_map.all_functions'),
                label: "name",
                'track-by': "id",
                selectLabel: "",
                selectedLabel: "",
                deselectLabel: "",
            },
            employeeDivisionsConfig: {
                placeholder: this.$t('documents_edit_map.all_divisions'),
                label: "name",
                'track-by': "id",
                selectLabel: "",
                selectedLabel: "",
                deselectLabel: "",
            },
            searchQuery: null,
            selectedBusinessUnits: [],
            businessUnits: [],
            employeeFunctions: [],
            selectedEmployeeFunctions: [],
            languages: [],
            selectedLanguage: null,
            breadcrumbs: [],
            showBreadcrumbs: false,
        };
    },
    components: {
        VueMultiselect
    },

    beforeMount() {
        this.getData();
        this.getFolderStructure();
    },

    methods: {
        getData() {
            window.axios.get('/documents/path/show/' + this.$route.params.id).then((response) => {
                this.parentId = response.data.parent_id;
                this.title = response.data.name;
                this.selectedLanguage = this.languages.find(language => response.data.language_id === language.id);

                let selectedEmployeeFunctions = response.data.employee_functions;
                let selectedBusinessUnits = response.data.business_units;

                let employeeFunctionsUrl = this.parentId === null ? '/employee-functions' : '/documents/path/employee-functions/' + this.parentId;

                let businessUnitPromise;
                if (this.parentId === null) {
                    businessUnitPromise = BusinessUnitService.fetchByPermission(this.$permissions.ADD_AND_EDIT_DOCUMENTS);
                } else {
                    businessUnitPromise = window.axios.get(`/documents/path/business-units/${this.parentId}`);
                }

                businessUnitPromise.then((response) => {
                    this.businessUnits = response.data;

                    selectedBusinessUnits = selectedBusinessUnits.filter(d => {
                        return !!this.businessUnits.find(b => b.id === d);
                    });

                    this.selectedBusinessUnits = selectedBusinessUnits.map(value => response.data.find(option => option.id === value));
                }).then(() => {
                    window.axios.get(employeeFunctionsUrl).then((response) => {
                        this.employeeFunctions = response.data;
                        this.selectedEmployeeFunctions = selectedEmployeeFunctions.map(value => response.data.find(option => option.id === value));
                        this.loaded = true;
                    });
                });
            });
        },

        updateMap() {
            if (this.parentId === null && this.selectedLanguage === null) {
                this.showNoLanguageModal = true;
                return;
            }

            window.axios.put('/documents/path/update/' + this.$route.params.id, {
                name: this.title,
                parent_id: this.parentId,
                language_id: this.parentId === null ? this.selectedLanguage.id : null,
                employee_functions: this.selectedEmployeeFunctions.map(employeeFunction => {
                    return employeeFunction.id;
                }),
                business_units: this.selectedBusinessUnits.map(businessUnit => {
                    return businessUnit.id;
                }),
            }).then(response => {
                if (response.data.parent_id !== null) {
                    this.$router.push('/documenten/' + response.data.parent_id);
                } else {
                    this.$router.push('/documenten/');
                }
            }).catch((err) => {
                const errors = Object.entries(err.response.data.errors).map((err) => err[1]).flat().join('<br>');
                this.$flashMessage(errors, 5000, 'error')
            });
        },

        deleteMap() {
            window.axios.delete('/documents/path/delete/' + this.$route.params.id).then(response => {
                if (response.data.parent_id !== null) {
                    this.$router.push('/documenten/' + response.data.parent_id);
                } else {
                    this.$router.push('/documenten/');
                }
            });
        },

        getFolderStructure() {
            window.axios.get('/documents/path/' + this.$route.params.id).then(response => {
                this.breadcrumbs = response.data.breadcrumbs;
            });
        },
        togglePopover: function () {
            this.showBreadcrumbs = !this.showBreadcrumbs;
            // some code to filter users
        },
        closePopOver() {
            this.showBreadcrumbs = false;
        },

        gotoDirectory(id) {
            if (id === null) {
                return;
            }

            this.$router.push(`/documenten/${id}`);
        },

    },

    computed: {
        computedBreadcrumbs() {
            const breadcrumbs = this.breadcrumbs.slice().reverse();
            breadcrumbs.push({
                id: null,
                name: this.$t('documents_edit_map.edit_folder')
            });

            if (breadcrumbs.length <= 3) {
                return breadcrumbs;
            }

            return [
                ...breadcrumbs.slice(0, 2),
                ...[breadcrumbs.slice(2, breadcrumbs.length - 1)],
                ...breadcrumbs.slice(-1),
            ];
        }
    }

};
</script>
