<template>
    <div class="tabs">
        <router-link tag="div" to="content">
            {{ $t('documents_tabs.content') }}
        </router-link>
        <router-link tag="div" to="akkoord-geven" v-show="(document || {}).has_to_accept">
            {{ $t('documents_tabs.agree') }}
        </router-link>
    </div>
</template>

<script>
    export default {
        name: 'documents-tabs',
        props: {
            document: {
                required: true,
            },
        },
    };
</script>
