<template>
    <div>
        <div class="panel panel--push-top">
            <h2 class="title title--push-bottom">{{ $t('manual_tab_divisions.divisions') }}</h2>

            <label for="divisions">{{ $t('manual_tab_divisions.divisions') }}</label>
            <vue-multiselect
                id="divisions"
                v-model="selectedBusinessUnits"
                v-bind="config"
                :placeholder="$t('manual_tab_divisions.placeholder')"
                :close-on-select="false"
                :multiple="true"
                :options="businessUnits"
                :searchable="false"
                @close="saveBusinessUnits">
                <template slot="tag" slot-scope="props">
                    <span v-text="props.option.name + ', '"></span>
                </template>
                <template slot="option" slot-scope="props">
                    <div class="option__desc">
                        <span class="option__title">{{ props.option.name }}</span>
                        <img alt="check" class="selected" src="/svg/check.svg">
                    </div>
                </template>
                <span slot="noResult">{{ $t('manual_tab_divisions.no_results') }}</span>
            </vue-multiselect>
        </div>

        <div class="panel panel--push-top">
            <h2 class="title title--push-bottom">{{ $t('manual_tab_divisions.function') }}</h2>

            <label for="functions">{{ $t('manual_tab_divisions.function') }}</label>
            <vue-multiselect
                id="functions"
                v-model="selectedEmployeeFunctions"
                v-bind="config"
                :placeholder="$t('manual_tab_divisions.all_functions')"
                :close-on-select="false"
                :multiple="true"
                :options="employeeFunctions"
                :searchable="false"
                @close="saveEmployeeFunctions">
                <template slot="tag" slot-scope="props">
                    <span v-text="props.option.name + ', '"></span>
                </template>
                <template slot="option" slot-scope="props">
                    <div class="option__desc">
                        <span class="option__title">{{ props.option.name }}</span>
                        <img alt="check" class="selected" src="/svg/check.svg">
                    </div>
                </template>
                <span slot="noResult">{{ $t('manual_tab_divisions.no_results') }}</span>
            </vue-multiselect>
        </div>
    </div>
</template>

<script>
import VueMultiselect from "vue-multiselect/src/Multiselect";
import { BusinessUnitService } from "../services";

export default {
    name: 'ManualTabDivisions',

    components: {
        VueMultiselect
    },

    props: {
        manualVersion: {
            required: true,
        },
        activeManual: {
            required: true,
        },
    },

    data() {
        return {
            config: {
                label: "name",
                'track-by': "id",
                selectLabel: "",
                selectedLabel: "",
                deselectLabel: "",
            },
            businessUnits: [],
            employeeFunctions: [],
            selectedBusinessUnits: [],
            selectedEmployeeFunctions: [],
        }
    },

    watch: {
        activeManual: function () {
            this.selectedBusinessUnits = this.activeManual.business_units;
            this.selectedBusinessUnits = this.selectedBusinessUnits.filter((d) => {
                return !!this.businessUnits.find(b => b.id === d.id);
            });
            this.selectedEmployeeFunctions = this.activeManual.employee_functions;
        },
    },

    beforeMount() {
        this.getBusinessUnits().then(response => {
            this.selectedBusinessUnits = this.selectedBusinessUnits.filter((d) => {
                return !!this.businessUnits.find(b => b.id === d.id);
            });
        });

        this.getEmployeeFunctions();
    },

    methods: {
        getBusinessUnits() {
            return BusinessUnitService.fetchByPermission(this.$permissions.ADD_AND_EDIT_INSTRUCTIONS).then((response) => {
                this.businessUnits = response.data;

                if (this.activeManual.business_units) {
                    this.selectedBusinessUnits = this.activeManual.business_units.filter((d) => {
                        return !!this.businessUnits.find(b => b.id === d.id);
                    });
                }

                return response;
            });
        },

        getEmployeeFunctions() {
            window.axios.get('/employee-functions').then((response) => {
                this.employeeFunctions = response.data
            });
        },

        saveBusinessUnits() {
            const params = {
                business_units: this.selectedBusinessUnits.map(b => b.id),
            };

            window.axios.put(`/manuals/${this.activeManual.id}/business-units`, params);
        },

        saveEmployeeFunctions() {
            const params = {
                employee_functions: this.selectedEmployeeFunctions.map(b => b.id),
            };

            window.axios.put(`/manuals/${this.activeManual.id}/employee-functions`, params);
        },
    }
}
</script>
