<template>
    <div class="chapters-content-wrap">
        <div class="chapters">
            <div @click="showNewChapterModal = -1" class="chapter-footer" v-if="! chapters.length && !versionDisabled">
                <div class="img-container">
                    <img src="/svg/plus.svg">
                </div>
                <span>{{ $t('manual_tab_content.add_chapter') }}</span>
            </div>

            <draggable :disabled="versionDisabled" @end="onEndDrag" handle=".chapter-header" v-model="chapters">
                <div v-for="(chapter, index) of chapters" id="chapters">
                    <manual-chapter :chapter="chapter"
                                    :chapterKey="chapter.id.toString()"
                                    :chapterPosition="(index + 1).toString()"
                                    :class="'js-ref-target-' + chapter.id"
                                    :key="'manual-chapter-' + chapter.id"
                                    :language="language"
                                    :manualId="manualId !== null ? manualId : 0"
                                    :versionDisabled="versionDisabled" />

                    <div @click="showNewChapterModal = chapter.id" class="chapter-footer" v-if="!versionDisabled">
                        <div class="img-container">
                            <img src="/svg/plus.svg">
                        </div>
                        <span>{{ $t('manual_tab_content.add_chapter') }}</span>
                    </div>
                </div>
            </draggable>
        </div>

        <div class="chapters-navigation">
            <div class="chapters-navigation-header">
                <h3>{{ $t('manual_tab_content.chapters') }}</h3>
                <div class="search">
                    <label for="search">
                        <img alt="search" src="/img/search-white.svg">
                    </label>

                    <input :placeholder="$t('manual_tab_content.search')" type="text" v-model="searchNavigationChapters" />
                </div>
            </div>

            <div class="chapters-navigation-items">
                <a @click.prevent="$eventBus.$emit('referencedLinkClicked', chapter.id)" href="#"
                   v-for="(chapter, index) in navigationChapterList"
                   v-if="chapter.title.toLowerCase().search(searchNavigationChapters.toLowerCase()) !== -1">
                    {{ index + 1 }}. {{ chapter.title }}
                </a>
            </div>
        </div>

        <portal to="modals">
            <new-chapter-modal :afterId="showNewChapterModal"
                               @add-chapter="addChapter"
                               @close="showNewChapterModal = 0"
                               v-if="showNewChapterModal !== 0" />
        </portal>
    </div>
</template>
<script>
    import draggable from 'vuedraggable';

    export default {
        name: 'ManualTabContent',
        components: {
            draggable,
        },
        props: {
            versionDisabled: {
                required: true,
            },
            language: {
                required: true,
            },
            chaptersProp: {
                required: true,
            },
            manualId: {
                required: true,
            },
            activeManual: {
                required: true,
            },
        },
        watch: {
            chaptersProp: {
                handler() {
                    this.chapters = this.chaptersProp
                }
            }
        },
        data() {
            return {
                chapters: this.chaptersProp,
                showNewChapterModal: 0,
                searchNavigationChapters: '',
            }
        },
        computed: {
            navigationChapterList() {
                const language = this.$store.getters['chapters/language'].code;

                const chapters = this.$store.getters['chapters/chapters'].map(c => {
                    let title = c.translations.find((t) => t.language === language).content;

                    return {
                        id: c.id,
                        title
                    };
                });

                return chapters;
            },
        },
        methods: {
            addChapter({ chapter, afterId }) {
                this.$store.dispatch('chapters/store_chapter', {
                    manual_id: this.activeManual.id,
                    chapter_id: null,
                    content: chapter.translations[0].content,
                    language: this.language,
                    afterId: afterId,
                });
            },

            onEndDrag(a) {
                if (a.newIndex === a.oldIndex) {
                    return;
                }

                this.$store.dispatch('chapters/swap_chapter_positions', {
                    chapterId: this.chapters[a.newIndex].id.toString(),
                    position: a.newIndex + 1,
                    parentChapterKey: null,
                });
            },
        }
    }
</script>
