<template>
    <div class="poll">
        <label>{{ $t('ab_component.poll_question') }}</label>
        <input v-model="question"
               :disabled="versionDisabled"
               class="input"
               type="text"
               @input="onChange">
        <div>
            <draggable :list="answers">
                <answer v-for="(answer, index) in answers"
                        :key="'answer'+answer.id"
                        v-model="answers[index]"
                        :answer-index="index + 1"
                        :on-delete="onDelete"
                        :version-disabled="versionDisabled"
                        :vote-count="voteCount(answer.id)"
                        @input="onChange" />
            </draggable>
            <a class="add-answer-button"
               href="#"
               @click.prevent="addAnswer">{{ $t('ab_component.poll_add_answer') }}</a>
        </div>
    </div>
</template>

<script>
import Draggable from 'vuedraggable';
import Answer from './Poll/Answer';

export default {
    name: 'PollComponent',
    components: { Answer, Draggable },
    props: {
        versionDisabled: {
            type: Boolean,
            default: false,
        },
        value: {
            type: String,
        },
    },
    data() {
        const temp = JSON.parse(this.value);

        return {
            question: temp.question,
            answers: temp.answers,
            id: temp.id,
            votes: [],
        };
    },
    beforeMount() {
        this.fetchVotes();
    },
    methods: {
        voteCount(id) {
            if (this.votes === undefined) {
                return 0
            }

            const vote = this.votes.find(function (vote) {
                return vote.id === id
            })
            return vote !== undefined ? vote.total : 0;
        },
        fetchVotes() {
            window.axios.get(`/poll/${this.id}/results`).then((response) => {
                this.votes = response.data.votes
            });
        },
        onChange() {
            // create json object with poll data and emit it
            this.$emit('input', JSON.stringify({
                id: this.id,
                question: this.question,
                answers: this.answers,
            }));
        },
        onDelete(index) {
            this.answers.splice(index - 1, 1);
        },
        addAnswer() {
            this.answers.push({
                id: null,
                text: '',
            });
        },
    },
    watch: {
        answers() {
            this.onChange();
        },

        value(val) {
            const temp = JSON.parse(val);

            this.question = temp.question;
            this.answers = temp.answers;
            this.id = temp.id;
        }
    },
};
</script>
