import DepartmentsIndex from '../components/Departments/departments.vue';
import DepartmentsForm from '../components/Departments/department-form.vue';

export default [
    {
        path: '/afdelingen',
        component: DepartmentsIndex,
        name: 'departments.index',
    },
    {
        path: '/afdelingen/nieuw',
        component: DepartmentsForm,
        name: 'departments.create',
    },
    {
        path: '/afdelingen/:id',
        component: DepartmentsForm,
        name: 'departments.edit',
    },
];
