import Vue from 'vue';
import VueI18n from 'vue-i18n';
import componentTypes from './component-types';
import en from './en';
import de from './de';
import nl from './nl';

Vue.use(VueI18n);

const translations = {
    nl: {
        ...componentTypes.nl,
        ...nl,
    },
    en: {
        ...componentTypes.en,
        ...en,
    },
    de: {
        ...componentTypes.de,
        ...de,
    }
};

export const i18n = new VueI18n({
    locale: navigator.language.split('-')[0],
    fallbackLocale: 'en',
    messages: translations,
});
