import BaseService from './index';

export default {
    fetch() {
        return BaseService.get('/business-units');
    },

    fetchByPermission(permission) {
        return BaseService.get(`/business-units?permission=${permission}`);
    },
}
