(function ($R) {
    $R.add('plugin', 'custombuttons', {
        init: function (app) {
            // define app
            this.app = app;

            // define services
            this.lang = app.lang;
            this.toolbar = app.toolbar;
        },

        start: function () {
            const buttons = JSON.parse(this.app.rootElement.dataset.customButtons);

            // Create custom buttons
            buttons.forEach((button) => {
                const btn = this.toolbar.addButton(button.id, {
                    title: button.label,
                });

                btn.on('click', (e) => {
                    this.app.broadcast('custombuttons.click', e.target.dataset.reName);
                });
            });
        },
    });
})(Redactor);
