import Vue from "vue";
import VueRouter from "vue-router";
import FurloughsIndex from "../components/Furloughs/index";
import FurloughsDetail from "../components/Furloughs/furloughs-detail";

Vue.use(VueRouter);

export default [
    {
        path: '/verlof',
        component: FurloughsIndex,
        name: 'furloughs.index',
        meta: {
            permissions: ['manage-furloughs']
        },
    },

    {
        path: '/verlof/:id',
        component: FurloughsDetail,
        name: 'furloughs.detail',
        meta: {
            permissions: ['manage-furloughs']
        },
    },
]