<template>
    <div class="content">
        <div class="header">
            <h1>{{ $t('departments.departments') }}</h1>
            <div class="header-buttons">
                <div @click="createDepartment">
                    <div class="button green">
                        <span>{{ $t('departments.add_department') }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="body">
            <table>
                <thead>
                    <tr>
                        <th>{{ $t('departments.title') }}</th>
                    </tr>
                </thead>

                <tbody>
                    <!-- Use template tags for conditional rendering -->
                    <template v-if="departments.length">
                        <tr v-for="department in departments" :key="department.id" @click="editDepartment(department)">
                            <td>{{ department.name }}</td>
                        </tr>
                    </template>
                    <tr v-else>
                        <td colspan="1">{{ $t('departments.none_found') }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'departments-index',

        data() {
            return {
                departments: [],
            };
        },

        beforeMount() {
            this.fetchDepartments();
        },

        methods: {
            fetchDepartments() {
                axios.get('/departments')
                     .then(response => {
                         this.departments = response.data.data;
                     })
                     .catch(error => {
                         const errors = Object.entries(error.response.data.errors).map((error) => error[1]).flat().join('<br>');
                         this.$flashMessage(errors, 5000, 'error');
                     });
            },
            createDepartment() {
                this.$router.push({ name: 'departments.create' });
            },
            editDepartment(department) {
                this.$router.push({ name: 'departments.edit', params: { id: department.id } });
            },
        },
    };
</script>
<style>
    .router-link, .router-link:hover {
        text-decoration : none;
        color           : #212529;
    }
</style>