<template>
    <div class="content-wrap">
        <div :class="{'content-wrap__content--push-right': !! $route.params.id}" class="content-wrap__content">
            <div class="panel panel--push-top">
                <h2 class="title title--push-bottom">{{ $t('documents_edit_doc.data') }}</h2>

                <div class="field">
                    <label class="label" for="title">{{ $t('documents_edit_doc.title') }}</label>
                    <input id="title" type="text" v-model="document.name" />
                </div>

                <div class="field">
                    <label class="label" for="doc">{{ $t('documents_edit_doc.file') }}</label>
                    <div class="image-uploader" v-if="!document.file_name">
                        <input @change="previewDoc" accept="application/pdf, image/*" id="doc" ref="doc" type="file">

                        <p>{{ $t('documents_edit_doc.drag_file') }}</p>
                        <span>{{ $t('documents_edit_doc.or') }}</span>
                        <a @click.prevent="$refs.doc.click()" class="btn btn--blue" href="#">
                            {{ $t('documents_edit_doc.add_file') }}
                        </a>
                    </div>


                    <div class="field field--checkbox">
                        <input id="isActive" type="checkbox" v-model="document.has_to_accept" disabled />
                        <label for="isActive"></label>
                        <label class="label" for="isActive">{{ $t('documents_add_doc.has_to_accept') }}</label>
                    </div>


                    <div class="image-preview" v-if="doc || document.file_name">
                        <img :src="doc" v-if="doc !== null && doc.includes('base64') && ! documentIsPdf">
                        <img :src="generatePreviewUrl()" v-else-if="! documentIsPdf" />
                        <a :href="generatePreviewUrl()" target="_blank" v-else-if="documentIsPdf && !isNewFile">
                            {{ $t('documents_edit_doc.preview_pdf') }} ({{ document.original_file_name }})
                        </a>
                        <a @click.prevent="removeDoc" href="#">{{ $t('documents_edit_doc.replace_file') }}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            value: {
                required: true,
            },
        },
        data() {
            return {
                document: {},
                documentIsPdf: false,
                isNewFile: false,
                doc: null,
            };
        },
        watch: {
            value() {
                this.document = this.value;
                this.documentIsPdf = (this.document.file_name || '').endsWith('.pdf');
            },
            document() {
                this.$emit('input', this.document);
            },
        },
        mounted() {
            this.document = (this.value || {});
        },
        methods: {
            previewDoc(event) {
                let input = event.target;

                if (input.files && input.files[0]) {
                    let reader = new FileReader();
                    reader.onload = (e) => {
                        if (e.total > 22000000) {
                            this.$flashMessage(this.$t('documents_edit_doc.image_to_large'), 5000);
                        }

                        this.doc = e.target.result;
                        this.document.file = input.files[0];
                        this.document.file_name = input.files[0].name;

                        this.documentIsPdf = input.files[0].type === 'application/pdf';
                        this.isNewFile = true;
                    };

                    reader.readAsDataURL(input.files[0]);
                }
            },
            removeDoc() {
                this.doc = null;
                this.document.file = null;
                this.document.file_name = null;
                this.isNewFile = false;
            },
            generatePreviewUrl() {
                return '/documents/files/download/' + this.document.id + '?' + (new Date()).getTime();
            },
        },
    }
</script>
