<template>
    <div class="content">
        <div>
            <div class="header not-sticky">
                <h1>{{ $route.params.id ? $t('user_docs.edit_user') : $t('user_docs.add_user') }}</h1>
            </div>

            <div class="body">
                <div class="tabs">
                    <router-link tag="div" to="algemeen">
                        {{ $t('user_docs.general_data') }}
                    </router-link>
                    <router-link tag="div" to="documenten">
                        {{ $t('user_docs.documents') }}
                    </router-link>
                </div>
            </div>
        </div>

        <div class="content-wrap">
            <div class="content-wrap__content content-wrap__content--push-right">
                <personal-document v-for="personalDocumentType in personalDocumentTypes"
                                   :id="`personal_doc_type_${personalDocumentType.id}`"
                                   :key="`personal_document_${personalDocumentType.id}`"
                                   :document-type="personalDocumentType"
                                   :documents="personalDocumentType.personal_documents"
                                   :certificates="personalDocumentType.personal_certificates"
                                   v-on:updatePersonalDocument="updatePersonalDocument"
                                   v-on:updateExpirationDate="updateExpirationDate"
                                   v-on:deletePersonalDocument="deletePersonalDocument"
                                   v-on:personalDocumentTypeDeleted="personalDocumentTypeDeleted" />

                <div class="add-type panel panel--push-top">
                    <h2>{{ $t('personal_document.add_type') }}</h2>

                    <div class="title-input">
                        <label for="name">{{ $t('article_tab_profile.title') }}</label>
                        <input id="name" type="text" v-model="forms.addType.name">
                    </div>

                    <div class="button-container">
                        <div class="button light-blue" @click="addType">
                            <span>{{ $t('personal_document.add_type') }}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="content-wrap__sidebar content-wrap__sidebar--sticky">
                <div class="content-wrap__sidebar__title">{{ $t('user_docs.document_types') }}
                </div>

                <ul>
                    <li v-for="personalDocumentType in personalDocumentTypes">
                        <a :href="`#personal_doc_type_${personalDocumentType.id}`">
                            {{ personalDocumentType.name }}
                        </a>
                    </li>
                </ul>
            </div>

            <portal-target name="modals">
                <!--Teleport modals here-->
            </portal-target>
        </div>
    </div>
</template>

<script>
    import PersonalDocument from "./components/personal-document";

    export default {
        name: 'UsersDocs',

        components: {PersonalDocument },

        data() {
            return {
                personalDocumentTypes: [],
                forms: {
                    addType: {
                        name: null
                    }
                }
            };
        },

        beforeMount() {
            this.getPersonalDocumentTypes();
        },

        methods: {
            addType() {
                const userId = this.$route.params.id;

                window.axios.post(`/personal-document-types/${userId}`, this.forms.addType).then(() => {
                    this.forms.addType.name = null;

                    this.getPersonalDocumentTypes();
                })
            },

            getPersonalDocumentTypes() {
                const userId = this.$route.params.id;

                window.axios.get(`/personal-documents/${userId}`).then((response) => {
                    this.personalDocumentTypes = response.data;
                });
            },

            updatePersonalDocument(data) {
                const userId = this.$route.params.id;

                const formData = new FormData()
                formData.append('documentType', data.documentType.id);
                formData.append('document', data.document);
                formData.append('isBackPage', data.isBackPage);
                if (data.expirationDate) {
                    formData.append('expirationDate', data.expirationDate);
                }

                const requestOptions = {
                    'Content-Type': 'multipart/form-data',
                };

                window.axios.post(`/personal-documents/${userId}`, formData, requestOptions).then(() => {
                    this.getPersonalDocumentTypes();
                });
            },

            updateExpirationDate({ documentType, expirationDate }) {
                const userId = this.$route.params.id;

                const params = {
                    documentType: documentType.id,
                    expirationDate: expirationDate,
                };

                window.axios.post(`/personal-documents/${userId}`, params).then(() => {
                    this.getPersonalDocumentTypes();
                });
            },

            // updateCertificate({ documentType, name, file }) {
            //     const userId = this.$route.params.id;
            //
            //     const formData = new FormData()
            //     formData.append('documentType', documentType.id);
            //     formData.append('document', file);
            //     formData.append('name', name);
            //
            //     const requestOptions = {
            //         'Content-Type': 'multipart/form-data',
            //     };
            //
            //     window.axios.post(`/personal-documents/${userId}/certificates`, formData, requestOptions).then(() => {
            //         this.getPersonalDocumentTypes();
            //     });
            // },
            //
            // deleteCertificate({ id }) {
            //     const userId = this.$route.params.id;
            //
            //     window.axios.delete(`/personal-documents/${userId}/certificates/${id}`).then(() => {
            //         this.getPersonalDocumentTypes();
            //     });
            // },

            deletePersonalDocument({ documentType, isBackPage }) {
                const userId = this.$route.params.id;

                const params = {
                    documentType: documentType.id,
                    isBackPage: isBackPage,
                };

                window.axios.post(`/personal-documents/${userId}/delete`, params);
            },

            personalDocumentTypeDeleted() {
                this.getPersonalDocumentTypes();
            },

            previewImage(event) {
                let input = event.target;

                if (input.files && input.files[0]) {
                    let reader = new FileReader();
                    reader.onload = (e) => {
                        if (e.total > 22000000) {
                            this.$flashMessage(this.$t('upload_component.image_to_large'), 5000);
                        }

                        this.idFront = e.target.result;
                        this.idBack = e.target.result;
                        this.driversLicenseFront = e.target.result;
                        this.driversLicenseBack = e.target.result;
                        this.driversCardFront = e.target.result;
                        this.driversCardBack = e.target.result;
                        this.ccv = e.target.result;
                        this.atTest = e.target.result;
                        this.kiwa = e.target.result;
                        this.potatoCard = e.target.result;
                        this.contract = e.target.result;
                        this.a1Document = e.target.result;

                    };

                    reader.readAsDataURL(input.files[0]);
                }
            },

            removeImage: function (document) {
                this[document] = null;
            },
        },
    };
</script>

<style scoped lang="scss">
.add-type {
    h2 {
        color       : #000000;
        font-size   : 24px;
        line-height : 40px;
        font-weight : bold;
        margin      : 0;
    }

    .title-input {
        display       : block;
        margin-bottom : 24px;

        label {
            margin      : 0;
            width       : 100%;
            font-size   : 12px;
            font-weight : 500;
            color       : grey;
        }

        input {
            border        : 1px solid rgba(0, 0, 0, 0.1);
            border-radius : 4px;
            width         : 100%;
            height        : 48px;
            padding-left  : 18px;
            font-size     : 14px;
            font-weight   : 500;
            color         : #1A1A1A;

            &:disabled {
                background : #e8e8e8;
                cursor     : not-allowed;
                color      : grey;
            }
        }
    }

    .button-container {
        display    : flex;
        margin-top : 16px;

        .button {
            display       : flex;
            border-radius : 6px;
            height        : 48px;
            font-size     : 16px;
            font-weight   : 600;

            span {
                border-radius : 6px;
                padding       : 12px 42px;
                transition    : all 0.2s ease;
            }

            .dropdown-button {
                border-left                : 1px lightgray solid;
                border-top-right-radius    : 6px;
                border-bottom-right-radius : 6px;
                transition                 : all 0.2s ease;

                img {
                    padding : 22px;
                }

                &:hover {
                    background : darken(#96B522, 5%);
                    cursor     : pointer;
                }
            }

            &.disabled {
                cursor : not-allowed;
            }

            &.light-blue {
                background : #d4e4f0;
                color      : black;
            }

            &:hover {
                cursor : pointer;

                &.disabled {
                    cursor : not-allowed;
                }

                &.light-blue {
                    background : darken(#d4e4f0, 5%);
                }
            }
        }
    }
}
</style>