<template>
    <div>
        <portal-target name="modals">
            <!--Teleport modals here-->
        </portal-target>
        <portal to="modals">
            <document-map-no-language-modal v-if="showNoLanguageModal" @close="showNoLanguageModal = false" />
        </portal>

        <div class="content">
            <div id="n-header" class="header">
                <ul class="breadcrumbs">
                    <div v-for="breadcrumb in computedBreadcrumbs">
                        <a v-if="! Array.isArray(breadcrumb)" @click.prevent="gotoDirectory(breadcrumb.id)">
                            {{ breadcrumb.name }}</a>
                        <a v-else @click="togglePopover">
                            ...
                            <div v-click-outside="closePopOver" :class="{ show : showBreadcrumbs }"
                                 class="popover-breads">
                                <li v-for="subBreadcrumb in breadcrumb">
                                    <a @click.prevent="gotoDirectory(subBreadcrumb.id)">{{ subBreadcrumb.name }}</a>
                                </li>
                            </div>
                        </a>
                    </div>
                </ul>
                <div id="n-header-buttons" class="header-buttons">
                    <div>
                        <div class="button green">
                            <span @click="createMap">{{ $t('documents_add_map.add_map') }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-wrap">
                <div :class="{'content-wrap__content--push-right': !! $route.params.id}" class="content-wrap__content">
                    <div class="panel panel--push-top">
                        <h2 class="title title--push-bottom">{{ $t('documents_add_map.data') }}</h2>

                        <div class="field">
                            <label class="label" for="title">{{ $t('documents_add_map.title') }}</label>
                            <input id="title" v-model="folderName" type="text" />
                        </div>

                        <div class="field">
                            <label class="label" for="divisions">{{ $t('documents_add_map.divisions') }}</label>
                            <vue-multiselect
                                id="divisions"
                                v-model="selectedBusinessUnits"
                                v-bind="config"
                                :close-on-select="false"
                                :multiple="true"
                                :options="businessUnits"
                                :searchable="false">
                                <template slot="tag" slot-scope="props">
                                    <span v-text="props.option.name + ', '"></span>
                                </template>
                                <template slot="option" slot-scope="props">
                                    <div class="option__desc">
                                        <span class="option__title">{{ props.option.name }}</span>
                                        <img alt="check" class="selected" src="/svg/check.svg">
                                    </div>
                                </template>
                                <span slot="noResult">{{ $t('documents_add_map.no_results') }}</span>
                            </vue-multiselect>
                        </div>
                        <div class="field">
                            <label class="label" for="function">{{ $t('documents_add_map.function') }}</label>
                            <vue-multiselect
                                id="functions"
                                v-model="selectedEmployeeFunctions"
                                :multiple="true"
                                :options="employeeFunctions"
                                :placeholder="$t('documents_add_map.select_function')"
                                :searchable="false"
                                class="multiselect--push-bottom"
                                deselectLabel=""
                                label="name"
                                selectLabel=""
                                selectedLabel=""
                                track-by="id">
                                <template slot="tag" slot-scope="props">
                                    <span v-text="props.option.name + ', '"></span>
                                </template>
                                <template slot="option" slot-scope="props">
                                    <div class="option__desc">
                                        <span class="option__title">{{ props.option.name }}</span>
                                        <img alt="check" class="selected" src="/svg/check.svg">
                                    </div>
                                </template>
                                <span slot="noResult">{{ $t('documents_add_map.no_results') }}</span>
                            </vue-multiselect>
                        </div>
                        <div v-if="this.parentId === null" class="field language-select">
                            <label class="label" for="country">{{ $t('documents_add_map.language') }}</label>
                            <vue-multiselect
                                id="country"
                                v-model="selectedLanguage"
                                :multiple="false"
                                :options="languages"
                                :placeholder="$t('documents_add_map.select_language')"
                                :searchable="false"
                                class="multiselect--push-bottom"
                                deselectLabel=""
                                label="name"
                                selectLabel=""
                                selectedLabel=""
                                track-by="id">
                                <template slot="singleLabel" slot-scope="props">
                                    <img :src="'/svg/flags/' + props.option.code + '.svg'" alt="country flag">
                                    <span v-text="props.option.name"></span>
                                </template>
                                <template slot="option" slot-scope="props">
                                    <div class="option__desc">
                                        <img :src="'/svg/flags/' + props.option.code + '.svg'" alt="country flag">
                                        <span class="option__title">{{ props.option.name }}</span>
                                    </div>
                                </template>
                                <span slot="noResult">{{ $t('documents_add_map.no_results') }}</span>
                            </vue-multiselect>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueMultiselect from "vue-multiselect/src/Multiselect";
import { BusinessUnitService } from "../../services";

export default {

    name: 'documents-add-map-app',

    beforeRouteEnter(to, from, next) {
        window.axios.get('/languages').then(response => {
            let languages = response.data
            let language = window.authUser.language_relation ||
                this.languages.find(language => {
                    return language.default === 1;
                });

            next((vm) => {
                vm.languages = languages
                vm.language = language
            })
        }).catch(() => {
            next();
        });
    },

    data() {
        return {
            showNoLanguageModal: false,
            parentId: null,
            loading: false,
            loaded: false,
            page: 0,
            config: {
                placeholder: this.$t('documents_add_map.all_divisions'),
                label: "name",
                'track-by': "id",
                selectLabel: "",
                selectedLabel: "",
                deselectLabel: "",
            },
            configMaps: {
                placeholder: this.$t('documents_add_map.select_map'),
                label: "name",
                'track-by': "id",
                selectLabel: "",
                selectedLabel: "",
                deselectLabel: "",
            },
            configLanguages: {
                placeholder: this.$t('documents_add_map.select_language'),
                label: 'name',
                'track-by': 'id',
                selectLabel: "",
                selectedLabel: "",
                deselectLabel: "",
            },
            folderName: null,
            employeeFunctions: [],
            selectedEmployeeFunctions: [],
            selectedBusinessUnits: [],
            businessUnits: [],
            languages: [],
            selectedLanguage: null,
            searchQuery: null,
            breadcrumbs: [],
            showBreadcrumbs: false,
        };
    },
    components: {
        VueMultiselect
    },

    beforeMount() {
        this.parentId = this.$route.params.parentId !== undefined ? this.$route.params.parentId : null;

        this.getLanguages();
        this.getBusinessUnits();
        this.getEmployeeFunctions();
        this.getFolderStructure();
    },

    methods: {
        getLanguages() {
        },
        getBusinessUnits() {
            if (this.parentId === null) {
                BusinessUnitService.fetchByPermission(this.$permissions.ADD_AND_EDIT_DOCUMENTS).then((response) => {
                    this.businessUnits = response.data
                });
            } else {
                window.axios.get('/documents/path/business-units/' + this.parentId).then((response) => {
                    this.businessUnits = response.data
                });
            }
        },
        getEmployeeFunctions() {
            if (this.parentId === null) {
                window.axios.get('/employee-functions').then((response) => {
                    this.employeeFunctions = response.data
                });
            } else {
                window.axios.get('/documents/path/employee-functions/' + this.parentId).then((response) => {
                    this.employeeFunctions = response.data
                });
            }
        },

        createMap() {
            if (this.parentId === null && this.selectedLanguage === null) {
                this.showNoLanguageModal = true;
                return;
            }

            window.axios.post('/documents/path/create', {
                name: this.folderName,
                parent_id: this.parentId,
                language_id: this.parentId === null ? this.selectedLanguage.id : null,
                employee_functions: this.selectedEmployeeFunctions.map(employeeFunction => {
                    return employeeFunction.id;
                }),
                business_units: this.selectedBusinessUnits.map(businessUnit => {
                    return businessUnit.id;
                }),
            }).then(response => {
                window.location = '/documenten/' + response.data.id;
            }).catch((err) => {
                const errors = Object.entries(err.response.data.errors).map((err) => err[1]).flat().join('<br>');
                this.$flashMessage(errors, 5000, 'error')
            });
        },

        getFolderStructure() {
            window.axios.get('/documents/path/' + this.parentId).then(response => {
                this.breadcrumbs = response.data.breadcrumbs;
            });
        },
        togglePopover: function () {
            this.showBreadcrumbs = !this.showBreadcrumbs;
            // some code to filter users
        },
        closePopOver() {
            this.showBreadcrumbs = false;
        },

        gotoDirectory(id) {
            if (id === null) {
                return;
            }

            this.$router.push(`/documenten/${id}`);
        },

    },

    computed: {
        computedBreadcrumbs() {
            const breadcrumbs = this.breadcrumbs.slice().reverse();
            breadcrumbs.push({
                id: null,
                name: this.$t('documents_add_map.add_new_map')
            });

            if (breadcrumbs.length <= 3) {
                return breadcrumbs;
            }

            return [
                ...breadcrumbs.slice(0, 2),
                ...[breadcrumbs.slice(2, breadcrumbs.length - 1)],
                ...breadcrumbs.slice(-1),
            ];
        }
    }

};
</script>
<style lang="scss" scoped>

</style>
