<template>
    <div class="content">
        <div class="header">
            <h1>{{ $t('navigation.furloughs') }}</h1>
        </div>

        <div class="body">
            <div class="search">
                <label for="search">
                    <img alt="search" src="img/search.svg">
                </label>

                <input id="search" :placeholder="$t('users.search')" type="text" v-model="search" @keyup="onSearch" />
            </div>

            <table ref="overviewTable">
                <thead>
                    <tr>
                        <th :class="{'sorted': sortByColumn === 'name'}" @click="sortBy('name')">
                            {{ $t('furloughs.applicant') }}
                        </th>
                        <th :class="{'sorted': sortByColumn === 'from'}" @click="sortBy('from')">
                            {{ $t('furloughs.from') }}
                        </th>
                        <th :class="{'sorted': sortByColumn === 'to'}" @click="sortBy('to')">
                            {{ $t('furloughs.to') }}
                        </th>
                        <th :class="{'sorted': sortByColumn === 'status'}" @click="sortBy('status')">
                            {{ $t('furloughs.status') }}
                        </th>
                        <th :class="{'sorted': sortByColumn === 'is_processed_fleethours'}"
                            @click="sortBy('is_processed_fleethours')">
                            {{ $t('furloughs.fleethours') }}
                        </th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-for="furlough in furloughs" :key="'furlough_' + furlough.id"
                        @click="furloughDetail(furlough)">
                        <td>{{ furlough.user.name }}</td>
                        <td>{{ formatDate(furlough.from) | upperCaseFirst }}</td>
                        <td>{{ formatDate(furlough.to) | upperCaseFirst }}</td>
                        <td>
                            <span :class="getClassName(furlough.status)"
                                  class="furlough-status">
                                {{ $t('furloughs.statuses.' + furlough.status).toUpperCase() }}
                            </span>
                        </td>
                        <td>{{ furlough.is_processed_fleethours ? $t('general.yes') : $t('general.no') | upperCaseFirst}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import * as moment from 'moment'
import debounce from "lodash.debounce";

export default {

    name: "furloughs-index",

    data() {
        return {
            furloughs: [],
            search: '',
            sortByColumn: null,
            sortDirection: 'asc',
        }
    },

    onSearch: debounce(function () {
        this.fetchUsers();
    }, 500),

    methods: {
        fetchFurloughs() {
            window.axios.get('furloughs', {
                params: {
                    ...(this.search) && {search: this.search},
                    ...(this.sortByColumn && {orderBy: this.sortByColumn}),
                    ...(this.sortByColumn && {orderByDirection: this.sortDirection}),
                }
            }).then(response => {
                this.furloughs = response.data;
            });
        },

        furloughDetail(furlough) {
            this.$router.push({name: 'furloughs.detail', params: {id: furlough.id}});
        },

        formatDate(date) {
            return moment(date).locale(window.authUser.language).format('dddd D MMMM Y')
        },

        onSearch: debounce(function () {
            this.fetchFurloughs();
        }, 500),

        getClassName(status) {
            switch (status) {
                case 1:
                    return 'pending'
                case 2:
                    return 'approved'
                case 3:
                    return 'denied'
            }
        },

        sortBy(s) {
            if (s === this.sortByColumn) {
                this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
            } else {
                this.sortDirection = 'asc';
            }

            this.sortByColumn = s;
            this.users = [];
            this.fetchFurloughs();
        },
    },

    beforeMount() {
        this.fetchFurloughs();
    }
}
</script>

<style scoped>

</style>
