<template>
    <div class="contact-person">
        <a href="#"
           class="contact-person-delete"
           @click.prevent="showDeleteContactPersonModal = true"
           v-if="uniqueIdentifier != null">
            <svg class="bi bi-trash"
                 width="16"
                 height="16"
                 viewBox="0 0 16 16"
                 fill="currentColor"
                 xmlns="http://www.w3.org/2000/svg">
                <path d="M5.5 5.5A.5.5 0 016 6v6a.5.5 0 01-1 0V6a.5.5 0 01.5-.5zm2.5 0a.5.5 0 01.5.5v6a.5.5 0 01-1 0V6a.5.5 0 01.5-.5zm3 .5a.5.5
                 0 00-1 0v6a.5.5 0 001 0V6z" />
                <path fill-rule="evenodd"
                      d="M14.5 3a1 1 0 01-1 1H13v9a2 2 0 01-2 2H5a2 2 0 01-2-2V4h-.5a1 1 0 01-1-1V2a1 1 0 011-1H6a1 1 0 011-1h2a1 1 0 011 1h3.5a1
                      1 0 011 1v1zM4.118 4L4 4.059V13a1 1 0 001 1h6a1 1 0 001-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                      clip-rule="evenodd" />
            </svg>
        </a>
        <div class="field">
            <label for="name"
                   class="label">{{ $t('contact_person.name') }}</label>
            <input type="text"
                   id="name"
                   v-model="name">
        </div>
        <div class="field">
            <label class="label">{{ $t('contact_person.picture') }}</label>
            <div class="image-uploader"
                 v-if="! photo">
                <input type="file"
                       accept="image/*"
                       ref="image"
                       @change="previewPhoto">

                <p>{{ $t('upload_component.add_files') }}</p>
                <span>{{ $t('upload_component.or') }}</span>
                <a href="#"
                   @click.prevent="$refs.image.click()"
                   class="btn btn--blue">
                    {{ $t('upload_component.add_image') }}
                </a>
            </div>

            <div class="image-preview"
                 v-if="photo">
                <img :src="photo" v-if="photo.includes('base64')">
                <img :src="`/files/contacts/${contactId}/persons/${photo}?v=${randomImageVersion}`" v-else>
                <a href="#"
                   @click.prevent="removePhoto">{{ $t('upload_component.delete_image') }}</a>
            </div>
        </div>

        <portal to="modals">
            <delete-contact-person-confirmation-modal v-if="showDeleteContactPersonModal"
                                                      @confirm="deleteContactPerson"
                                                      @close="showDeleteContactPersonModal = false" />
        </portal>
    </div>
</template>

<script>
    export default {
        name: 'ContactPerson',

        props: ['contactPerson', 'contactId'],

        data() {
            return {
                id: this.contactPerson.id,
                uniqueIdentifier: this.contactPerson.uniqueIdentifier,
                name: this.contactPerson.name,
                photo: this.contactPerson.photo,
                deleted: false,
                showDeleteContactPersonModal: false,
                randomImageVersion: Math.random().toString(36).substring(5),
            };
        },

        methods: {
            previewPhoto(event) {
                const input = event.target;

                if (input.files && input.files[0]) {
                    this.randomImageVersion = Math.random().toString(36).substring(5);
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        if (e.total > 22000000) {
                            this.$flashMessage(this.$t('upload_component.image_to_large'), 5000);
                        }

                        this.photo = e.target.result;
                    };

                    reader.readAsDataURL(input.files[0]);
                }
            },

            removePhoto() {
                this.photo = null;
            },

            deleteContactPerson() {
                const contactId = this.contactId;
                const id = this.contactPerson.id;
                this.$emit('onDeleted', this.contactPerson.uniqueIdentifier);

                // Isn't an entity in the database so just remove it visually
                if (!id)
                {
                    return;
                }

                /* exported response */
                window.axios.delete(`/contacts/${contactId}/${id}`).then(() => {
                    this.showDeleteContactPersonModal = false;
                    this.$flashMessage(this.$t('contact_person.contactperson_deleted'), 5000, 'succes');
                    this.$emit('onDeleted', id);
                }).catch((err) => {
                    const errors = Object.entries(err.response.data.errors).map(error => error[1]).flat().join('<br>');
                    this.$flashMessage(errors, 5000, 'error');
                });
            }
        },
    };
</script>

<style scoped>
</style>
