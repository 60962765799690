<template>
    <div>
        <div class="content">
            <div id="n-header" class="header">
                <h1 id="n-h1">{{ $t('ideabox.header') }}</h1>
            </div>

            <div class="body">
                <div class="search">
                    <label for="search">
                        <img alt="search" src="img/search.svg">
                    </label>

                    <input id="search" v-model="searchQuery" :placeholder="$t('ideabox.search')" type="text"
                           @keyup="onSearch" />
                </div>

                <idea-component v-for="(idea, index) in ideas"
                                :id="idea.id"
                                :key="index"
                                :date="idea.created_at"
                                :status="idea.status"
                                :subject="idea.subject"
                                :user="idea.user"
                                @updateIdea="updateIdea($event,index)">
                    {{ idea.idea }}
                </idea-component>
            </div>
        </div>
    </div>
</template>

<script>
import IdeaComponent from "./IdeaComponent";
import { debounce } from 'lodash';

export default {
    name: "IdeaBoxApp",
    components: { IdeaComponent },

    data() {
        return {
            ideas: [],
            searchQuery: ''
        }
    },

    mounted() {
        this.fetch();
    },

    methods: {
        updateIdea(status, index) {
            this.ideas[index].status = status;
        },
        fetch() {
            const params = {
                ...(this.searchQuery.length) && { query: this.searchQuery },
            };
            window.axios.get('/ideabox', { params }).then(result => {
                this.ideas = result.data;
            }).catch(err => {
                const errors = Object.entries(err.response.data.errors).map(error => error[1]).flat().join('<br>');
                this.$flashMessage(errors, 5000, 'error');
            });
        },

        onSearch: debounce(function () {
            this.fetch();
        }, 500)
    }
}
</script>
