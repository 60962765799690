<template>
    <div class="paragraph">
        <div class="paragraph-header sub-paragraph-header">
            <div class="title">
                <h1>{{ paragraphPosition }}.</h1>
                <input :disabled="versionDisabled" type="text" v-model="subParagraphTitle">
            </div>

            <div v-if="!versionDisabled" class="options-menu">
                <div class="btn-circle" href="#">
                    <svg fill="none" height="4" viewBox="0 0 16 4" width="16" xmlns="http://www.w3.org/2000/svg">
                        <path clip-rule="evenodd"
                              d="M2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0ZM14 0C12.9 0 12 0.9 12 2C12 3.1 12.9 4 14 4C15.1 4 16 3.1 16 2C16 0.9 15.1 0 14 0ZM6 2C6 0.9 6.9 0 8 0C9.1 0 10 0.9 10 2C10 3.1 9.1 4 8 4C6.9 4 6 3.1 6 2Z"
                              fill="black"
                              fill-rule="evenodd" />
                    </svg>
                </div>

                <div class="options-menu-items">
                    <a @click.prevent="deleteSubParagraph = subParagraph" href="#">{{ $t('manual_subsection.delete_subsection') }}</a>
                </div>
            </div>
        </div>

        <div class="paragraph-content">
            <manual-component-list
                :chapter="chapter" :chapterKey="chapterKey"
                :components="subParagraph.components"
                :language="language"
                :paragraph="paragraph"
                :subParagraph="subParagraph" :versionDisabled="versionDisabled" />
        </div>

        <portal to="modals">
            <delete-sub-paragraph-modal @close="deleteSubParagraph = null" @confirm="confirmDeleteSubParagraph"
                                        v-if="deleteSubParagraph" />
        </portal>
    </div>
</template>

<script>
    import draggable from 'vuedraggable';

    export default {
        name: 'manual-sub-paragraph',

        components: {
            draggable,
        },

        props: {
            versionDisabled: {
                required: true,
            },
            chapterKey: {
                type: String,
                required: true,
            },
            paragraphPosition: {
                type: String,
                required: true
            },
            subParagraph: {
                type: Object,
                required: true
            },
            paragraph: {
                type: Object,
                required: true
            },
            chapter: {
                type: Object,
                required: true
            },
            language: {
                type: Object,
                required: true,
            }
        },

        data() {
            return {
                deleteSubParagraph: null,
                subParagraphTitle: '',
                oldLanguage: null,
                start: true,
            };
        },

        watch: {
            subParagraphTitle: function () {
                if (this.start) {
                    this.start = false;
                    return;
                }
                this.debouncedUpdateSubParagraphTitle();
            },
            language: function () {
                this.setTitle();
            }
        },

        methods: {
            setTitle() {
                const translation = this.subParagraph.translations.find(t => t.language === this.language.code);

                this.subParagraphTitle = translation.content;

                this.oldLanguage = this.language
            },

            confirmDeleteSubParagraph() {
                const subParagraph = this.deleteSubParagraph;
                this.deleteSubParagraph = null;

                this.$store.dispatch('chapters/delete_chapter', {
                    chapterKey: this.chapterKey,
                    chapter: subParagraph,
                });
            },

            updateSubParagraphTitle() {
                const translation = this.subParagraph.translations.find(t => t.language === this.language.code);

                if(this.subParagraphTitle === translation.content) {
                    return;
                }

                let params = {
                    chapterKey: this.chapterKey,
                    id: this.subParagraph.id,
                    content: this.subParagraphTitle,
                    language: this.oldLanguage,
                };
                this.$store.dispatch('chapters/update_chapter', params);
                if (this.oldLanguage.code !== this.language.code) {
                    this.oldLanguage = this.language;
                    this.setTitle()
                }
            },
        },

        created() {
            this.debouncedUpdateSubParagraphTitle = _.debounce(this.updateSubParagraphTitle, 500);
            this.setTitle();
        }
    };
</script>
