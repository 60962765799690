<template>
    <div>
        <div class="content">
            <div class="header" id="n-header">
                <ul class="breadcrumbs">
                    <div v-for="breadcrumb in computedBreadcrumbs">
                        <a v-if="! Array.isArray(breadcrumb)" @click.prevent="gotoDirectory(breadcrumb.id)">{{ breadcrumb.name }}</a>
                        <a @click="togglePopover" v-else>
                            ...
                            <div class="popover-breads" :class="{ show : showBreadcrumbs }" v-click-outside="closePopOver">
                                <li v-for="subBreadcrumb in breadcrumb">
                                    <a @click.prevent="gotoDirectory(subBreadcrumb.id)">{{ subBreadcrumb.name }}</a>
                                </li>
                            </div>
                        </a>
                    </div>
                </ul>
                <div class="header-buttons" id="n-header-buttons">
                    <div>
                        <div class="button green">
                            <span @click="storeDocument">{{ $t('documents_add_doc.add_document') }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-wrap">
                <div class="content-wrap__content" :class="{'content-wrap__content--push-right': !! $route.params.id}">
                    <div class="panel panel--push-top">
                        <h2 class="title title--push-bottom">{{ $t('documents_add_doc.data') }}</h2>

                        <div class="field">
                            <label for="title" class="label">{{ $t('documents_add_doc.title') }}</label>
                            <input type="text" id="title" v-model="name" />
                        </div>

                        <div class="field field--checkbox">
                            <input id="isActive" type="checkbox" v-model="hasToAccept" />
                            <label for="isActive"></label>
                            <label class="label" for="isActive">{{ $t('documents_add_doc.has_to_accept') }}</label>
                        </div>

                        <div class="field">
                            <label for="doc" class="label">{{ $t('documents_add_doc.file') }}</label>
                            <div class="image-uploader" v-if="! doc">
                                <input type="file" id="doc" accept="application/pdf, image/*"  ref="doc" @change="previewDoc">

                                <p>{{ $t('documents_add_doc.drag_file') }}</p>
                                <span>{{ $t('documents_add_doc.or') }}</span>
                                <a href="#" @click.prevent="$refs.doc.click()" class="btn btn--blue">
                                    {{ $t('documents_add_doc.add_file') }}
                                </a>
                            </div>

                            <div class="image-preview" v-if="doc">
                                <img :src="doc" v-if="doc.includes('base64') && ! documentIsPdf">
                                <div class="text-center" v-else-if="documentIsPdf">
                                    {{ filename }}
                                </div>
                                <a href="#" @click.prevent="removeDoc">{{ $t('documents_add_doc.delete_file') }}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'documents-app',

        data() {
            return {
                parentId: null,
                doc: null,
                filename: null,
                name: null,
                documentIsPdf: false,
                file: null,
                breadcrumbs: [],
                showBreadcrumbs: false,
                hasToAccept: false,
            };
        },

        beforeMount() {
            this.parentId = this.$route.params.parentId !== undefined ? this.$route.params.parentId : null;
            this.getFolderStructure();
        },

        methods: {
            storeDocument() {
                const formData = new FormData()
                formData.append('data', this.file);
                formData.append('filename', this.filename);
                formData.append('name', this.name);
                formData.append('document_path_id', this.parentId);
                formData.append('has_to_accept', this.hasToAccept ? '1' : '0');

                const requestOptions = {
                    'Content-Type': 'multipart/form-data',
                };

                window.axios.post('/documents/files/create', formData, requestOptions)
                    .then((response) => {
                        if (response.data.document_path_id !== null)
                        {
                            this.$router.push('/documenten/' + response.data.document_path_id);
                        }
                        else
                        {
                            this.$router.push('/documenten/');
                        }
                    })
                    .catch((err) => {
                        const errors = Object.entries(err.response.data.errors).map((err) => err[1]).flat().join('<br>');
                        this.$flashMessage(errors, 5000, 'error')
                    });
            },

            previewDoc(event) {
                let input = event.target;

                if (input.files && input.files[0]) {
                    let reader = new FileReader();
                    reader.onload = (e) => {
                        if (e.total > 22000000) {
                            this.$flashMessage('Upload bestanden kleiner dan 22mb', 5000);
                        }

                        this.doc = e.target.result;
                        this.filename = input.files[0].name;
                        this.file = input.files[0];
                        this.documentIsPdf = input.files[0].type === 'application/pdf';
                    };

                    reader.readAsDataURL(input.files[0]);
                }
            },

            removeDoc() {
                this.doc = null;
                this.file = null;
            },

            getFolderStructure() {
                window.axios.get('/documents/path/' + this.parentId).then(response => {
                    this.breadcrumbs = response.data.breadcrumbs;
                });
            },
            togglePopover: function() {
                this.showBreadcrumbs = !this.showBreadcrumbs;
                // some code to filter users
            },
            closePopOver() {
                this.showBreadcrumbs = false;
            },

            gotoDirectory(id) {
                if (id === null)
                {
                    return;
                }

                this.$router.push(`/documenten/${id}`);
            },

        },

        computed: {
            computedBreadcrumbs() {
                const breadcrumbs = this.breadcrumbs.slice().reverse();
                breadcrumbs.push({
                    id: null,
                    name: this.$t('documents_add_doc.add_new_document')
                });

                if (breadcrumbs.length <= 3) {
                    return breadcrumbs;
                }

                return [
                    ...breadcrumbs.slice(0, 2),
                    ...[breadcrumbs.slice(2, breadcrumbs.length - 1)],
                    ...breadcrumbs.slice(-1),
                ];
            }
        }

    };
</script>
