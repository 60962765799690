<template>
    <div class="content">
        <div class="header not-sticky">
            <h1>{{ $t('furloughs.detail.title') }}</h1>
            <span :class="getClassName(furlough.status)"
                  class="furlough-status" v-if="furlough.status">
                                {{ $t('furloughs.statuses.' + furlough.status).toUpperCase() }}
                            </span>
        </div>

        <div class="content-wrap">
            <div class="content-wrap__content">
                <div class="panel panel--push-top panel--bottom-square">
                    <h2 class="title title--push-bottom">{{ $t('furloughs.detail.data') }}</h2>

                    <div class="field">
                        <label class="label">{{ $t('furloughs.applicant') }}</label>
                        <label v-if="furlough.user" class="label value">{{ furlough.user.name }}</label>
                    </div>

                    <div class="field">
                        <label class="label">{{ $t('furloughs.from') }}</label>
                        <label v-if="furlough.user" class="label value">
                            {{ formatDate(furlough.from) | upperCaseFirst }}
                        </label>
                    </div>

                    <div class="field">
                        <label class="label">{{ $t('furloughs.to') }}</label>
                        <label v-if="furlough.user" class="label value">
                            {{ formatDate(furlough.to) | upperCaseFirst }}
                        </label>
                    </div>

                    <div v-if="furlough.notes && furlough.notes.length > 0" class="field">
                        <label class="label">{{ $t('furloughs.detail.explanation') }}</label>
                        <label class="label value">{{ furlough.notes }}</label>
                    </div>

                    <div class="field field--checkbox language-activator">
                        <input id="toggleLanguage" type="checkbox" v-model="furlough.is_processed_fleethours"
                               @change="toggleFleetHours" />
                        <label for="toggleLanguage"></label>
                        <label class="label"
                               for="toggleLanguage">{{ $t('furloughs.detail.is_processed_fleethours') }}</label>
                    </div>
                </div>
                <div class="panel panel--top-square">
                    <div class="field">
                        <label class="label" for="description">{{ $t('furloughs.detail.explanation') }}</label>
                        <textarea :disabled="furlough.status !== 1" v-if="furlough.user" id="description"
                                  v-model="furlough.admin_notes"></textarea>
                    </div>
                    <div class="buttons" v-if="furlough.status === 1">
                        <div class="btn btn--green" @click="saveFurlough(2)">
                            Goedkeuren
                        </div>
                        <div class="btn btn--red" @click="saveFurlough(3)">
                            Afkeuren
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";

export default {
    name: "furloughs-detail",
    data() {
        return {
            furlough: {}
        }
    },
    beforeRouteEnter(to, from, next) {
        window.axios.get('/furloughs/' + to.params.id)
                .then(response => {
                    next(vm => {
                        vm.furlough = response.data
                    })
                }).catch(() => {
            next(from)
        });
    },
    methods: {
        formatDate(date) {
            return moment(date).locale(window.authUser.language).format('dddd D MMMM Y')
        },
        saveFurlough(status) {
            window.axios.post('/furloughs/' + this.furlough.id, {
                status: parseInt(status),
                admin_notes: this.furlough.admin_notes
            })
                    .then(response => {
                        this.$router.push({name: 'furloughs.index'});
                    });
        },
        getClassName(status) {
            switch (status) {
                case 1:
                    return 'pending'
                case 2:
                    return 'approved'
                case 3:
                    return 'denied'
            }
        },
        toggleFleetHours() {
            window.axios.post('/furloughs/' + this.furlough.id + '/fleetHours', {
                is_processed_fleethours: this.furlough.is_processed_fleethours
            }).then(() => {
                this.$flashMessage(this.$t('furloughs.detail.saved'), 5000, 'succes');
            });
        }
    }
}
</script>

<style scoped>
.btn--green {
    margin-right: 16px;
}

h1 {
    margin-right: 16px;
}
</style>
