const state = {
    adjustment: {},
    language: {},
};

const getters = {
    adjustment: state => state.adjustment,
    language: state => state.language,
    components_by_adjustment_id: state => (state.adjustment && state.adjustment.components ? state.adjustment.components.sort((a, b) => a.position - b.position) : state),
};

const actions = {
    set_language({ commit, state }, { language }) {
        commit('set_language', {
            language: language
        })
    },
    swap_component_positions({ commit, state }, { oldComponentId, newComponentId }) {
        commit('swap_components', { oldComponentId, newComponentId });
        window.axios.put(`/components/swap-positions`, {
            'old': oldComponentId,
            'new': newComponentId,
        });
    },
    set_components({ commit, state }, { components }) {
        commit('update_adjustment_component_position', { components: components });

        let componentPositions = components.map(function (component) {
            return {
                'id': component.id,
                'pos': component.position
            };
        });

        window.axios.put(`/components/swap-positions`, componentPositions);
    },
    update_component({ commit }, component) {
        window.axios.put(`/components/${component.id}`, {
            content: component.content,
            language: component.language,
        }).then(response => {
            commit('update_component_content', {
                id: response.data.id,
                content: component.content,
                language: component.language,
            });
        });
    },
    delete_component({ commit }, { chapterKey, id }) {
        window.axios.delete(`/components/${id}`).then(() => {
            commit('delete_component', {
                id: id,
            });
        });
    },
    store_empty_component({ commit }, component) {
        const components = state.adjustment.components;
        const afterComponent = components.find(c => c.id === component.afterId);

        const promise = window.axios.post(`/components`, {
            componentable_id: component.data_id,
            componentable_type: component.componentable_type,
            component_type_id: component.component_type_id,
            ...(afterComponent ? { position: afterComponent.position } : {})
        });

        promise.then(response => {
            commit('add_component', {
                component: response.data,
            });

            if (afterComponent) {
                commit('reposition_components_after_component', {
                    component: response.data,
                });
            }
        });

        return promise;
    },
};

const mutations = {
    set_adjustment(state, adjustment) {
        state.adjustment = adjustment;
    },
    set_language(state, language) {
        state.language = language;
    },
    save(state) {
        window.axios.put('/adjustments/' + state.adjustment.id + '/save', {
            language: state.language,
        }).then((response) => {
            state.adjustment = response.data;
        });
    },
    update_adjustment_component_position(state, { components }) {
        state.adjustment.components = components;
    },
    swap_components(state, { oldComponentId, newComponentId }) {
        const adjustment = state.adjustment;
        const i = adjustment.components.findIndex(c => c.id === oldComponentId);
        const k = adjustment.components.findIndex(c => c.id === newComponentId);

        let tmp = adjustment.components[i].position;
        adjustment.components[i].position = adjustment.components[k].position;
        adjustment.components[k].position = tmp;
    },
    update_component_content(state, { id, content, language }) {
        let adjustment = state.adjustment;
        let component = adjustment.components.find(c => c.id === id);

        if (language.code) {
            let translation = component.translations.filter(function (translations) {
                return translations.language === language.code
            })[0];

            translation.content = content;
        }
    },
    delete_component(state, { id }) {
        let adjustment = state.adjustment;
        let components = adjustment.components;

        const i = components.findIndex(c => c.id === id);
        components.splice(i, 1);
    },
    add_component(state, { component }) {
        let adjustment = state.adjustment;
        let components = adjustment.components;

        components.push(component);
    },
    reposition_components_after_component(state, { component }) {
        let adjustment = state.adjustment;

        adjustment.components.forEach((cmp, i) => {
            if (cmp.position >= component.position && cmp.id !== component.id) {
                adjustment.components[i].position++;
            }
        });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
