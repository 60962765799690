import ArticleTabPushNotificationsOverview from '../components/Article/ArticleTabPushNotificationsOverview';
import ArticleTabPushNotificationsSend from '../components/Article/ArticleTabPushNotificationsSend';
import ArticleTabPushNotifications from '../components/Article/ArticleTabPushNotificationsSend';
import ArticleApp from '../components/ArticleApp';
import ArticleRelatedArticles from '../components/ArticleRelatedArticles';
import ArticleTabContent from '../components/ArticleTabContent';
import ArticleTabDivision from '../components/ArticleTabDivision';
import ArticleTabProfile from '../components/ArticleTabProfile';
import ArticleTabStory from '../components/ArticleTabStory';
import NewsApp from '../components/NewsApp';

export default [
    {
        path: '/nieuws',
        name: 'news',
        component: NewsApp,
        meta: {
            permissions: ['add-and-edit-news-items'],
        },
    },
    {
        path: '/nieuws/artikel/:id',
        component: ArticleApp,
        meta: {
            permissions: ['add-and-edit-news-items']
        },

        children: [
            {
                path: '',
                redirect: 'content',
            },
            {
                path: 'content',
                name: 'article-content',
                component: ArticleTabContent,
            },
            {
                path: 'related-articles',
                name: 'related-articles',
                component: ArticleRelatedArticles,
            },
            {
                path: 'profile',
                name: 'article-profile',
                component: ArticleTabProfile,
            },
            {
                path: 'story',
                name: 'article-story',
                component: ArticleTabStory,
            },
            {
                path: 'divisions',
                name: 'article-divisions',
                component: ArticleTabDivision,
            },
            {
                path: 'push-notificatie/push-notificatie-sturen',
                name: 'push-notification-send',
                component: ArticleTabPushNotificationsSend,
            },
            {
                path: 'push-notificatie/push-notificatie-overzicht',
                name: 'push-notification-overview',
                component: ArticleTabPushNotificationsOverview,
            },
        ],
    },
];