<template>
    <base-modal @close="close">
        <h1>{{ $t('new_chapter_modal.add_chapter') }}</h1>

        <label>{{ $t('new_chapter_modal.chapter_name') }}</label>

        <input type="text"
               v-model="value"
               @keydown.enter="addChapter">

        <div class="btn-wrap btn-wrap--right">
            <button :disabled="value.length === 0"
                    @click="addChapter"
                    class="btn btn--green btn--shadow btn--bold">
                {{ $t('new_chapter_modal.add_chapter') }}
            </button>
        </div>
    </base-modal>
</template>

<script>
    export default {
        name: 'new-chapter-modal',

        props: ['afterId'],

        data() {
            return {
                value: '',
            };
        },

        methods: {
            close() {
                this.$emit('close');
            },

            addChapter() {
                if (this.value.length > 0) {
                    this.$emit('add-chapter', {
                        chapter: {
                            id: Math.random().toString(36).replace('0.', ''),
                            chapter_id: null,
                            chapters: [],
                            components: [],
                            collapsed: false,
                            position: null,
                            translations: [
                                {
                                    content: this.value,
                                    language: 'nl'
                                }
                            ]
                        },
                        afterId: this.afterId,
                    });

                    this.close();
                }
            }
        }
    };
</script>
