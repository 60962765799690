<template>
    <div>
        <div class="content-head">
            <h1>{{ $t('article_tab_division.divisions') }}</h1>

            <div class="input-group divisions">
                <label for="profile">{{ $t('article_tab_division.divisions') }}</label>
                <vue-multiselect
                        :close-on-select="false"
                        :placeholder="$t('article_tab_division.placeholder')"
                        :multiple="true"
                        :searchable="false"
                        :options="business_units"
                        @close="save"
                        id="artikel1"
                        v-bind="config"
                        v-model="selectedBusinessUnits">
                    <template slot="tag" slot-scope="props">
                        <span v-text="props.option.name + ', '"></span>
                    </template>
                    <template slot="option" slot-scope="props">
                        <div class="option__desc">
                            <span class="option__title">{{ props.option.name }}</span>
                            <img alt="check" class="selected" src="/svg/check.svg">
                        </div>
                    </template>
                    <span slot="noResult">{{ $t('article_tab_division.no_results') }}</span>
                </vue-multiselect>
            </div>
        </div>

        <div class="content-head">
            <h1>{{ $t('article_tab_division.function') }}</h1>

            <div class="input-group divisions">
                <label for="profile">{{ $t('article_tab_division.function') }}</label>
                <vue-multiselect
                        :close-on-select="false"
                        :placeholder="$t('article_tab_division.all_functions')"
                        :multiple="true"
                        :searchable="false"
                        :options="employee_functions"
                        @close="save"
                        id="artikel1"
                        v-bind="config"
                        v-model="article.employee_functions">
                    <template slot="tag" slot-scope="props">
                        <span v-text="props.option.name + ', '"></span>
                    </template>
                    <template slot="option" slot-scope="props">
                        <div class="option__desc">
                            <span class="option__title">{{ props.option.name }}</span>
                            <img alt="check" class="selected" src="/svg/check.svg">
                        </div>
                    </template>
                    <span slot="noResult">{{ $t('article_tab_division.no_results') }}</span>
                </vue-multiselect>
            </div>
        </div>
    </div>
</template>

<script>
    import VueMultiselect from "vue-multiselect/src/Multiselect";
    import { BusinessUnitService } from "../services";

    export default {
        name: 'article-tab-division',

        components: {
            VueMultiselect
        },

        props: {
            article: {
                required: true,
            }
        },

        data() {
            return {
                config: {
                    label: "name",
                    'track-by': "id",
                    selectLabel: "",
                    selectedLabel: "",
                    deselectLabel: "",
                },
                business_units: [],
                employee_functions: [],
                selectedBusinessUnits: this.article && this.article.business_units ? this.article.business_units : [],
            }
        },

        watch: {
            article: {
                handler: function (val) {
                    this.selectedBusinessUnits = val && val.business_units ? val.business_units : [];
                    this.filterOut();
                },
                deep: true
            }
        },

        methods: {
            save() {
                this.$store.dispatch('articles/set_divisions_and_functions', {
                    divisions: this.selectedBusinessUnits,
                    functions: this.article.employee_functions,
                });
            },

            getBusinessUnits() {
                BusinessUnitService.fetchByPermission(this.$permissions.ADD_AND_EDIT_NEWS_ITEMS).then((response) => {
                    this.business_units = response.data;

                    if (!this.selectedBusinessUnits) {
                        return;
                    }

                    this.filterOut();
                });
            },

            filterOut() {
                this.selectedBusinessUnits = this.selectedBusinessUnits.filter((d) => {
                    return !!this.business_units.find(b => b.id === d.id);
                });
            },

            getEmployeeFunctions() {
                window.axios.get('/employee-functions').then((response) => {
                    this.employee_functions = response.data
                });
            },
        },

        beforeMount() {
            this.getBusinessUnits();
            this.getEmployeeFunctions();
        }
    };
</script>
