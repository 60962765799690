<template>
    <base-modal @close="$emit('close')">
        <h1>Contact persoon verwijderen?{{ $t('delete_contact_person_confirmation_modal.h1') }}</h1>
        <p>Dit kan niet ongedaan worden gemaakt.{{ $t('delete_contact_person_confirmation_modal.paragraph') }}</p>

        <div class="btn-wrap btn-wrap--right">
            <button class="btn" @click.prevent="$emit('close')">{{ $t('delete_contact_person_confirmation_modal.no') }}</button>
            <button class="btn btn--green btn--shadow" @click.prevent="$emit('confirm', true)">{{ $t('delete_contact_person_confirmation_modal.yes') }}</button>
        </div>
    </base-modal>
</template>

<script>
    export default {
        name: 'delete-contact-person-confirmation-modal',

        methods: {
            close() {
                this.$emit('close');
            }
        }
    };
</script>
