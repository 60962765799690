<template>
    <div>
        <div class="content-head">
            <h1>{{ $t('article_tab_profile.linked_profile') }}</h1>

            <div class="title-input">
                <label for="title">{{ $t('article_tab_profile.title') }}</label>
                <input @change="inputChanged" id="title" type="text" v-model="article.profile_title">
            </div>

            <div class="title-input">
                <label for="title">{{ $t('article_tab_profile.description') }}</label>
                <input @change="inputChanged" id="description" type="text" v-model="article.profile_description">
            </div>

            <div class="input-group related-articles">
                <label for="profile">{{ $t('article_tab_profile.profile') }}</label>
                <vue-multiselect
                    :options="contacts"
                    label="email"
                    :custom-label="multiSelectLabel"
                    @close="setProfile"
                    id="profile"
                    v-bind="translations"
                    :allow-empty="true"
                    :disabled="isDisabled"
                    v-model="article.profile" />
            </div>
        </div>
    </div>
</template>

<script>
    import VueMultiselect from "vue-multiselect/src/Multiselect";

    export default {
        name: 'article-tab-profile',

        components: { VueMultiselect },

        props: {
            article: {
                required: true,
            }
        },

        data() {
            return {
                translations: {
                    placeholder: this.$t('article_tab_profile.select_profile'),
                    label: "name",
                    'track-by': "id",
                    selectLabel: "",
                    selectedLabel: "",
                    deselectLabel: ""
                },
                businessUnits: [],
                departments: [],
                contacts: [],
            }
        },

        computed: {
            stateArticle() {
                return this.$store.state.articles.article;
            },

            isDisabled() {
                const profile = this.article && this.article.profile ? this.article.profile : null;

                if (!profile) {
                    return false;
                }

                return !this.contacts.find(c => c.id === profile.id);
            },
        },

        watch: {
            'stateArticle.profile_title': function (newVal, oldVal) {
                if (newVal === oldVal || oldVal === undefined) {
                    return;
                }

                this.save();
            },
            'stateArticle.profile_description': function (newVal, oldVal) {
                if (newVal === oldVal || oldVal === undefined) {
                    return;
                }

                this.save();
            },
            'stateArticle.profile_user_id': function (newVal, oldVal) {
                if (newVal === oldVal || oldVal === undefined) {
                    return;
                }

                this.save();
            }
        },

        methods: {
            inputChanged(e) {
                if (e.target.id === 'title') {
                    this.$store.commit('articles/set_profile_title', e.target.value);
                }

                if (e.target.id === 'description') {
                    this.$store.commit('articles/set_profile_description', e.target.value);
                }
            },

            setProfile() {
                this.$store.commit('articles/set_profile_user_id', this.article.profile ? this.article.profile.id : null);
            },

            multiSelectLabel(item) {
                let businessUnits = item.business_units && item.business_units.length ? item.business_units.map(bu => bu.name).join(' | ') : 'Alle divisies';
                let departments = item.departments && item.departments.length ? item.departments.map(d => d.name).join(' | ') : 'Alle afdeling';

                if(item.business_units && item.business_units.length === this.businessUnits.length) {
                    businessUnits = this.$t('article_tab_profile.all_divisions');
                }

                if(item.departments && item.departments.length === this.departments.length) {
                    departments = this.$t('article_tab_profile.all_departments');
                }

                return `${item.title} - ${businessUnits} - ${departments}`;
            },

            save() {
                window.axios.put(`/articles/${this.article.id}/link-profile`, {
                    profileTitle: this.article.profile_title,
                    profileDescription: this.article.profile_description,
                    contact: this.article.profile ? this.article.profile.id : null,
                });
            },

            getContacts() {
                window.axios.get(`/contacts/list?permission=${this.$permissions.ADD_AND_EDIT_NEWS_ITEMS}`).then((response) => {
                    this.contacts = response.data;
                });
            },

            getBusinessUnits() {
                window.axios.get('/business-units').then((response) => {
                    this.businessUnits = response.data
                });
            },

            getDepartments() {
                window.axios.get('/departments').then((response) => {
                    this.departments = response.data
                });
            },
        },

        beforeMount() {
            this.getContacts();
            this.getBusinessUnits();
            this.getDepartments();
        }
    };
</script>
