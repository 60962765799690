<template>
    <div class="component">
        <div class="component--header handle">
            <div class="title">
                {{ $t('componentTypes.' + componentType) }}
            </div>

            <div v-if="!versionDisabled"
                 class="options-menu">
                <div class="btn-circle btn-circle--transparent">
                    <svg fill="none"
                         height="4"
                         viewBox="0 0 16 4"
                         width="16"
                         xmlns="http://www.w3.org/2000/svg">
                        <path clip-rule="evenodd"
                              d="M2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0ZM14 0C12.9 0 12 0.9 12 2C12 3.1 12.9 4
                              14 4C15.1 4 16 3.1 16 2C16 0.9 15.1 0 14 0ZM6 2C6 0.9 6.9 0 8 0C9.1 0 10 0.9 10 2C10 3.1 9.1 4 8 4C6.9 4 6 3.1 6 2Z"
                              fill="black"
                              fill-rule="evenodd" />
                    </svg>
                </div>

                <div class="options-menu-items">
                    <a href="#"
                       @click.prevent="showDeleteComponentModal = true">{{ $t('ab_component.component_delete') }}</a>
                </div>
            </div>
        </div>

        <div class="component--content">
            <div v-if="componentType === 'title_lg'">
                <label>{{ $t('ab_component.title_big') }}</label>
                <input v-model="iContent"
                       :disabled="versionDisabled"
                       type="text">
            </div>

            <div v-if="componentType === 'title_sm'">
                <label>{{ $t('ab_component.title_small') }}</label>
                <input v-model="iContent"
                       :disabled="versionDisabled"
                       type="text">
            </div>

            <div v-if="componentType === 'textarea'">
                <label>{{ $t('ab_component.textbox') }}</label>
                <redactor-text-area :key="'redactor-for-' + id"
                                    ref="editor"
                                    v-model="iContent"
                                    :config="redactorConfig"
                                    :disabled="versionDisabled"
                                    @click-custom-button="customButtonClick" />
            </div>

            <div v-if="componentType === 'image'">
                <div v-if="! iContent"
                     class="image-uploader">
                    <input ref="image"
                           :disabled="versionDisabled"
                           accept="image/*"
                           style="display: none"
                           type="file"
                           @change="previewImage">
                    <p>{{ $t('upload_component.add_files') }}</p>
                    <span>{{ $t('upload_component.or') }}</span>
                    <a v-if="!versionDisabled"
                       class="btn btn--blue"
                       href="#"
                       @click.prevent="$refs.image.click()">
                        {{ $t('upload_component.add_image') }}
                    </a>
                </div>

                <div v-if="iContent"
                     class="image-preview">
                    <img v-if="iContent.includes('base64')"
                         :src="iContent">
                    <img v-else
                         :src="`/files/translations/${iContent}?${randomImageVersion}`">
                    <a v-if="!versionDisabled"
                       href="#"
                       @click.prevent="removeImage">{{ $t('upload_component.delete_image') }}</a>
                </div>
            </div>

            <div v-if="componentType === 'pdf'">
                <div v-if="iContent">
                    <label>{{ $t('ab_component.pdf_name') }}</label>
                    <input v-model="pdfName"
                           :disabled="versionDisabled"
                           type="text">
                </div>
                <div v-if="! iContent"
                     class="image-uploader">
                    <input ref="pdf"
                           :disabled="versionDisabled"
                           accept="application/pdf"
                           style="display: none"
                           type="file"
                           @change="uploadPdf">
                    <p>{{ $t('upload_component.add_files') }}</p>
                    <span>Of</span>
                    <a v-if="!versionDisabled"
                       class="btn btn--blue"
                       href="#"
                       @click.prevent="$refs.pdf.click()">
                        {{ $t('ab_component.add_pdf') }}
                    </a>
                </div>

                <div v-if="iContent"
                     class="image-preview">
                    <a :href="`/files/translations/${pdfLink}?${randomImageVersion}`"
                       target="_blank">
                        {{ $t('ab_component.preview_pdf') }}
                    </a>

                    <br>

                    <a v-if="!versionDisabled"
                       href="#"
                       @click.prevent="removeImage">{{ $t('ab_component.delete_pdf') }}</a>
                </div>
            </div>

            <poll-component v-if="componentType === 'poll'"
                            v-model="iContent"
                            :version-disabled="versionDisabled" />
        </div>

        <portal to="modals">
            <delete-component-modal v-if="showDeleteComponentModal"
                                    @close="showDeleteComponentModal = false"
                                    @confirm="deleteComponent" />
            <add-chapter-link v-if="showAddChapterLinkModal"
                              :languageCode="this.language.code"
                              @close="showAddChapterLinkModal = false"
                              @confirm="addChapterLink" />
            <add-article-link-modal v-if="showAddArticleLinkModal"
                                    :languageCode="this.language.code"
                                    @close="showAddArticleLinkModal = false"
                                    @confirm="addArticleLink" />
        </portal>
    </div>
</template>

<script>
import debounce from 'lodash.debounce';
import '../lang/nl';
import AddArticleLinkModal from './Article/modals/AddArticleLinkModal';
import AddChapterLink from './Article/modals/AddChapterLinkModal';
import PollComponent from './ComponentTypes/PollComponent';

export default {
    name: 'Abcomponent',

    props: {
        versionDisabled: {
            type: undefined,
            required: true,
        },
        componentType: {
            type: String,
            required: true,
        },
        translations: {
            type: undefined,
            required: true,
        },
        id: {
            type: Number,
            required: false,
        },
        language: {
            type: undefined,
            required: true,
        },
        type: {
            type: undefined,
            required: true,
        },
    },
    components: {
        AddArticleLinkModal,
        PollComponent,
        AddChapterLink,
    },

    data() {
        return {
            redactorConfig: {
                lang: 'nl',
                buttons: ['format', 'bold', 'italic', 'ul', 'ol', 'link'],
                formatting: ['p'],
                pasteImages: false,
                plugins: ['table'],
                customButtons: [
                    {
                        id: 'add-chapter-link',
                        label: this.$t('redactor_custom_buttons.manual_link'),
                    },
                    {
                        id: 'add-article-link',
                        label: this.$t('redactor_custom_buttons.article_link')
                    }
                ],
            },
            iContent: null,
            pdfName: null,
            iComponentType: this.componentType,
            showDeleteComponentModal: null,
            oldLanguage: {},
            randomImageVersion: Math.random().toString(36).substring(5),
            showAddChapterLinkModal: false,
            showAddArticleLinkModal: false,
        };
    },

    computed: {
        pdfLink() {
            if (this.iComponentType !== 'pdf') {
                return '';
            }

            const translation = this.translations.find(t => t.language === this.language.code);

            if (!translation) {
                return '';
            }

            return `${translation.content}`;
        },
    },

    watch: {
        pdfName(newVal, oldVal) {
            if (oldVal !== null) {
                this.debouncedUpdateComponent();
            }
        },
        iContent(newVal, oldVal) {
            if (oldVal !== null) {
                this.debouncedUpdateComponent();
            }
        },
        language() {
            if (this.oldLanguage.code !== this.language.code) {
                this.updateComponent();
            }
        },
        translations: {
            handler: function () {
                this.setContent();
            },
            deep: true
        }
    },

    created() {
        this.setContent();
        this.debouncedUpdateComponent = debounce(this.updateComponent, 1200);
    },

    methods: {
        previewImage(event) {
            const input = event.target;

            if (input.files && input.files[0]) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    if (e.total > 22000000) {
                        this.$flashMessage('Upload afbeeldingen kleiner dan 22mb', 5000);
                        return;
                    }
                    this.iContent = e.target.result;
                    this.updateComponent();
                };

                reader.readAsDataURL(input.files[0]);
            }
        },

        uploadPdf(event) {
            const input = event.target;

            if (input.files && input.files[0]) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    if (e.total > 22000000) {
                        this.$flashMessage('Upload afbeeldingen kleiner dan 22mb', 5000);
                        return;
                    }
                    let name = input.files[0].name.toString()
                    let lastFourCharacters = name.substr(name.length - 4, name.length);
                    if (lastFourCharacters === '.pdf' || lastFourCharacters === '.PDF') {
                        name = name.substr(0, name.length - 4)
                    }
                    this.pdfName = name;
                    this.iContent = e.target.result;
                    this.updateComponent();
                };

                reader.readAsDataURL(input.files[0]);
            }
        },

        setContent() {
            const self = this;
            const translation = this.translations.filter(translations => translations.language === self.language.code)[0];

            this.iContent = translation.content;
            if (this.iComponentType === "pdf") {
                let content = translation.content
                content = content.replace(translation.id + "_", '');
                let lastFourCharacters = content.substr(content.length - 4, content.length);
                if (lastFourCharacters === '.pdf' || lastFourCharacters === '.PDF') {
                    content = content.substr(0, content.length - 4)
                }
                this.pdfName = content;
            }

            this.oldLanguage = this.language;
        },

        removeImage() {
            this.iContent = '';
            this.pdfName = '';
            this.updateComponent();
        },

        updateComponent() {
            const params = {
                id: this.id,
                content: this.iContent,
                language: this.oldLanguage,
            };

            if (this.iComponentType === "pdf") {
                params.pdfName = this.pdfName
            }

            this.$store.dispatch(`${this.type}s/update_component`, params);
            if (this.oldLanguage.code !== this.language.code) {
                this.oldLanguage = this.language;
                this.setContent();
                return;
            }
            this.$resetMessages();
            this.$flashMessage('Succesvol opgeslagen', 5000, 'succes');
        },

        deleteComponent() {
            this.showDeleteComponentModal = false;
            const params = {
                id: this.id,
            };

            this.$store.dispatch(`${this.type}s/delete_component`, params);
        },

        customButtonClick({ key, redactor }) {

            // Save current position of the cursor.
            this.$refs.editor.redactor.selection.save();

            switch (key) {
                case 'add-chapter-link':
                    this.showAddChapterLinkModal = true;
                    break;
                case 'add-article-link':
                    this.showAddArticleLinkModal = true;
                    break;
                default:
                    break;
            }
        },

        addChapterLink({ label, manual, chapter }) {
            const chapterTitle = chapter.translations[0].content;
            const template = `<span class="redactor-component" data-manual-id="${manual.id}" data-article-chapter-link="${chapterTitle}">${label || chapterTitle}</span>`;

            // Restore position of the cursor.
            this.$refs.editor.redactor.selection.restore();

            this.$refs.editor.redactor.insertion.insertHtml(template);

            this.showAddChapterLinkModal = false;
        },

        addArticleLink({label, article}) {
            const template = `<span class="redactor-component" data-redactor-type="articlelink" data-link="${article}">${label}</span>`;

            // Restore position of the cursor.
            this.$refs.editor.redactor.selection.restore();

            this.$refs.editor.redactor.insertion.insertHtml(template);

            this.showAddArticleLinkModal = false;
        }
    },
};
</script>
