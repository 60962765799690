<template>
    <div class="content">
        <div class="header">
            <h1>{{ $t('roles.roles') }}</h1>

            <div class="header-buttons">
                <div @click="createRole">
                    <div class="button green">
                        <span>{{ $t('roles.add_role') }}</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="body">
            <div class="search">
                <label for="search">
                    <img alt="search" src="img/search.svg">
                </label>

                <input id="search" :placeholder="$t('roles.search')" type="text" v-model="search" @keyup="onSearch" />
            </div>

            <table ref="overviewTable">
                <thead>
                    <tr>
                        <th class="sortable" :class="{'sorted': sortByColumn === 'name'}" @click="sortBy('name')">{{ $t('roles.name') }}</th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-for="role in roles" :key="'roles_' + role.id" @click="editRole(role)">
                        <td>{{ role.name }}</td>
                    </tr>
                </tbody>
            </table>

            <div class="table-no-results" v-if="! roles.length">
                {{ $t('roles.no_results') }}
            </div>
        </div>
    </div>
</template>

<script>
    import debounce from 'lodash.debounce';

    export default {
        name: 'roles-index',

        data() {
            return {
                roles: [],
                search: '',
                sortByColumn: null,
                sortDirection: 'asc',
            };
        },

        beforeMount() {
            this.fetchRoles();
        },

        methods: {
            createRole() {
                this.$router.push({ name: 'roles.create' });
            },

            editRole(role) {
                this.$router.push({ name: 'roles.edit', params: { id: role.id } });
            },

            onSearch: debounce(function () {
                this.fetchRoles();
            }, 500),

            fetchRoles() {
                const params = {
                    page: this.currentPage,
                    ...(this.search) && { search: this.search },
                    ...(this.sortByColumn && { orderBy: this.sortByColumn }),
                    ...(this.sortByColumn && { orderByDirection: this.sortDirection }),
                };

                const promise = window.axios.get('/roles', { params });

                promise.then((response) => {
                    this.roles = response.data;
                });

                return promise;
            },

            sortBy(s) {
                if (s === this.sortByColumn) {
                    this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
                } else {
                    this.sortDirection = 'asc';
                }

                this.sortByColumn = s;
                this.roles = [];
                this.fetchRoles();
            },
        },
    }
</script>
