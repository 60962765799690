<template>
    <div class="statistics-content-wrap">
        <div class="content">
            <div class="header"
                 id="n-header">
                <h1 id="n-h1">
                    {{ $t('statistics_app.statistics') }}
                </h1>
            </div>
            <div class="body">
                <div class="tabs">
                    <router-link tag="div"
                                 to="logins">
                        {{ $t('statistics_app.login') }}
                    </router-link>
                    <router-link tag="div"
                                 to="news">
                        {{ $t('statistics_app.news') }}
                    </router-link>
                    <router-link tag="div"
                                 to="fuel-reports">
                        {{ $t('statistics_app.fuelReports') }}
                    </router-link>
                </div>
                <router-view />
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'StatisticsApp',
    };
</script>

<style lang="scss">
    .statistics-content-wrap {
        display : flex;

        .statistics-content-wrap-header {
            display         : flex;
            align-items     : center;
            justify-content : space-between;
            margin-top      : 32px;
        }

        .table {
            tbody {
                tr {
                    height : auto !important;
                }
            }

            th {
                border-top : none;
            }

            th, td {
                font-size : 16px;
            }
        }

        h2 {
            font-size   : 24px;
            font-weight : 800;
            font-style  : normal;
            line-height : 100%;
        }
    }
</style>
