import StatisticsApp from "../components/Statistics/StatisticsApp";
import StatisticsTabLogins from "../components/Statistics/StatisticsTabLogins";
import StatisticsTabNews from "../components/Statistics/StatisticsTabNews";
import StatisticsTabFuelReports from "../components/Statistics/StatisticsTabFuelReports";

export default [
    {
        path: '/statistieken',
        component: StatisticsApp,
        meta: {
            permissions: ['see-statistics']
        },
        children: [
            {
                path: '',
                redirect: 'logins',
            },
            {
                path: 'logins',
                name: 'logins',
                component: StatisticsTabLogins,
            },
            {
                path: 'news',
                name: 'news',
                component: StatisticsTabNews,
            },
            {
                path: 'fuel-reports',
                name: 'fuel-reports',
                component: StatisticsTabFuelReports,
            },
        ],
    },
];
