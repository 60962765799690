<template>
    <div>
        <portal-target name="modals">
            <!--Teleport modals here-->
        </portal-target>
        <portal to="modals">
            <delete-doc-modal @close="showDeleteDocModal = false"
                              v-if="showDeleteDocModal" @confirm="deleteDoc" />
        </portal>
        <div class="content">
            <div class="header" id="n-header">
                <ul class="breadcrumbs">
                    <div v-for="breadcrumb in computedBreadcrumbs">
                        <a v-if="! Array.isArray(breadcrumb)" @click.prevent="gotoDirectory(breadcrumb.id)">{{ breadcrumb.name }}</a>
                        <a @click="togglePopover" v-else>
                            ...
                            <div class="popover-breads" :class="{ show : showBreadcrumbs }" v-click-outside="closePopOver">
                                <li v-for="subBreadcrumb in breadcrumb">
                                    <a @click.prevent="gotoDirectory(subBreadcrumb.id)">{{ subBreadcrumb.name }}</a>
                                </li>
                            </div>
                        </a>
                    </div>
                </ul>
                <div class="header-buttons" id="n-header-buttons">
                    <div>
                        <div @click="showDeleteDocModal = true" class="button light-blue">
                            <span>{{ $t('documents_edit_doc.delete_doc') }}</span>
                        </div>
                    </div>
                    <div>
                        <div class="button green">
                            <span @click="updateDocument">{{ $t('documents_edit_doc.save_changes') }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <documents-tabs :document="document" />

            <router-view 
                v-model="document"
            />
        </div>
    </div>
</template>

<script>
    import DeleteDocModal from './DeleteDocModal';
    import DocumentsTabs from './DocumentsTabs.vue';

    export default {
        name: 'documents-app',
        components: { 
            DeleteDocModal, 
            DocumentsTabs 
        },
        data() {
            return {
                showDeleteDocModal: false,
                document: null,
                breadcrumbs: [],
                showBreadcrumbs: false,
            };
        },

        async beforeMount() {
            this.document = (await window.axios.get('/documents/files/' + this.$route.params.id)).data;
            this.getFolderStructure();
        },

        methods: {
            updateDocument() {
                const formData = new FormData();
                formData.append('name', this.document.name);

                if (this.document.file) {
                    formData.append('data', this.document.file);
                    formData.append('filename', this.document.file_name);
                }

                const requestOptions = {
                    'Content-Type': 'multipart/form-data',
                };

                window.axios.post(`/documents/files/update/${this.$route.params.id}`, formData, requestOptions)
                      .then((response) => {
                          if (response.data.document_path_id !== null) {
                              this.$router.push('/documenten/' + response.data.document_path_id);
                          } else {
                              this.$router.push('/documenten/');
                          }
                      })
                      .catch((err) => {
                          const errors = Object.entries(err.response.data.errors).map((err) => err[1]).flat().join('<br>');
                          this.$flashMessage(errors, 5000, 'error');
                      });
            },

            deleteDoc() {
                window.axios.delete('/documents/files/delete/' + this.$route.params.id).then(response => {
                    if (response.data.parent_id !== null) {
                        this.$router.push('/documenten/' + response.data.parent_id);
                    } else {
                        this.$router.push('/documenten/');
                    }
                });
            },

            getFolderStructure() {
                window.axios.get('/documents/path/' + this.document.document_path_id).then(response => {
                    this.breadcrumbs = response.data.breadcrumbs;
                });
            },
            togglePopover: function () {
                this.showBreadcrumbs = !this.showBreadcrumbs;
                // some code to filter users
            },
            closePopOver() {
                this.showBreadcrumbs = false;
            },

            gotoDirectory(id) {
                if (id === null) {
                    return;
                }

                this.$router.push(`/documenten/${id}`);
            },

        },

        computed: {
            computedBreadcrumbs() {
                const breadcrumbs = this.breadcrumbs.slice().reverse();
                breadcrumbs.push({
                    id: null,
                    name: this.$t('documents_edit_doc.edit_doc'),
                });

                if (breadcrumbs.length <= 3) {
                    return breadcrumbs;
                }

                return [
                    ...breadcrumbs.slice(0, 2),
                    ...[breadcrumbs.slice(2, breadcrumbs.length - 1)],
                    ...breadcrumbs.slice(-1),
                ];
            },
        },
    };
</script>
