(function ($R) {
    $R.add('plugin', 'linkreference', {
        init: function (app) {
            // define app
            this.app = app;

            // define services
            this.lang = app.lang;
            this.toolbar = app.toolbar;
            this.insertion = app.insertion;
            this.component = app.component;
            this.inspector = app.inspector;
        },

        start: function () {
            // create the button data
            let buttonData = {
                title: 'Koppel hoofdstuk',
                api: 'plugin.linkreference.open'
            };

            // create the button
            let $button = this.toolbar.addButton('linkreference', buttonData);
        },

        open: function () {
            let options = {
                title: 'Koppel naar hoofdstuk',
                width: '400px',
                name: 'linkreference',
                handle: 'insert',
                commands: {
                    insert: { title: 'Invoegen', },
                    cancel: { title: 'Annuleren' }
                }
            };

            this.app.api('module.modal.build', options);
        },

        modals: {
            'linkreference': '<form action="">'
                + '<div class="form-item">'
                + '<label>Label</label>'
                + '<input type="text" name="title" />'
                + '</div>'
                + '<div class="form-item">'
                + '<label>Hoofdstuk</label>'
                + '<select name="chapter">'
                + '</select>'
                + '</div>'
                + '</form>'
        },

        // messages
        onmodal: {
            'linkreference': {
                opened: ($modal, $form) => {
                    let chapters = JSON.parse($modal.app.rootElement.dataset.chapters) || [];
                    const selectEl = $form.getField('chapter').nodes[0];
                    chapters.forEach((chapter) => {
                        var opt = document.createElement('option');
                        opt.value = chapter.id;
                        opt.innerHTML = chapter.title;
                        selectEl.appendChild(opt);
                    });

                    $form.getField('title').focus();
                },
                insert: function ($modal, $form) {
                    var data = $form.getData();
                    this._insert(data);
                }
            }
        },

        oncontextbar: function(e, contextbar)
        {
            var data = this.inspector.parse(e.target)
            if (data.isComponentType('linkreference'))
            {
                var node = data.getComponent();
                this.app.broadcast('linkreference.started', node.dataset.refTrack);
            }
        },

        _insert: function (data) {
            this.app.api('module.modal.close');

            let reference = this.component.create('linkreference');
            reference.html('[ ' + data.title + ' ]');
            reference.setRef(data.chapter);

            this.insertion.insertRaw(reference);
        }
    });
})(Redactor);

(function ($R) {
    $R.add('class', 'linkreference.component', {
        mixins: ['dom', 'component'],
        init: function(app, el)
        {
            this.app = app;
            this.utils = app.utils;

            // init
            return (el && el.cmnt !== undefined) ? el : this._init(el);
        },
        setRef(ref) {
            this.attr({
                'data-ref-track': ref,
            });
        },

        // private
        _init: function(el)
        {
            el = el || '<span>';

            this.parse(el);
            this._initWrapper();
        },
        _getType: function()
        {
            var text = this.text().trim();

            return this.utils.removeInvisibleChars(text);
        },
        _initWrapper: function()
        {
            this.addClass('redactor-component');
            this.attr({
                'data-redactor-type': 'linkreference',
                'tabindex': '-1',
                'contenteditable': false
            });
        }
    });
})(Redactor);
