<template>
    <div>
        <div class="head">
            <div class="search">
                <label for="search">
                    <img alt="search-icon" src="/svg/search.svg">
                </label>
                <input id="search" type="text" v-model="search">
            </div>
            <div class="selector">
                <div :class="{ active:option === 1 }" @click="option = 1" class="item">
                    {{ $t('manual_tab_confirmed.not_confirmed') }}
                </div>
                <div :class="{ active:option === 2 }" @click="option = 2" class="item">
                    {{ $t('manual_tab_confirmed.confirmed') }}
                </div>
                <div :class="{ active:option === 3 }" @click="option = 3" class="item">
                    {{ $t('manual_tab_confirmed.show_all') }}
                </div>
            </div>
            <vue-multiselect
                :close-on-select="true"
                :multiple="false"
                :options="businessUnits"
                :searchable="false"
                :placeholder="this.$t('manual_tab_divisions.all_divisions')"
                class="division-select"
                id="businessUnits"
                label="name"
                v-model="selectedBusinessUnit"
                selected-label=""
                select-label=""
                deselect-label=""
                @input="filterBusinessUnit"
                track-by="id">
                <template slot="tag" slot-scope="props">
                    <span v-text="props.option.name + ', '"></span>
                </template>
                <template slot="option"
                          slot-scope="props">
                    <div class="option__desc">
                        <span class="option__title">{{ props.option.name }}</span>
                        <img alt="check"
                             class="selected" src="/svg/check.svg">
                    </div>
                </template>
            </vue-multiselect>
        </div>
        <div class="body">
            <table>
                <thead>
                    <tr>
                        <th>{{ $t('manual_tab_confirmed.title') }}</th>
                        <th>{{ $t('manual_tab_confirmed.type') }}</th>
                        <th>{{ $t('manual_tab_confirmed.date_agree') }}</th>
                        <th>{{ $t('manual_tab_confirmed.phone') }}</th>
                        <th>{{ $t('manual_tab_confirmed.business_unit') }}</th>
                    </tr>
                </thead>

                <tbody ref="tableBody">
                    <tr v-for="user in filteredUsers">
                        <td>{{ user.name }}</td>
                        <td>{{
                                user.confirmed === 1 ? $t('manual_tab_confirmed.not_confirmed') : $t('manual_tab_confirmed.confirmed')
                            }}
                        </td>
                        <td>{{ user.confirmed_date }}</td>
                        <td>{{ user.phonenumber }}</td>
                        <td>{{ user.business_unit ? user.business_unit.name : "" }}</td>
                    </tr>
                </tbody>
            </table>
            <div class="table-no-results" v-if="!filteredUsers.length">
                {{ $t('manual_tab_confirmed.no_results') }}
            </div>
        </div>
    </div>
</template>
<script>
    import businessUnitService from "../services/business-units";
    import VueMultiselect from "vue-multiselect/src/Multiselect";

    export default {
        name: 'ManualTabConfirmed',
        data() {
            return {
                users: [],
                option: 1,
                search: '',
                selectedBusinessUnit: null,
                businessUnits: [],
            }
        },
        components: { VueMultiselect },
        props: {
            manualId: {
                required: true,
            },
            manualVersion: {
                required: true,
            }
        },
        watch: {
            manualId() {
                this.getUsers()
            },
            "manualVersion.version"() {
                this.getUsers()
            }
        },
        created() {
            this.$resetMessages();
            this.getUsers();
        },
        computed: {
            filteredUsers() {
                let vm = this;

                return this.users.filter(function (user) {
                    if (vm.search === "" || user.name.toLowerCase().includes(vm.search.toLowerCase()) || user.phonenumber.toLowerCase().includes(vm.search.toLowerCase())) {
                        if (vm.option === 3) {
                            return true;
                        }
                        return user.confirmed === vm.option;
                    }
                });
            }
        },
        methods: {
            getUsers() {
                if (this.manualId !== null) {
                    window.axios.get('/manuals/' + this.manualId + '/users' + (this.selectedBusinessUnit ? '/' + this.selectedBusinessUnit.id : ''), {
                        params: {
                            version: this.manualVersion.version
                        }
                    }).then(response => {
                        this.users = response.data;
                    });
                }
            },
            filterBusinessUnit() {
                this.users = [];
                this.getUsers();
            },
            getBusinessUnits() {
                businessUnitService.fetch().then((response) => {
                    this.businessUnits = response.data;
                });
            },
        },
        beforeMount() {
            this.getBusinessUnits();
        }
    }
</script>
