import RolesIndex from "../components/Roles/index";
import RolesForm from "../components/Roles/role-form";

export default [
    {
        path: '/rollen',
        component: RolesIndex,
        name: 'roles.index',
        meta: {
            permissions: ['add-and-edit-roles']
        },
    },
    {
        path: '/rollen/nieuw',
        component: RolesForm,
        name: 'roles.create',
        meta: {
            permissions: ['add-and-edit-roles']
        },
    },
    {
        path: '/rollen/:id',
        component: RolesForm,
        name: 'roles.edit',
        meta: {
            permissions: ['add-and-edit-roles']
        },
    },
];