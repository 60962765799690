<template>
    <div class="content">
        <div class="header">
            <h1>{{ $t('locations_on_route.locations_on_route') }}</h1>

            <div class="header-buttons">
                <div @click="createLocation">
                    <div class="button green">
                        <span>{{ $t('locations_on_route.add_location') }}</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="body">
            <table>
                <thead>
                    <tr>
                        <th>{{ $t('locations_on_route.title') }}</th>
                        <th>{{ $t('locations_on_route.division') }}</th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-for="location in locations" :key="'locations_' + location.id" @click="editLocation(location)">
                        <td>{{ location.title }}</td>
                        <td>{{ location.division }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'locations-index',

        data() {
            return {
                locations: [
                ],
                sortByColumn: null,
                sortDirection: 'asc',
            };
        },

        beforeMount() {
            this.fetchLocations();
        },

        methods: {
            createLocation() {
                this.$router.push({ name: 'locations.create' });
            },

            editLocation(location) {
                this.$router.push({ name: 'locations.edit', params: { id: location.id } });
            },

            fetchLocations() {
                window.axios.get('/locations')
                      .then((response) => {
                          this.locations = response.data;
                      });
            },
        },
    }
</script>
