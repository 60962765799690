<template>
    <div>
        <div class="content">
            <div id="n-header" class="header">
                <ul class="breadcrumbs">
                    <div v-for="breadcrumb in computedBreadcrumbs">
                        <a v-if="! Array.isArray(breadcrumb)" @click.prevent="gotoDirectory(breadcrumb.id)">{{ breadcrumb.name }}</a>
                        <a v-else @click="togglePopover">
                            ...
                            <div v-click-outside="closePopOver" :class="{ show : showBreadcrumbs }" class="popover-breads">
                                <li v-for="subBreadcrumb in breadcrumb">
                                    <a @click.prevent="gotoDirectory(subBreadcrumb.id)">{{ subBreadcrumb.name }}</a>
                                </li>
                            </div>
                        </a>
                    </div>
                </ul>
                <div id="n-header-buttons" class="header-buttons">
                    <div v-if="parentId !== ''">
                        <div class="button light-blue">
                            <router-link :to="'/documenten/link-toevoegen/' + this.parentId" tag="span">{{ $t('documents_app.add_link') }}</router-link>
                        </div>
                    </div>
                    <div v-if="parentId !== ''">
                        <div class="button light-blue">
                            <router-link :to="'/documenten/document-toevoegen/' + this.parentId" tag="span">{{ $t('documents_app.add_document') }}</router-link>
                        </div>
                    </div>
                    <div>
                        <div class="button green">
                            <router-link :to="'/documenten/map-toevoegen/' + this.parentId" tag="span">{{ $t('documents_app.add_folder') }}</router-link>
                        </div>
                    </div>
                </div>
            </div>
            <div class="body">
                <div class="search">
                    <label for="search">
                        <img alt="search" src="/img/search.svg">
                    </label>
                    <input id="search" v-model="searchQuery" :placeholder="$t('documents_app.search_placeholder')" type="text" @keyup="search" />
                </div>

                <table>
                    <thead>
                        <tr>
                            <th class="more sortable">{{ $t('documents_app.title') }}</th>
                            <th class="sortable">{{ $t('documents_app.last_changed') }}</th>
                            <th></th>
                        </tr>
                    </thead>

                    <tbody ref="tableBody" class="documents-list">
                        <router-link v-for="document in documents"
                                     v-if="document.visible && document.type === 'map'"
                                     :key="document.id"
                                     :to="'/documenten/' + document.id"
                                     tag="tr">
                            <td>
                                <img v-if="document.type === 'map'" src="/img/baseline-folder-24px.svg">
                                {{ document.name }}
                            </td>
                            <td>{{ formatTimestamp(document.lastUpdate) }}</td>
                            <td>
                                <router-link v-if="document.type === 'map'" :to="'/documenten/map-bewerken/' + document.id" class="edit">
                                    <img src="/img/edit.svg">
                                </router-link>
                            </td>
                        </router-link>
                        <tr v-for="document in documents"
                            v-if="document.visible && document.type === 'doc'"
                            :key="document.id" @click="viewOrDownload(document)">
                            <td>
                                <img :src="document.docType === 'document' ? '/img/baseline-doc-24px.svg' : '/img/link.svg'">
                                {{ document.name }}
                            </td>
                            <td>{{ formatTimestamp(document.lastUpdate) }}</td>
                            <td>
                                <span v-on:click.stop>
                                    <router-link :to="'/documenten/' + (document.docType === 'document' ? 'document-bewerken' : 'link-bewerken') + '/' + document.id"
                                                 class="edit">
                                        <img src="/img/edit.svg">
                                    </router-link>
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div v-if="! documents.length" class="table-no-results">
                    {{ $t('documents_app.no_searchresults') }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ClickOutside from 'vue-click-outside';
    import moment from 'moment';

    export default {
        name: 'documents-app',

        beforeRouteUpdate(to, from, next) {
            this.parentId = to.params.parentId !== undefined ? to.params.parentId : '';
            this.documents = [];
            this.breadcrumbs = [];
            this.getFolderStructure();
            next();
        },

        directives: {
            ClickOutside,
        },
        data() {
            return {
                parentId: null,
                loading: false,
                loaded: false,
                documents: [],
                page: 0,
                searchQuery: null,
                searchResults: null,
                showBreadcrumbs: false,
                breadcrumbs: [],
            };
        },

        created() {
            this.parentId = this.$route.params.parentId !== undefined ? this.$route.params.parentId : '';
            this.getFolderStructure();
        },

        methods: {
            viewOrDownload(document) {
                switch (document.docType) {
                    case 'document':
                        window.open('/documents/files/download/' + document.id);
                        break;
                    case 'url':
                        window.open(document.url);
                }
            },

            getFolderStructure() {
                window.axios.get('/documents/path/' + this.parentId).then(response => {
                    this.documents = response.data.documents;
                    this.breadcrumbs = response.data.breadcrumbs;
                });
            },
            togglePopover: function () {
                this.showBreadcrumbs = !this.showBreadcrumbs;
                // some code to filter users
            },
            closePopOver() {
                this.showBreadcrumbs = false;
            },

            gotoDirectory(id) {
                this.$router.push(`/documenten/${id}`);
            },

            formatTimestamp(ts) {
                return moment.unix(ts).format('D MMM. YYYY');
            },

            search() {
                this.documents.forEach(document => {
                    if (this.searchQuery === '' ||
                        this.searchQuery === null ||
                        document.name.toLowerCase().includes(this.searchQuery.toLowerCase())) {
                        document.visible = true;
                    } else {
                        document.visible = false;
                    }
                });
            },
        },
        computed: {
            computedBreadcrumbs() {
                const breadcrumbs = this.breadcrumbs.slice().reverse();

                if (breadcrumbs.length <= 3) {
                    return breadcrumbs;
                }

                return [
                    ...breadcrumbs.slice(0, 2),
                    ...[breadcrumbs.slice(2, breadcrumbs.length - 1)],
                    ...breadcrumbs.slice(-1),
                ];
            },
        },
    };
</script>
