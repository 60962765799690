import moment from 'moment';

export default {
    toDate(dateTime) {
        if (!dateTime) {
            return '';
        }

        let date = new Date(dateTime);

        return [
            ('0' + date.getDate()).slice(-2),
            ('0' + (date.getMonth() + 1)).slice(-2),
            date.getFullYear(),
        ].join('-');
    },

    upperCaseFirst(value) {
        if (!value) {
            return '';
        }
        value = value.toString();
        return value.charAt(0).toUpperCase() + value.slice(1);
    },

    lowerCaseFirst(value) {
        if (!value) {
            return '';
        }
        value = value.toString();
        return value.charAt(0).toLowerCase() + value.slice(1);
    },

    toFormattedDate(dateTime, format) {
        if (!dateTime) {
            return '';
        }

        return moment(dateTime).lang('nl').format(format);
    },
};
