<template>
    <div class="tabs">
        <router-link tag="div" :to="{ name: 'article-content' }">{{ $t('article_tabs.content') }}</router-link>
        <router-link tag="div" :to="{ name: 'article-story' }" v-if="article.article_type_id !== 2">{{ $t('article_tabs.story') }}</router-link>
        <router-link tag="div" :to="{ name: 'article-profile' }">{{ $t('article_tabs.linked_profile') }}</router-link>
        <router-link tag="div" :to="{ name: 'related-articles' }">{{ $t('article_tabs.related_articles') }}</router-link>
        <router-link tag="div" :to="{ name: 'article-divisions' }">{{ $t('article_tabs.divisions') }}</router-link>
        <router-link tag="div" :to="{ name: 'push-notification-send' }">{{ $t('article_tabs.push_notification') }}</router-link>
    </div>
</template>

<script>
    export default {
        name: 'article-tabs',
        props: {
            article: {
                required: true,
            }
        }
    };
</script>
