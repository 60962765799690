<template>
    <div class="answer">
        <label>{{ $t('ab_component.poll_answer') }} {{ answerIndex }}</label>
        <br>
        <div class="amount">{{ voteCount }}x</div>
        <input v-model="answerData"
               :disabled="versionDisabled"
               type="text"
               @input="onChange">
        <div class="sort-button">
            <svg fill="none"
                 height="10"
                 viewBox="0 0 16 10"
                 width="16"
                 xmlns="http://www.w3.org/2000/svg">
                <path d="M0.333008 10H5.33301V8.33333H0.333008V10ZM0.333008 0V1.66667H15.333V0H0.333008ZM0.333008
                5.83333H10.333V4.16667H0.333008V5.83333Z"
                      fill="black" />
            </svg>
        </div>
        <div class="delete-button"
             @click="onDelete(answerIndex)">
            <svg fill="none"
                 height="14"
                 viewBox="0 0 12 14"
                 width="12"
                 xmlns="http://www.w3.org/2000/svg">
                <path d="M1.55519 12.1851C1.55519 12.9999 2.22186 13.6666 3.03668 13.6666H8.9626C9.77742 13.6666 10.4441 12.9999 10.4441
                12.1851V3.29621H1.55519V12.1851ZM11.1848 1.07399H8.59223L7.85149 0.333252H4.14779L3.40705 1.07399H0.814453V2.55547H11.1848V1.07399Z"
                      fill="black" />
            </svg>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Answer',

    props: {
        onDelete: {
            type: Function,
            required: true,
        },
        answerIndex: {
            type: Number,
            required: true,
        },
        versionDisabled: {
            type: Boolean,
            default: false,
        },
        voteCount: {
            required: false,
            default: 0,
        },
        value: {
            type: Object,
        },
    },

    data() {
        return {
            answerData: this.value.answer,
            idData: this.value.id,
        };
    },

    methods: {
        onChange() {
            this.$emit('input', {
                id: this.idData,
                answer: this.answerData,
            });
        },
    },

    watch: {
        value(val) {
            this.answerData = val.answer;
            this.idData = val.id;
        }
    },
};
</script>

<style lang="scss" scoped>
label {
    margin-left : 44px;
}

.amount {
    display     : inline-block;
    width       : 40px;
    font-size   : 14px;
    font-weight : bold;
    color       : #ababab;
}

input {
    max-width : calc(100% - 36px - 8px - 36px - 8px - 40px);
}
</style>
