<template>
    <div class="content">
        <div class="header not-sticky">
            <h1>{{
                    $route.params.id ? $t('locations_on_route.change_location') : $t('locations_on_route.add_new_location')
                }}</h1>

            <div class="header-buttons">
                <div v-if="$route.params.id" class="button alert" @click="deleteLocation">
                    {{ $t('locations_on_route.delete') }}
                </div>
                <div class="language-select header">
                    <vue-multiselect
                        id="languages"
                        v-model="language"
                        v-bind="langSwitcherOptions"
                        :allow-empty="false"
                        :options="languages"
                        :searchable="false">
                        <template slot="singleLabel" slot-scope="props">
                            <img :src="'/svg/flags/' + props.option.code + '.svg'" alt="country flag">
                            <span v-text="props.option.name"></span>
                        </template>
                        <template slot="option" slot-scope="props">
                            <div class="option__desc">
                                <img :src="'/svg/flags/' + props.option.code + '.svg'" alt="check">
                                <span class="option__title">{{ props.option.name }}</span>
                            </div>
                        </template>
                    </vue-multiselect>
                </div>

                <div @click="save">
                    <div class="button green">
                        <span>{{
                                !$route.params.id ? $t('locations_on_route.add_location') : $t('locations_on_route.save_changes')
                              }}</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="content-wrap">
            <div :class="{'content-wrap__content--push-right': !! $route.params.id}" class="content-wrap__content">
                <div class="panel panel--push-top">
                    <h2 class="title title--push-bottom">{{ $t('locations_on_route.data') }}</h2>

                    <div class="field">
                        <label class="label" for="title">{{ $t('locations_on_route.title') }}</label>
                        <input id="title" v-model="location.title" type="text" />
                    </div>

                    <div class="field">
                        <label class="label" for="divisions">{{ $t('locations_on_route.divisions') }}</label>
                        <vue-multiselect
                            id="divisions"
                            v-model="location.businessUnits"
                            :close-on-select="false"
                            :multiple="true"
                            :options="businessUnits"
                            :placeholder="$t('locations_on_route.select_division')"
                            :searchable="false"
                            class="multiselect--push-bottom"
                            deselectLabel=""
                            label="name"
                            selectLabel=""
                            selectedLabel=""
                            track-by="id">
                            <template slot="tag" slot-scope="props">
                                <span v-text="props.option.name + ', '"></span>
                            </template>
                            <template slot="option" slot-scope="props">
                                <div class="option__desc">
                                    <span class="option__title">{{ props.option.name }}</span>
                                    <img alt="check" class="selected" src="/svg/check.svg">
                                </div>
                            </template>
                            <span slot="noResult">{{ $t('locations_on_route.no_results') }}</span>
                        </vue-multiselect>
                    </div>

                    <div class="field">
                        <label class="label">{{ $t('locations_on_route.description') }}</label>
                        <redactor-text-area :config="redactorConfig"
                                            v-model="location.description" />
                    </div>

                    <div class="field">
                        <label class="label" for="btn-txt">{{ $t('locations_on_route.button_text') }}</label>
                        <input id="btn-txt" v-model="location.buttonText" type="text" />
                    </div>

                    <div class="field">
                        <label class="label" for="btn-url">{{ $t('locations_on_route.button_url') }}</label>
                        <input id="btn-url" v-model="location.buttonUrl" type="text" />
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import VueMultiselect from 'vue-multiselect/src/Multiselect';
    import { BusinessUnitService } from '../../services';

    export default {
        name: 'events-form',
        components: { VueMultiselect },

        beforeRouteEnter(to, from, next) {
            window.axios.get('/languages').then(response => {
                let languages = response.data;
                let language = window.authUser.language_relation || languages.find(language => {
                    return language.default === 1;
                });

                const locationId = to.params.id;
                if (locationId) {
                    window.axios.get(`/locations/${locationId}?language=` + language.code).then((response) => {
                        next(vm => {
                            vm.location = response.data;
                            vm.languages = languages;
                            vm.language = language;
                        });
                    }).catch((err) => {
                        next({ name: 'locations.index' })
                    });
                }

                next(vm => {
                    vm.languages = languages;
                    vm.language = language;
                })
            });
        },

        data() {
            return {
                langSwitcherOptions: {
                    placeholder: this.$t('locations_on_route.select_language'),
                    label: 'name',
                    'track-by': 'code',
                    selectLabel: '',
                    selectedLabel: '',
                    deselectLabel: '',
                },
                language: null,
                translations: [],
                location: {
                    title: null,
                    buttonText: null,
                    buttonUrl: null,
                    description: null,
                    businessUnits: [],
                },
                businessUnits: [],
                languages: [],
                redactorConfig: {
                    lang: 'nl',
                    buttons: ['format', 'bold', 'italic', 'ul', 'ol', 'link'],
                    formatting: ['p'],
                    pasteImages: false,
                    plugins: ['table'],
                    customButtons: [],
                },
            };
        },

        watch: {
            language(newVal) {
                if (newVal) {
                    this.getLocationByLanguage(newVal.code);
                }
            },
        },

        beforeMount() {
            this.getBusinessUnits().then(() => {
                this.selectedBusinessUnits = this.location.businessUnits.filter((d) => {
                    return !!this.businessUnits.find(b => b.id === d.id);
                });
            });

            // Set dutch as default language if its create route
            if (!this.$route.params.id) {
                this.language = this.languages[0];
            }
        },

        methods: {
            getLocationByLanguage(lang) {
                const locationId = this.$route.params.id;
                if (locationId) {
                    window.axios.get(`/locations/${locationId}?language=` + lang).then((response) => {
                        this.location = response.data;
                    })
                }
            },
            getBusinessUnits() {
                return BusinessUnitService.fetchByPermission(this.$permissions.ADD_AND_EDIT_LOCATIONS).then((response) => {
                    this.businessUnits = response.data;

                    return response;
                });
            },

            save() {
                this.storeOrUpdateLocation().then((response) => {
                    this.$flashMessage(this.$t('locations_on_route.location_saved'), 5000, 'succes');
                    if (this.$route.name !== 'locations.edit') {
                        this.$router.push({ name: 'locations.edit', params: { id: response.data.id } });
                    }
                }).catch((err) => {
                    const errors = Object.entries(err.response.data.errors).map((err) => err[1]).flat().join('<br>');
                    this.$flashMessage(errors, 5000, 'error');
                })
            },

            storeOrUpdateLocation() {
                const locationId = this.$route.params.id;

                if (locationId) {
                    return window.axios.put(
                        `/locations/${locationId}?language=` + this.language.code,
                        this.location
                    )
                }

                return window.axios.post(
                    `/locations?language=` + this.language.code,
                    this.location
                )
            },
            deleteLocation() {
                window.axios.delete(`/locations/${this.$route.params.id}`).then((response) => {
                    this.$router.push({ name: 'locations.index' });
                }).catch((err) => {
                    this.$flashMessage(this.$t('locations_on_route.location_delete_failed'))
                });
                return true
            }
        },
    };
</script>
