<template>
    <div class="components">
        <template v-if="!components.length && !versionDisabled">
            <div class="component-row">
                <div class="add-component add-component--push-bottom">
                    <span>{{ $t('manual_component_list.add_component') }} <img src="/svg/caret-down.svg"></span>
                    <div class="options">
                        <a @click.prevent="addComponent(1)" href="#">{{ $t('manual_component_list.title_big') }}</a>
                        <a @click.prevent="addComponent(2)" href="#">{{ $t('manual_component_list.title') }}</a>
                        <a @click.prevent="addComponent(3)" href="#">{{ $t('manual_component_list.textbox') }}</a>
                        <a @click.prevent="addComponent(4)" href="#">{{ $t('manual_component_list.image') }}</a>
                    </div>
                </div>
            </div>
        </template>

        <draggable :disabled="versionDisabled" handle=".handle" v-model="computedComponents">
            <div class="component-row" v-for="component of computedComponents">
                <manual-component :chapterKey="chapterKey"
                                  :componentType="component.component_type.name"
                                  :id="component.id"
                                  :key="'manual-component-' + component.id"
                                  :language="language"
                                  :translations="component.translations"
                                  :versionDisabled="versionDisabled" />

                <div class="add-component add-component--push-bottom" v-if="!versionDisabled">
                    <span>{{ $t('manual_component_list.add_component') }} <img src="/svg/caret-down.svg"></span>
                    <div class="options">
                        <a @click.prevent="addComponent(1, component.id)" href="#">{{ $t('manual_component_list.title_big') }}</a>
                        <a @click.prevent="addComponent(2, component.id)" href="#">{{ $t('manual_component_list.title') }}</a>
                        <a @click.prevent="addComponent(3, component.id)" href="#">{{ $t('manual_component_list.textbox') }}</a>
                        <a @click.prevent="addComponent(4, component.id)" href="#">{{ $t('manual_component_list.image') }}</a>
                    </div>
                </div>
            </div>
        </draggable>
    </div>
</template>

<script>
    import draggable from 'vuedraggable';

    export default {
        name: 'manual-component-list',

        components: {
            draggable,
        },

        props: {
            versionDisabled: {
                required: true,
            },
            components: {
                type: Array,
                required: true,
            },
            chapterKey: {
                type: String,
                required: true,
            },
            subParagraph: {
                type: Object,
                required: false,
            },
            paragraph: {
                type: Object,
                required: false,
            },
            chapter: {
                type: Object,
                required: true,
            },
            language: {
                type: Object,
                required: true,
            }
        },

        computed: {
            computedComponents: {
                set(components) {
                    if (this.subParagraph) {
                        let mappedComponents = components.map((component, index) => {

                            let componentList = this.$store.getters['chapters/components_by_sub_paragraph_id'](this.chapter.id, this.paragraph.id, this.subParagraph.id);

                            let newcomponent = componentList.find(function (comp) {
                                return comp.id === component.id;
                            });

                            newcomponent.position = index;
                            return newcomponent
                        });
                        this.$store.dispatch('chapters/set_sub_paragraph_components', {
                            components: mappedComponents,
                            chapterId: this.chapter.id,
                            paragraphId: this.paragraph.id,
                            subParagraphId: this.subParagraph.id
                        });
                    } else if (this.paragraph) {
                        let mappedComponents = components.map((component, index) => {

                            let componentList = this.$store.getters['chapters/components_by_paragraph_id'](this.chapter.id, this.paragraph.id);

                            let newcomponent = componentList.find(function (comp) {
                                return comp.id === component.id;
                            });

                            newcomponent.position = index;
                            return newcomponent
                        });
                        this.$store.dispatch('chapters/set_paragraph_components', {
                            components: mappedComponents,
                            chapterId: this.chapter.id,
                            paragraphId: this.paragraph.id,
                        });
                    } else {
                        let mappedComponents = components.map((component, index) => {

                            let componentList = this.$store.getters['chapters/components_by_chapter_id'](this.chapter.id);

                            let newcomponent = componentList.find(function (comp) {
                                return comp.id === component.id;
                            });

                            newcomponent.position = index;
                            return newcomponent
                        });
                        this.$store.dispatch('chapters/set_chapter_components', {
                            components: mappedComponents,
                            chapterId: this.chapter.id
                        });
                    }
                },
                get() {
                    if (this.subParagraph) {
                        return this.$store.getters['chapters/components_by_sub_paragraph_id'](this.chapter.id, this.paragraph.id, this.subParagraph.id);
                    } else if (this.paragraph) {
                        return this.$store.getters['chapters/components_by_paragraph_id'](this.chapter.id, this.paragraph.id);
                    }

                    return this.$store.getters['chapters/components_by_chapter_id'](this.chapter.id);
                },
            },
        },

        methods: {
            addComponent(type, afterId) {
                this.$store.dispatch('chapters/store_empty_component', {
                    component_type_id: type,
                    componentable_id: this.chapterKey.split('.').map(Number).reverse()[0],
                    componentable_type: 'App\\Entities\\Chapter',
                    chapterKey: this.chapterKey,
                    afterId: afterId || null,
                });
            },
        },
    }
</script>
