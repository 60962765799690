<template>
    <base-modal @close="close">
        <h1>{{ $t('delete_chapter_modal.delete_chapter') }}</h1>

        <p>{{ $t('delete_chapter_modal.warning') }}</p>

        <div class="btn-wrap btn-wrap--right">
            <a href="#" class="btn" @click.prevent="close">{{ $t('delete_chapter_modal.cancel') }}</a>
            <a href="#" class="btn btn--red btn--shadow" @click.prevent="$emit('confirm')">{{ $t('delete_chapter_modal.delete') }}</a>
        </div>
    </base-modal>
</template>

<script>
    export default {
        name: 'delete-chapter-modal',

        methods: {
            close() {
                this.$emit('close');
            }
        }
    };
</script>
