<template>
    <div class="panel panel--push-top">
        <h3 class="panel-title">{{ $t('business_units.general_label') }}</h3>
        <div class="field">
            <label class="label" for="name">
                {{ $t('business_units.general.name') }}
            </label>
            <input id="name" v-model="businessUnitName"
                   type="text" @change="update" />
        </div>
    </div>
</template>

<script>
    export default {
        name: 'GeneralTab',
        props: {
            businessUnit: {
                required: true,
            },
            businessUnitName: {
                required: true,
            },
            permissions: {
                required: true,
            },
        },
        methods: {
            update() {
                this.$emit('update', { key: 'name', value: this.businessUnitName });
            },
        },
    };
</script>
