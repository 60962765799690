<template>
    <div class="content" :class="{ 'is-loading' : isLoading }">
        <div class="header not-sticky">
            <h1> {{ title }} </h1>

            <div class="header-buttons">
                <div class="language-select header">
                    <vue-multiselect
                        id="languages"
                        v-model="language"
                        v-bind="langSwitcherOptions"
                        :allow-empty="false"
                        :options="languages"
                        :searchable="false">
                        <template slot="singleLabel" slot-scope="props">
                            <img :src="'/svg/flags/' + props.option.code + '.svg'" alt="country flag">
                            <span v-text="props.option.name" />
                        </template>
                        <template slot="option" slot-scope="props">
                            <div class="option__desc">
                                <img :src="'/svg/flags/' + props.option.code + '.svg'" alt="check">
                                <span class="option__title">{{ props.option.name }}</span>
                            </div>
                        </template>
                    </vue-multiselect>
                </div>

                <div @click="saveContentPage">
                    <div class="button green">
                        <span>{{ $t('locations_on_route.save_changes') }}</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="content-wrap">
            <div :class="{'content-wrap__content--push-right': !! $route.params.id}" class="content-wrap__content">
                <div class="panel panel--push-top">
                    <h2 class="title title--push-bottom">
                        {{ $t('locations_on_route.data') }}
                    </h2>

                    <div class="field">
                        <label class="label" for="title">{{ $t('locations_on_route.title') }}</label>
                        <input id="title"
                               v-model="pageContent.title.content"
                               type="text">
                    </div>

                    <div class="field">
                        <label class="label">{{ $t('locations_on_route.description') }}</label>
                        <redactor-text-area :config="redactorConfig"
                                            v-model="pageContent.text.content" />
                    </div>

                    <div class="field">
                        <label class="label" for="btn-txt">{{ $t('locations_on_route.button_text') }}</label>
                        <input id="btn-txt"
                               v-model="pageContent.buttonText.content"
                               type="text">
                    </div>

                    <div class="field">
                        <label class="label" for="btn-url">{{ $t('locations_on_route.button_url') }}</label>
                        <input id="btn-url"
                               v-model="pageContent.buttonLink.content"
                               type="text">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import VueMultiselect from 'vue-multiselect/src/Multiselect';

    export default {
        name: 'ContentPage',
        components: { VueMultiselect },

        props: {
            title: {
                type: String,
                default: '',
            },
            contentPageId: {
                type: Number,
                default: 0,
            },
        },

        mounted() {
            window.axios.get('/languages').then(async (languageResponse) => {
                const languages = languageResponse.data;
                const language = window.authUser.language_relation || languages.find((lang) => lang.default === 1);

                this.language = language;
                this.languages = languages;

                await this.fetchContentPage({
                    languageCode : language.code ?? '',
                    id: this.contentPageId
                });
            });
        },

        data() {
            return {
                isLoading: true,

                pageContent: {
                    buttonLink: {
                        content: '',
                    },
                    buttonText: {
                        content: '',
                    },
                    text: {
                        content: '',
                    },
                    title: {
                        content: '',
                    },
                },

                languages: [],
                language: null,

                langSwitcherOptions: {
                    placeholder: this.$t('locations_on_route.select_language'),
                    label: 'name',
                    'track-by': 'code',
                    selectLabel: '',
                    selectedLabel: '',
                    deselectLabel: '',
                },

                /**
                 * Redactor config, the config is used in the redactor-text-area component
                 */
                redactorConfig: {
                    lang: 'nl',
                    buttons: ['format', 'bold', 'italic', 'ul', 'ol', 'link'],
                    formatting: ['p'],
                    pasteImages: false,
                    plugins: ['table'],
                    customButtons: [],
                },
            };
        },

        beforeMount() {
            // Set dutch as default language if its create route
            this.language = this.languages?.[0];
        },

        methods: {
            /**
             * Saves the content page
             * @return {Promise<void>}
             */
            async saveContentPage() {
                this.isLoading = true;

                try {
                    await window.axios.put(
                        `/content-pages/${this.contentPageId}`,
                        this.pageContent,
                    );

                    this.$flashMessage(this.$t('content_page.changes_saved_successfully'), 5000, 'succes');
                } catch (error) {
                    this.$flashMessage(this.$t('content_page.changes_couldnt_be_saved'), 5000, 'error');
                } finally {
                    this.isLoading = false;
                }
            },

            /**
             * Fetches the content page by language and id
             * @param languageCode
             * @param id
             * @return {Promise<void>}
             */
            async fetchContentPage({ languageCode, id }) {
                this.isLoading = true;

                try {
                    if(languageCode && !isNaN(id)) {
                        this.pageContent = (await window.axios.get(`/content-pages/${id}?languageCode=${languageCode}`))?.data
                    }
                } catch (e) {
                    this.$flashMessage(this.$t('content_page.content_couldnt_be_loaded'), 5000, 'error');
                } finally {
                    this.isLoading = false;
                }
            }
        },

        watch: {
            /**
             * If the language changes, fetch the content page
             */
            language : {
                async handler(newLanguage) {
                    await this.fetchContentPage({
                        languageCode : newLanguage?.code ?? '',
                        id: this.contentPageId
                    });
                },
            }
        }
    };
</script>

<style lang="scss" scoped>
    .content {
        transition: opacity 0.4s;

        &.is-loading {
            opacity: 0.5;
            cursor: wait;

            .header {
                pointer-events: none;
            }

            .content-wrap {
                pointer-events: none;
            }
        }
    }
</style>