<template>
    <div class="panel panel--push-top">
            <h3 class="panel-title">{{ $t('business_units.permissions_label') }}</h3>
        <div v-for="permission in permissions" :key="`permission_${permission.id}`" class="field field--checkbox">
            <input :id="`per_${permission.id}`"
                   v-model="selected"
                   @change="$emit('update', { key: 'selectedPermissions', value: selected })"
                   :value="permission.id"
                   multiple
                   type="checkbox" />
            <label :for="`per_${permission.id}`"></label>
            <label :for="`per_${permission.id}`" class="label">{{
                    $t(`business_units.permissions.${permission.name}`)
                                                               }}</label>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PermissionsTab',
    data() {
        return {
            selected: this.selectedPermissions,
        }
    },
    props: {
        permissions: {
            required: true
        },
        selectedPermissions: {
            required: true
        },
    },
    watch: {
        selectedPermissions(){
            this.selected = this.selectedPermissions;
        }
    },
}
</script>
